import React, { useEffect, useRef, useState } from 'react'
import Spinner from '../Spinner'
import { apiRequestUtils } from '../../../utils/apiRequestUtils'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { microsoftIntegrationStatuses, integrationTypes, EMAIL_FOLDER_NAMES } from '../../../frontendConsts.js'
import '../../css/integrationsDetail.css'
import '../../css/integrationCard.css'
import { ReportAPhishConsentCard } from './ReportAPhishConsentCard'
import { ReportAPhishSummary } from './ReportAPhishSummary.js'
import { disconnectIntegrationThunk, integrationUpdatedAction } from '../../../store/IntegrationsSlice'
import OutlookAddInSettings from './OutlookAddInSettings.js'
import { getCompanyManifestThunk, updatePhishingReportOutlookFolderThunk } from '../../../store/CompanySlice'
import { StatusBreadCrumbs } from './StatusBreadCrumbs.js'
import { updateHasSeenReportAPhishThunk } from '../../../store/AuthSlice.js'
import PhinModal from '../PhinModal.js'
import { Checkbox, Chip, FormControlLabel } from '@mui/material'

export function ReportAPhishIntegrationPage ({ id }) {
  const history = useHistory()
  const dispatch = useDispatch()
  const intervalRef = useRef()
  const { authorization } = useSelector((state) => state.auth)
  const hasSeenReportAPhish = authorization?.hasSeenReportAPhish

  const [disconnectModal, setDisconnectModal] = useState(false)
  const [agreedToDisconnect, setAgreedToDisconnect] = useState(false)

  const { company } = useSelector((state) => state.company)
  const { integrationsMap } = useSelector((state) => state.integrations)

  const consentToIntegration = () => {
    const redirectURL = encodeURIComponent(`${window.location.protocol}//${window.location.host}/integrations`)
    const adminConsentLink = 'https://login.microsoftonline.com/common/adminconsent'
    const integrationType = 'reportAPhish'
    const applicationId = process.env.REACT_APP_MICROSOFT_REPORT_A_PHISH_APPLICATION_ID
    window.open(`${adminConsentLink}?client_id=${applicationId}&redirect_uri=${redirectURL}&state=${id}%7C${integrationType}`, '_self')
  }

  const validateConsent = () => {
    apiRequestUtils.put(`/api/companies/${id}/integrations/microsoft/validateConsent`, { integrationId: integrationTypes.REPORT_A_PHISH }).then(async (response) => {
      if (response.status === 200) {
        const data = await response.json()
        if (data.integrationStatus === microsoftIntegrationStatuses.CONNECTED) {
          dispatch(integrationUpdatedAction({ integrationId: integrationTypes.REPORT_A_PHISH, updatedFields: { integrationStatus: data.integrationStatus } }))
          dispatch(updatePhishingReportOutlookFolderThunk({ companyId: company.id, phishingReportOutlookFolder: EMAIL_FOLDER_NAMES.JUNK, showNotification: false }))
          if (window.pendo) {
            window.pendo.track('Report_A_Phish_Integration', {
              status: microsoftIntegrationStatuses.CONNECTED,
              timeStamp: new Date().toISOString()
            })
          }
        } else {
          clearInterval(intervalRef.current)
          intervalRef.current = setInterval(() => {
            apiRequestUtils.put(`/api/companies/${id}/integrations/microsoft/validateConsent`, { integrationId: integrationTypes.REPORT_A_PHISH }).then(async (response) => {
              if (response.status === 200) {
                const data = await response.json()
                if (data.integrationStatus === microsoftIntegrationStatuses.CONNECTED) {
                  clearInterval(intervalRef.current)
                  dispatch(integrationUpdatedAction({ integrationId: integrationTypes.REPORT_A_PHISH, updatedFields: { integrationStatus: data.integrationStatus } }))
                  dispatch(updatePhishingReportOutlookFolderThunk({ companyId: company.id, phishingReportOutlookFolder: EMAIL_FOLDER_NAMES.JUNK, showNotification: false }))
                  if (window.pendo) {
                    window.pendo.track('Report_A_Phish_Integration', {
                      status: microsoftIntegrationStatuses.CONNECTED,
                      timeStamp: new Date().toISOString()
                    })
                  }
                }
              }
            })
          }, 10000)
        }
      } else {
        clearInterval(intervalRef.current)
        intervalRef.current = setInterval(() => {
          apiRequestUtils.put(`/api/companies/${id}/integrations/microsoft/validateConsent`, { integrationId: integrationTypes.REPORT_A_PHISH }).then(async (response) => {
            if (response.status === 200) {
              const data = await response.json()
              if (data.integrationStatus === microsoftIntegrationStatuses.CONNECTED) {
                clearInterval(intervalRef.current)
                dispatch(integrationUpdatedAction({ integrationId: integrationTypes.REPORT_A_PHISH, updatedFields: { integrationStatus: data.integrationStatus } }))
                dispatch(updatePhishingReportOutlookFolderThunk({ companyId: company.id, phishingReportOutlookFolder: EMAIL_FOLDER_NAMES.JUNK, showNotification: false }))
                if (window.pendo) {
                  window.pendo.track('Report_A_Phish_Integration', {
                    status: microsoftIntegrationStatuses.CONNECTED,
                    timeStamp: new Date().toISOString()
                  })
                }
              }
            }
          })
        }, 10000)
      }
    })
  }

  async function handleDisconnect () {
    if (agreedToDisconnect) {
      const success = await dispatch(disconnectIntegrationThunk({ id, integrationId: integrationTypes.REPORT_A_PHISH, history }))

      if (success) {
        if (window.pendo) {
          window.pendo.track('Report_A_Phish_Integration', {
            status: microsoftIntegrationStatuses.DISCONNECTED,
            timeStamp: new Date().toISOString()
          })
        }
        setDisconnectModal(false)
      }
    }
  }

  const getBreadCrumbSteps = () => {
    const steps = { ...microsoftIntegrationStatuses }

    delete steps.TEMP_STAGING
    delete steps.TEMP_STAGED
    delete steps.STAGING
    delete steps.STAGED
    delete steps.COMMITTING

    return Object.keys(steps).map((key) => steps[key])
  }

  const getBreadCrumbStep = (status) => {
    if (!status) return 0 // Show not connected if no integration exists
    const steps = getBreadCrumbSteps()
    return Object.keys(steps).findIndex((key) => steps[key] === status)
  }

  useEffect(() => {
    if (!hasSeenReportAPhish) {
      dispatch(updateHasSeenReportAPhishThunk({ adminId: authorization.uid, hasSeenReportAPhish: true }))
    }
  }, [])

  useEffect(() => {
    if (integrationsMap && integrationsMap.reportAPhish) {
      if (integrationsMap.reportAPhish.integrationStatus === microsoftIntegrationStatuses.CONSENT_SUBMITTED) {
        validateConsent()
        return function cleanUpInterval () {
          clearInterval(intervalRef.current)
        }
      }
    }
  }, [integrationsMap])

  return (
    <div>
      {(!company || !integrationsMap) && (
        <Spinner />
      )}

      {(company && integrationsMap) && (
        <>
          <div className='detailsPageHeadingGroup'>
            <img src='/RaPIcon.png' />
            <div>
              <div className='detailsTitleGroup'>
                <h2 className='phin-page-heading'>Report Phishing Button</h2>
                <Chip label='BETA' sx={{ backgroundColor: 'var(--purple-75)', color: 'white', marginLeft: '.5rem' }} />
              </div>
              <StatusBreadCrumbs
                step={getBreadCrumbStep(integrationsMap?.reportAPhish?.integrationStatus)}
                steps={getBreadCrumbSteps()}
              />
            </div>
          </div>

          <div className='d-flex justify-content-center'>
            {/* Spinners */}
            {integrationsMap.reportAPhish && integrationsMap.reportAPhish.integrationStatus === microsoftIntegrationStatuses.CONSENT_SUBMITTED && (
              <Spinner message='Verifying Microsoft Consent Status' secondaryMessage='This may take a few minutes' />
            )}

            <div className='details'>

              {/* Page Content */}
              <div className='left'>
                {(!integrationsMap.reportAPhish || integrationsMap.reportAPhish.integrationStatus === microsoftIntegrationStatuses.NOT_CONNECTED) && (
                  <ReportAPhishConsentCard
                    consentFunction={consentToIntegration}
                  />
                )}

                {integrationsMap.reportAPhish && integrationsMap.reportAPhish.integrationStatus === microsoftIntegrationStatuses.CONNECTED && (
                  <>
                    <PhinModal
                      isOpen={disconnectModal}
                      title='Are you sure you want to disconnect?'
                      close={() => {
                        setDisconnectModal(false)
                        setAgreedToDisconnect(false)
                      }}
                      closeText='Cancel'
                      action={handleDisconnect}
                      actionColor='error'
                      actionText='Disconnect'
                      actionButtonDisabled={!agreedToDisconnect}
                    >
                      <p style={{ fontSize: '1rem', textAlign: 'left' }}>The Outlook Add-in will not function properly until it is reconnected. To fully remove this feature, the Report Phishing Add-in must be deleted from your Azure Admin Portal.</p>

                      <FormControlLabel
                        sx={{ marginTop: 'var(--phin-s-2', marginLeft: 'var(--phin-s2)' }}
                        label='I understand & want to disconnect Report Phishing Button.'
                        control={
                          <Checkbox
                            required
                            checked={agreedToDisconnect}
                            onChange={(e) => {
                              setAgreedToDisconnect(e.target.checked)
                            }}
                            inputProps={{ 'aria-label': 'I understand & want to disconnect Report Phishing Button' }}
                          />
                        }
                      />
                    </PhinModal>
                    <ReportAPhishSummary
                      disconnectFunction={() => setDisconnectModal(true)}
                    />
                  </>
                )}
              </div>

              {(
                !integrationsMap.reportAPhish || (integrationsMap.reportAPhish && integrationsMap.reportAPhish.integrationStatus === microsoftIntegrationStatuses.CONNECTED)
              ) && (
                <div className='right' style={integrationsMap.reportAPhish ? {} : { filter: 'opacity(.2)', pointerEvents: 'none' }}>
                  <OutlookAddInSettings
                    getCompanyManifestThunk={getCompanyManifestThunk}
                    phishingReportEmailCurrentValue={company.phishingReportEmails}
                  />
                </div>
              )}

            </div>
          </div>
        </>
      )}
    </div>
  )
}
