import { Grid, TextField, Button, InputAdornment } from '@mui/material'
import { PhinCard } from '../PhinCard.js'
import { useState } from 'react'
import NotificationUtilities from '../notifications/notificationUtils.js'
import { useDispatch } from 'react-redux'
import { addPartnerIntegrationAction } from '../../../store/PartnerIntegrationSlice'

export function ConnectWiseIntegrationForm ({
  isLoadingEnableIntegration,
  isIntegrationEnabled,
  enableIntegration,
  disableIntegration,
  partnerId
}) {
  const dispatch = useDispatch()

  const REDACTED_DATA = '**********'
  const [isIntegrationEnabledDynamic, setIsIntegrationEnabled] = useState(isIntegrationEnabled)
  const [cwPartnerId, setCwPartnerId] = useState('')
  const [publicKey, setPublicKey] = useState('')
  const [privateKey, setPrivateKey] = useState('')
  const [cwApiUrl, setCwApiUrl] = useState('')
  const [formErrors, setFormErrors] = useState({ hasErrors: false })

  function validateInputs () {
    const newFormErrors = { hasErrors: false }

    if (cwPartnerId === '' || cwPartnerId === undefined) {
      newFormErrors.cwPartnerId = 'Company ID is required'
      newFormErrors.hasErrors = true
    }
    if (publicKey === '' || publicKey === undefined) {
      newFormErrors.publicKey = 'Public Key is required'
      newFormErrors.hasErrors = true
    }
    if (privateKey === '' || privateKey === undefined) {
      newFormErrors.privateKey = 'Private Key is required'
      newFormErrors.hasErrors = true
    }
    if (cwApiUrl === '' || cwApiUrl === undefined) {
      newFormErrors.cwApiUrl = 'ConnectWise API URL is required'
      newFormErrors.hasErrors = true
    }

    return newFormErrors
  }

  async function handleConnectIntegration (e) {
    e.preventDefault()

    const newFormErrors = validateInputs()

    if (newFormErrors.hasErrors) {
      setFormErrors(newFormErrors)
    } else {
      // Enable integration
      const result = await enableIntegration({
        partnerId,
        cwPartnerId: cwPartnerId?.trim(),
        publicKey: publicKey?.trim(),
        privateKey: privateKey?.trim(),
        cwApiUrl: 'https://' + cwApiUrl?.trim()
      })
      if (result.error) {
        console.error('Failed to enable ConnectWise Integration', result.error)
        NotificationUtilities.sendErrorMessage('Failed to enable ConnectWise Integration. Please try again or open a ticket with Phin Support.')
      } else {
        dispatch(addPartnerIntegrationAction((result.data)))
        setIsIntegrationEnabled(true)
        NotificationUtilities.sendSuccessMessage('ConnectWise Integration Enabled')
      }
    }
  }

  return (
    <PhinCard title='Configure Integration' height='60vh'>
      <form onSubmit={handleConnectIntegration} style={{ height: '100%' }}>
        <Grid
          container
          direction='column'
          justifyContent='space-between'
          sx={{ height: '100%' }}
        >
          <Grid item>
            <Grid container direction='column' sx={{ marginTop: 'var(--phin-s2)' }}>
              <TextField
                id='cw-partner-id-input'
                type='text'
                value={isIntegrationEnabledDynamic ? REDACTED_DATA : cwPartnerId}
                onChange={(e) => setCwPartnerId(e.target.value)}
                label='Company ID *'
                sx={{ marginBottom: 'var(--phin-s-1)' }}
                error={formErrors.cwPartnerId}
                helperText={formErrors.cwPartnerId}
                disabled={isIntegrationEnabledDynamic}
              />
              <TextField
                id='public-key-input'
                type='text'
                value={isIntegrationEnabledDynamic ? REDACTED_DATA : publicKey}
                onChange={(e) => setPublicKey(e.target.value)}
                label='Public Key *'
                sx={{ marginBottom: 'var(--phin-s-1)' }}
                error={formErrors.publicKey}
                helperText={formErrors.publicKey}
                disabled={isIntegrationEnabledDynamic}
              />
              <TextField
                id='private-key-input'
                type='text'
                value={isIntegrationEnabledDynamic ? REDACTED_DATA : privateKey}
                onChange={(e) => setPrivateKey(e.target.value)}
                label='Private Key *'
                sx={{ marginBottom: 'var(--phin-s-1)' }}
                error={formErrors.privateKey}
                helperText={formErrors.privateKey}
                disabled={isIntegrationEnabledDynamic}
              />
              <TextField
                id='cw-api-url-input'
                type='text'
                value={isIntegrationEnabledDynamic ? REDACTED_DATA : cwApiUrl}
                onChange={(e) => setCwApiUrl(e.target.value)}
                label='ConnectWise API URL *'
                sx={{ marginBottom: 'var(--phin-s-1)' }}
                error={formErrors.cwApiUrl}
                helperText={formErrors.cwApiUrl}
                disabled={isIntegrationEnabledDynamic}
                InputProps={{
                  startAdornment: <InputAdornment position='start'>https:// </InputAdornment>
                }}

              />
            </Grid>
          </Grid>

          <Grid item>
            <div className='phin-flex-end'>
              {isIntegrationEnabledDynamic
                ? (
                  <Button
                    id='disconnect-connectWise-integration-button'
                    aria-label='Disconnect ConnectWise Integration Button'
                    color='error'
                    sx={{ mr: 'var(--phin-s-1)' }}
                    variant='contained'
                    size='large'
                    onClick={() => disableIntegration()}
                  >
                    Disconnect
                  </Button>
                  )
                : (
                  <Button
                    id='enable-cw-integration-button'
                    variant='contained'
                    type='submit'
                    size='large'
                    disabled={isLoadingEnableIntegration}
                  >Connect
                  </Button>)}
            </div>
          </Grid>
        </Grid>
      </form>
    </PhinCard>
  )
}
