import { createSlice } from '@reduxjs/toolkit'
import { orgTypes } from '../frontendConsts.js'

export const topNavSlice = createSlice({
  name: 'topNav',
  initialState: {
    topNavDistributorName: '',
    topNavDistributorId: '',
    topNavPartnerName: '',
    topNavPartnerId: '',
    topNavCompanyName: '',
    topNavCompanyId: '',
    topNavOrgType: ''
  },
  reducers: {
    setTopNavDistributorData: (state, action) => {
      state.topNavDistributorName = action.payload.name
      state.topNavDistributorId = action.payload.id
      state.topNavOrgType = orgTypes.DISTRIBUTOR
      state.topNavPartnerName = ''
      state.topNavPartnerId = ''
      state.topNavCompanyName = ''
      state.topNavCompanyId = ''
    },
    setTopNavPartnerData: (state, action) => {
      state.topNavDistributorName = action.payload.distributorName
      state.topNavDistributorId = action.payload.distributorId
      state.topNavPartnerId = action.payload.id
      state.topNavPartnerName = action.payload.name
      state.topNavOrgType = orgTypes.PARTNER
      state.topNavCompanyName = ''
      state.topNavCompanyId = ''
    },
    setTopNavCompanyData: (state, action) => {
      state.topNavDistributorName = action.payload.distributorName
      state.topNavDistributorId = action.payload.distributorId
      state.topNavPartnerId = action.payload.partnerId
      state.topNavPartnerName = action.payload.partnerName
      state.topNavCompanyName = action.payload.name
      state.topNavCompanyId = action.payload.id
      state.topNavOrgType = orgTypes.COMPANY
    },
    setTopNavCompanyName: (state, action) => {
      state.topNavCompanyName = action.payload.name
    },
    setTopNavPartnerName: (state, action) => {
      state.topNavPartnerName = action.payload.name
    }
  }
})

// Action creators are generated for each case reducer function
export const {
  setTopNavDistributorData: setTopNavDistributorDataAction,
  setTopNavPartnerData: setTopNavPartnerDataAction,
  setTopNavCompanyData: setTopNavCompanyDataAction,
  setTopNavCompanyName: setTopNavCompanyNameAction,
  setTopNavPartnerName: setTopNavPartnerNameAction
} = topNavSlice.actions

export default topNavSlice.reducer
