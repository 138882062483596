import { Autocomplete, Button, Chip, Grid, Skeleton, TextField, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { integrationTypes, partnerIntegrationStatuses } from '../../frontendConsts'
import { getPartnerIntegrationsThunk, removePartnerIntegrationAction } from '../../store/PartnerIntegrationSlice'
import { useHistory } from 'react-router-dom'
import debounce from 'lodash/debounce'
import { ConfigureConnectWiseIntegration } from './connectWise/ConfigureConnectWiseIntegration'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import PhinModal from './PhinModal'
import { StatusBreadCrumbs } from './integrations/StatusBreadCrumbs'
import { useDeleteCwMappingMutation, useDisconnectIntegrationMutation, useGetCwCompaniesQuery, useGetCwMappingsQuery, useLazyGetCwAdditionsQuery, useLazyGetCwAgreementsQuery, useSetCwMappingMutation, useSyncCwCompaniesMutation } from '../../store/ConnectwiseApiSlice'
import { DateTime } from 'luxon'
import { IoCheckmarkCircle, IoCloseCircleSharp } from 'react-icons/io5'
import NotificationUtilities from './notifications/notificationUtils'
import { updateHasSeenCWBillingThunk } from '../../store/AuthSlice'

export function ConnectwiseBillingIntegrationPage ({ partnerId }) {
  const dispatch = useDispatch()
  const history = useHistory()

  const { authorization } = useSelector((state) => state.auth)
  const { hasSeenCWBilling } = authorization
  const {
    partnerIntegrationsMap,
    partnerIdOfLastFetch,
    loaders: partnerIntegrationLoaders
  } = useSelector((state) => state.partnerIntegrations)
  const { isLoadingPartnerIntegrations } = partnerIntegrationLoaders
  const { partner, loaders: partnerLoaders } = useSelector((state) => state.partner)
  const { isLoadingPartner } = partnerLoaders

  const [lastUpdated, setLastUpdated] = useState()

  const cwBillingIntegration = useSelector(state => state.partnerIntegrations?.partnerIntegrationsMap?.[integrationTypes.CW_BILLING])

  const [disconnectModal, setDisconnectModal] = useState(false)
  const [disconnectCwBillingIntegration] = useDisconnectIntegrationMutation()
  const [isSyncing, setIsSyncing] = useState(false)

  const { data: companyMappings, isLoading: isLoadingCwCompanyMappings, refetch } = useGetCwMappingsQuery(partnerId, {
    pollingInterval: 5000 // Poll for sync changes every 5 seconds for changes
  })

  const [syncCwCompanies, { data: syncCwCompaniesData, error: syncCwCompaniesError, isLoading: isLoadingSyncCwCompanies, isSuccess: isSyncCwCompaniesSuccess }] = useSyncCwCompaniesMutation()

  function generateLastMonthBillingDateRange () {
    function getOrdinal (day) {
      if (day > 3 && day < 21) {
        return 'th' // catch 11th, 12th, 13th
      }
      switch (day % 10) {
        case 1: return 'st'
        case 2: return 'nd'
        case 3: return 'rd'
        default: return 'th'
      }
    }

    const now = DateTime.now()
    const previousMonth = now.minus({ months: 1 })

    const startOfMonth = previousMonth.startOf('month')
    const endOfMonth = previousMonth.endOf('month')

    const startDay = startOfMonth.day
    const endDay = endOfMonth.day

    const startString = startOfMonth.toFormat('MMMM d') + getOrdinal(startDay)
    const endString = endOfMonth.toFormat('MMMM d') + getOrdinal(endDay) + endOfMonth.toFormat(' yyyy')

    return `${startString} - ${endString}`
  }

  const checkValidCwMappingExists = (companyMappings) => {
    return companyMappings?.rows.some((row) =>
      (row.cwCompanyId && row.cwAgreementId && row.cwAdditionId)
    )
  }

  const handleSyncCWButtonPress = () => {
    if (checkValidCwMappingExists(companyMappings)) {
      syncCwCompanies({ partnerId })
      setIsSyncing(true)
    } else {
      NotificationUtilities.sendErrorMessage('At least one company must be fully configured to begin syncing')
    }
  }

  useEffect(() => {
    if (companyMappings?.rows && isSyncing) {
      const companiesAreSyncing = companyMappings.rows.some((row) => row.isSyncing === true)
      if (companiesAreSyncing === false) {
        setIsSyncing(false)
        const numberOfFailedCompanies = companyMappings.rows.filter((row) => row.lastSyncSucceeded === false).length
        if (numberOfFailedCompanies > 0) {
          if (numberOfFailedCompanies === companyMappings.rows.length) {
            NotificationUtilities.sendErrorMessage('Connectwise Syncing process failed')
          } else {
            NotificationUtilities.sendErrorMessage(`ConnectWise syncing process is complete. ${numberOfFailedCompanies} companies failed to sync. Please retry "Push to ConnectWise" to attempt to fix the issue.`)
          }
        } else {
          NotificationUtilities.sendSuccessMessage('Connectwise Syncing process completed successfully')
        }
      }
    }
  }, [companyMappings])

  useEffect(() => {
    if (isSyncCwCompaniesSuccess) {
      NotificationUtilities.sendSuccessMessage('Connectwise Syncing process started successfully. Please wait a moment for the sync to complete')
      refetch() // Refresh the query after a successful sync
    } else if (syncCwCompaniesError) {
      NotificationUtilities.sendErrorMessage(syncCwCompaniesError?.data?.error)
    }
  }, [isSyncCwCompaniesSuccess, syncCwCompaniesError, syncCwCompaniesData, refetch])

  // Fetch the partner integrations if the partnerIntegrationsMap is null or the partnerIdOfLastFetch is different from the current partnerId
  useEffect(() => {
    if (!partnerIntegrationsMap || partnerIdOfLastFetch !== partnerId) {
      dispatch(getPartnerIntegrationsThunk(partnerId))
    }
  }, [dispatch, partnerId, partnerIntegrationsMap, partnerIdOfLastFetch])

  useEffect(() => {
    if (!hasSeenCWBilling) {
      dispatch(updateHasSeenCWBillingThunk({ adminId: authorization.uid, hasSeenCWBilling: true }))
    }
  }, [])

  async function disconnectConnectWiseIntegration () {
    const result = await disconnectCwBillingIntegration({ partnerId })
    setDisconnectModal(false)
    if (result.error) {
      NotificationUtilities.sendErrorMessage('Failed to disconnect ConnectWise Billing Integration. Please try again or open a ticket with Phin Support.')
    } else {
      NotificationUtilities.sendSuccessMessage('ConnectWise Billing Integration Disconnected')
      dispatch(removePartnerIntegrationAction(integrationTypes.CW_BILLING))
      history.push(`/partners/${partnerId}/integrations`)
    }
  }

  if (isLoadingPartner || !partner) {
    return <Skeleton variant='rectangle' width={1190} height={600} />
  }

  return (
    <>
      <PhinModal
        isOpen={disconnectModal}
        title='Disconnect ConnectWise Integration'
        action={disconnectConnectWiseIntegration}
        close={() => setDisconnectModal(false)}
        closeText='Cancel'
        actionText='Disconnect'
        actionColor='error'
      >
        <p>Are you sure you want to disconnect the ConnectWise Integration?</p>
      </PhinModal>

      <div>

        <div className='detailsTitleGroup'>
          <h2 className='phin-page-heading'>ConnectWise Billing Integration </h2>
          <Chip label='BETA' sx={{ backgroundColor: 'var(--purple-75)', color: 'white', marginLeft: '.5rem' }} />
        </div>

        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          sx={{ marginTop: 'var(--phin-s-1)', marginBottom: 'var(--phin-s-1)' }}
        >
          <Grid>
            <StatusBreadCrumbs
              steps={['Not Connected', 'Connected']}
              step={cwBillingIntegration ? 1 : 0}
            />
          </Grid>
        </Grid>

        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='end'
          sx={{ marginBottom: 'var(--phin-s-1)' }}
        >
          {cwBillingIntegration?.integrationStatus === partnerIntegrationStatuses.ENABLED && (
            <Typography sx={{ padding: '1rem', background: 'var(--blue-0)' }} variant='body2'>
              Billing Period
              <Typography sx={{ fontWeight: 'bold' }}>{generateLastMonthBillingDateRange()}</Typography>
            </Typography>
          )}

          {lastUpdated && (
            <div>
              <span className='phin-body-text'>Last Saved:</span>
              {lastUpdated === 'loading'
                ? (
                  <span className='phin-body-text-gray'>Loading...</span>
                  )
                : (
                  <span className='phin-body-text'>
                    {lastUpdated.toLocal().toLocaleString(DateTime.DATETIME_MED)}
                  </span>
                  )}
            </div>
          )}
        </Grid>

        {(isLoadingPartnerIntegrations || !partnerIntegrationsMap) && (
          <Skeleton variant='rectangle' width={1500} height={700} />
        )}

        {!isLoadingPartnerIntegrations && partnerIntegrationsMap && (
          <>
            {(!cwBillingIntegration || cwBillingIntegration.integrationStatus === partnerIntegrationStatuses.NOT_ENABLED) && (
              <ConfigureConnectWiseIntegration />
            )}

            {cwBillingIntegration?.integrationStatus === partnerIntegrationStatuses.ENABLED && isLoadingCwCompanyMappings && (
              <Skeleton variant='rectangle' width={1500} height={700} />
            )}

            {cwBillingIntegration?.integrationStatus === partnerIntegrationStatuses.ENABLED && !isLoadingCwCompanyMappings && companyMappings && (
              <>
                <div style={{ height: 700, width: '100%' }}>
                  <DataGrid
                    className='DataGrid'
                    rowHeight={60}
                    columns={[
                      {
                        headerName: 'Phin Company',
                        field: 'companyName',
                        minWidth: 200,
                        flex: 1
                      },
                      {
                        headerName: 'Billable Users',
                        field: 'lastMonthBillableUsers',
                        minWidth: 190,
                        flex: 1
                      },
                      {
                        headerName: 'ConnectWise Company',
                        field: 'cwCompanyName',
                        minWidth: 300,
                        flex: 2,
                        renderCell: (params) => {
                          return (
                            <ConnectWiseCompanyDropdown
                              partnerId={partnerId}
                              companyId={params.row.companyId}
                              cwCompany={params.row.cwCompanyId ? { id: params.row.cwCompanyId, label: params.row.cwCompanyName } : null}
                              setLastUpdated={setLastUpdated}
                              isDisabled={isSyncing}
                            />
                          )
                        }
                      },
                      {
                        headerName: 'ConnectWise Agreement',
                        field: 'cwAgreementName',
                        minWidth: 300,
                        flex: 2,
                        renderCell: (params) => {
                          return (
                            <ConnectWiseAgreementDropdown
                              cwCompanyId={params.row.cwCompanyId}
                              cwAgreement={params.row.cwAgreementId ? { id: params.row.cwAgreementId, label: params.row.cwAgreementName } : null}
                              partnerId={partnerId}
                              companyId={params.row.companyId}
                              setLastUpdated={setLastUpdated}
                              isDisabled={isSyncing}
                            />
                          )
                        }
                      },
                      {
                        headerName: 'ConnectWise Addition',
                        field: 'cwAdditionName',
                        minWidth: 300,
                        flex: 2,
                        renderCell: (params) => {
                          return (
                            <ConnectWiseAdditionDropdown
                              cwAgreementId={params.row.cwAgreementId}
                              cwAddition={params.row.cwAdditionId ? { id: params.row.cwAdditionId, label: params.row.cwAdditionName } : null}
                              partnerId={partnerId}
                              companyId={params.row.companyId}
                              setLastUpdated={setLastUpdated}
                              isDisabled={isSyncing}
                            />
                          )
                        }
                      },
                      {
                        headerName: 'Sync Status',
                        field: 'lastSyncSucceeded',
                        minWidth: 150,
                        align: 'center',
                        renderCell: (params) => {
                          if (!params.row.cwAdditionId ||
                            params.row.lastSyncSucceeded === undefined ||
                            params.row.lastSyncSucceeded === null) {
                            return <></>
                          }

                          return (
                            <Tooltip title={params.row.lastSyncTime}>
                              <Grid>
                                {params.row.lastSyncSucceeded
                                  ? (
                                    <IoCheckmarkCircle color='var(--phin-green)' size='1.5em' />
                                    )
                                  : (
                                    <IoCloseCircleSharp color='var(--phin-red)' size='1.5em' />
                                    )}
                              </Grid>
                            </Tooltip>
                          )
                        }
                      }
                    ]}
                    rows={companyMappings.rows}
                    components={{ Toolbar: GridToolbar }}
                    componentsProps={{
                      toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 300 },
                        csvOptions: { disableToolbarButton: true },
                        printOptions: { disableToolbarButton: true }
                      }
                    }}
                    disableSelectionOnClick
                    disableColumnSelector
                    disableDensitySelector
                    isRowSelectable={() => false}
                    // TODO: make it so cells are NOT selectable
                  />

                </div>

                <Grid container direction='row' justifyContent='end' sx={{ mt: 'var(--phin-s-1)' }}>
                  <Button
                    id='disconnect-connectWise-integration-button'
                    aria-label='Disconnect ConnectWise Integration Button'
                    color='error'
                    sx={{ mr: 'var(--phin-s-1)' }}
                    variant='contained'
                    size='large'
                    onClick={() => setDisconnectModal(true)}
                  >
                    Disconnect
                  </Button>

                  <Button
                    id='sync-cw-billing-data-button'
                    aria-label='Sync with ConnectWise Button'
                    variant='contained'
                    size='large'
                    disabled={isSyncing || isLoadingSyncCwCompanies}
                    onClick={handleSyncCWButtonPress}
                  >
                    {isSyncing ? 'Syncing Usage' : 'Push to ConnectWise'}
                  </Button>
                </Grid>
              </>
            )}
          </>
        )}
      </div>
    </>
  )
}

function ConnectWiseCompanyDropdown ({ cwCompany, partnerId, companyId, setLastUpdated, isDisabled = false }) {
  const [nameFilter, setNameFilter] = useState('')
  const [cwCompanyState, setCwCompanyState] = useState(cwCompany)

  const { data } = useGetCwCompaniesQuery({ partnerId, nameFilter })
  const [triggerUpdateFunction, results] = useSetCwMappingMutation()
  const { isLoading } = results
  const [triggerDeleteFunction] = useDeleteCwMappingMutation()

  const debouncedGetCompanies = debounce((nameFilter) => {
    setNameFilter(nameFilter)
  }, 100)

  useEffect(() => {
    if (!isLoading) {
      setCwCompanyState(cwCompany)
    }
  }, [cwCompany, isLoading])

  // when a user selects the company dropdown, we want their selected company to be an option
  // instead of being empty
  let changeable = data?.data ? [...data?.data] : []
  if (cwCompany) {
    changeable.push(cwCompany)
  }

  if (data?.hasMorePages) {
    changeable = [{ id: null, label: 'Search for more companies...', disabled: true }, ...changeable]
  }

  return (
    <Autocomplete
      sx={{
        width: '100%',
        '.MuiOutlinedInput-notchedOutline':
      {
        borderStyle: 'none'
      }
      }}
      disabled={isDisabled}
      options={changeable}
      noOptionsText='No Companies found'
      getOptionDisabled={(option) => option.disabled}
      getOptionKey={(option) => option.id}
      renderInput={(params) => <TextField {...params} />}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onInputChange={async (event, newValue, type) => {
        debouncedGetCompanies(newValue)

        if (type === 'clear') {
          setCwCompanyState(null)
          setLastUpdated('loading')
          // Delete the Company mapping
          const result = await triggerDeleteFunction({ partnerId, companyId })

          if (result.error) {
            setCwCompanyState(cwCompany)
          } else {
            setLastUpdated(DateTime.now())
          }
        }
      }}
      onChange={async (event, newValue) => {
        if (newValue) {
          setCwCompanyState(newValue)
          setLastUpdated('loading')
          const result = await triggerUpdateFunction({
            partnerId,
            companyId,
            mappingUpdate: {
              cwCompanyId: newValue.id,
              cwCompanyName: newValue.label,
              cwAgreementId: null,
              cwAgreementName: null,
              cwAdditionId: null,
              cwAdditionName: null,
              lastSyncSucceeded: null,
              lastSyncTime: null,
              lastSyncUserCount: null
            }
          })

          if (result.error) {
            setCwCompanyState(cwCompany)
          } else {
            setLastUpdated(DateTime.now())
          }
        }
      }}
      value={cwCompanyState}
      onKeyDown={(event) => {
        if ([' ', 'ArrowUp', 'ArrowDown'].includes(event.key)) {
          event.stopPropagation()
        }
      }}
    />
  )
}

function ConnectWiseAgreementDropdown ({ cwCompanyId, cwAgreement, partnerId, companyId, setLastUpdated, isDisabled = false }) {
  const [cwAgreementState, setCwAgreementState] = useState(cwAgreement)
  const [getTriggerFunction, getResult] = useLazyGetCwAgreementsQuery()
  const { data, isLoading } = getResult

  const debouncedGetAgreements = debounce((nameFilter) => {
    nameFilter = nameFilter || ''
    getTriggerFunction({ partnerId, cwCompanyId, nameFilter })
  }, 100)

  const [updateTriggerFunction] = useSetCwMappingMutation()

  useEffect(() => {
    if (cwCompanyId) {
      getTriggerFunction({ partnerId, cwCompanyId, nameFilter: '' })
    }
  }, [cwCompanyId])

  useEffect(() => {
    if (!isLoading) {
      setCwAgreementState(cwAgreement)
    }
  }, [cwAgreement, isLoading])

  let changeable = data?.data ? [...data?.data] : []
  if (data?.hasMorePages) {
    changeable = [{ id: null, label: 'Search for more Agreements...', disabled: true }, ...changeable]
  }

  return (
    <Autocomplete
      sx={{
        width: '100%',
        '.MuiOutlinedInput-notchedOutline':
      {
        borderStyle: 'none'
      }
      }}
      disabled={isDisabled}
      options={changeable}
      noOptionsText={cwCompanyId ? 'No Agreements found' : 'Select a ConnectWise Company first'}
      getOptionDisabled={(option) => option.disabled}
      getOptionKey={(option) => option.id}
      renderInput={(params) => <TextField {...params} />}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onInputChange={async (event, newValue, type) => {
        debouncedGetAgreements(newValue)
        if (type === 'clear') {
          setCwAgreementState(null)
          setLastUpdated('loading')
          const result = await updateTriggerFunction({
            partnerId,
            companyId,
            mappingUpdate: {
              cwAgreementId: null,
              cwAgreementName: null,
              cwAdditionId: null,
              cwAdditionName: null,
              lastSyncSucceeded: null,
              lastSyncTime: null,
              lastSyncUserCount: null
            }
          })

          if (result.error) {
            setCwAgreementState(cwAgreement)
          } else {
            setLastUpdated(DateTime.now())
          }
        }
      }}
      onChange={async (event, newValue) => {
        if (newValue) {
          setCwAgreementState(newValue)
          setLastUpdated('loading')
          const result = await updateTriggerFunction({ partnerId, companyId, mappingUpdate: { cwAgreementId: newValue.id, cwAgreementName: newValue.label, cwAdditionId: null, cwAdditionName: null } })

          if (result.error) {
            setCwAgreementState(cwAgreement)
          } else {
            setLastUpdated(DateTime.now())
          }
        }
      }}
      value={cwAgreementState}
      onKeyDown={(event) => {
        if ([' ', 'ArrowUp', 'ArrowDown'].includes(event.key)) {
          event.stopPropagation()
        }
      }}
    />
  )
}

function ConnectWiseAdditionDropdown ({ cwAgreementId, cwAddition, partnerId, companyId, setLastUpdated, isDisabled = false }) {
  const [cwAdditionState, setCwAdditionState] = useState(cwAddition)
  const [getTriggerFunction, getResult] = useLazyGetCwAdditionsQuery()
  const { data, isLoading } = getResult

  const debouncedGetAdditions = debounce((nameFilter) => {
    nameFilter = nameFilter || ''
    getTriggerFunction({ partnerId, cwAgreementId, nameFilter })
  }, 100)

  const [updateTriggerFunction] = useSetCwMappingMutation()

  useEffect(() => {
    if (cwAgreementId) {
      getTriggerFunction({ partnerId, cwAgreementId, nameFilter: '' })
    }
  }, [cwAgreementId])

  useEffect(() => {
    if (!isLoading) {
      setCwAdditionState(cwAddition)
    }
  }, [cwAddition, isLoading])

  let changeable = data?.data ? [...data?.data] : []

  if (data?.hasMorePages) {
    changeable = [{ id: null, label: 'Search for more Additions...', disabled: true }, ...changeable]
  }

  return (

    <Autocomplete
      sx={{
        width: '100%',
        '.MuiOutlinedInput-notchedOutline':
      {
        borderStyle: 'none'
      }
      }}
      options={changeable}
      disabled={isDisabled}
      getOptionDisabled={(option) => option.disabled}
      getOptionKey={(option) => option.id}
      noOptionsText={cwAgreementId ? 'No Additions found' : 'Select an Agreement first'}
      renderInput={(params) => <TextField {...params} />}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onInputChange={async (event, newValue, type) => {
        debouncedGetAdditions(newValue)
        if (type === 'clear') {
          setCwAdditionState(null)
          setLastUpdated('loading')
          const result = await updateTriggerFunction({
            partnerId,
            companyId,
            mappingUpdate:
                {
                  cwAdditionId: null,
                  cwAdditionName: null,
                  lastSyncSucceeded: null,
                  lastSyncTime: null,
                  lastSyncUserCount: null
                }
          })

          if (result.error) {
            setCwAdditionState(cwAddition)
          } else {
            setLastUpdated(DateTime.now())
          }
        }
      }}
      onChange={async (event, newValue) => {
        if (newValue) {
          setCwAdditionState(newValue)
          setLastUpdated('loading')
          const result = await updateTriggerFunction({ partnerId, companyId, mappingUpdate: { cwAdditionId: newValue.id, cwAdditionName: newValue.label } })

          if (result.error) {
            setCwAdditionState(cwAddition)
          } else {
            setLastUpdated(DateTime.now())
          }
        }
      }}
      value={cwAdditionState}
      onKeyDown={(event) => {
        if ([' ', 'ArrowUp', 'ArrowDown'].includes(event.key)) {
          event.stopPropagation()
        }
      }}
    />
  )
}
