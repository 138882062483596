import React from 'react'
import CampaignFrequencySelector from '../Campaigns/modules/CampaignFrequencySelector'
import { campaignTypes } from '../../../frontendConsts'

function ContinuousCampaignLauncher ({
  company, campaignFrequency, trainingCampaignFrequency, firstFireDate, firstTrainingFireDate,
  isCampaignFrequencyWeeksValid,
  setIsCampaignFrequencyWeeksValid,
  phishingStartTime, setPhishingStartTime,
  trainingStartTime, setTrainingStartTime,
  phishingAttemptWindowDays, setPhishingAttemptWindowDays
}) {
  const minDate = new Date()
  minDate.setDate(minDate.getDate() + 1)

  return (
    <div>
      <h2 className='phin-h2 d-flex justify-content-left padding-top:2 padding-bottom:2'>Scheduling and Campaign Launcher</h2>
      <CampaignFrequencySelector
        startTime={phishingStartTime}
        setStartTime={setPhishingStartTime}
        phishingAttemptWindowDays={phishingAttemptWindowDays}
        setPhishingAttemptWindowDays={setPhishingAttemptWindowDays}
        campaignFrequency={campaignFrequency}
        tz={company ? company.timezone.value : 'America/New_York'}
        campaignType={campaignTypes.PHISHING}
        isRunning={false}
        nextFireTimes={null}
        firstFireDate={firstFireDate}
        nextFireTimesErrorMessage={null}
        isCampaignFrequencyWeeksValid={isCampaignFrequencyWeeksValid}
        setIsCampaignFrequencyWeeksValid={setIsCampaignFrequencyWeeksValid}
      />

      <br />

      <CampaignFrequencySelector
        startTime={trainingStartTime}
        setStartTime={setTrainingStartTime}
        campaignFrequency={trainingCampaignFrequency}
        tz={company ? company.timezone.value : 'America/New_York'}
        campaignType={campaignTypes.TRAINING}
        isRunning={false}
        nextFireTimes={null}
        firstFireDate={firstTrainingFireDate}
        nextFireTimesErrorMessage={null}
        isCampaignFrequencyWeeksValid={isCampaignFrequencyWeeksValid}
        setIsCampaignFrequencyWeeksValid={setIsCampaignFrequencyWeeksValid}
        useTrainingSelector
      />
    </div>
  )
}

export default ContinuousCampaignLauncher
