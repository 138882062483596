import { createSlice } from '@reduxjs/toolkit'
import NotificationUtilities from '../pages/components/notifications/notificationUtils'
import { setSideNavOrgDataAction, setSideNavOrgNameAction } from './SideNavSlice'
import { setTopNavPartnerDataAction, setTopNavPartnerNameAction } from './TopNavSlice'
import { NOTIFICATIONS, orgTypes } from '../frontendConsts.js'
import { createGenericDownload } from '../utils/fileUtils'
import { notificationOpenedThunk } from './MuiNotificationsSlice'
import { updatePartnerInDistributorAction } from './DistributorSlice.js'

export const partnerSlice = createSlice({
  name: 'partner',
  initialState: {
    partner: null,
    companies: null,
    partnerAnalytics: null,
    loaders: {
      isLoadingPartner: false,
      isLoadingDeleteCompany: false,
      isUploadingLogo: false,
      isDownloadingPartnerFile: {},
      isLoadingSaaSAgreement: false
    },
    logoUrl: null,
    logoShareLevel: null
  },
  reducers: {
    setIsLoadingPartner: (state, action) => {
      state.loaders.isLoadingPartner = action.payload
    },
    getPartner: (state, action) => {
      state.partner = action.payload
      state.loaders.isLoadingPartner = false
      state.companies = action.payload.companies
    },
    setPartner: (state, action) => {
      state.partner = action.payload
    },
    createCompany: (state, action) => {
      const updatedCompanies = [...state.companies, action.payload]

      state.companies = updatedCompanies
    },
    setIsLoadingDeleteCompany: (state, action) => {
      state.loaders.isLoadingDeleteCompany = action.payload
    },
    deleteCompany: (state, action) => {
      const updatedCompanies = [...state.companies].filter(company => company.id !== action.payload)
      state.companies = updatedCompanies
    },
    createAdmin: (state, action) => {
      const updatedPartner = { ...state.partner }
      updatedPartner.admins.push(action.payload)
      state.partner = updatedPartner
    },
    deleteAdmin: (state, action) => {
      const updatedPartner = { ...state.partner }
      const updatedAdmins = updatedPartner.admins.filter(({ uid: adminUid }) => adminUid !== action.payload)
      updatedPartner.admins = updatedAdmins
      state.partner = updatedPartner
    },
    editAdmin: (state, action) => {
      const updatedPartner = { ...state.partner }
      const updatedAdmins = updatedPartner.admins.map(admin => {
        if (admin.uid === action.payload.uid) {
          const { firstName, lastName, permissionLevel } = action.payload.editedFields
          admin.billing = permissionLevel === 'full-access' ? 'admin' : 'no-access'
          admin.adminLevels = permissionLevel
          admin.name = `${firstName} ${lastName}`
        }
        return admin
      })
      updatedPartner.admins = updatedAdmins
      state.partner = updatedPartner
    },
    setIsLoadingUploadLogo: (state, action) => {
      state.loaders.isUploadingLogo = action.payload
    },
    setLogoUrl: (state, action) => {
      state.logoUrl = action.payload.logoUrl
      state.logoShareLevel = action.payload.shareLevel
      state.loaders.isUploadingLogo = false
    },
    setPartnerName: (state, action) => {
      const updatedPartner = { ...state.partner, name: action.payload }
      state.partner = updatedPartner
    },
    setPartnerAutomaticBillingEnabled: (state, action) => {
      state.partner.automaticBillingEnabled = action.payload
    },
    setIsDownloadingPartnerFile: (state, action) => {
      state.loaders.isDownloadingPartnerFile[action.payload.id] = action.payload.value
    },
    setIsLoadingSaaSAgreement: (state, action) => {
      state.loaders.isLoadingSaaSAgreement = action.payload
    },
    partnerUpdated: (state, action) => {
      const updatedPartner = { ...state.partner }

      for (const [attribute, value] of Object.entries(action.payload)) {
        updatedPartner[attribute] = value
      }

      state.partner = updatedPartner
    },
    updateCompanyInPartner: (state, action) => {
      if (state.partner) {
        const updatedCompanies = [...state.companies].map((company) => {
          if (company.id === action.payload.companyId && action.payload.companyName) {
            company.name = action.payload.companyName
          }
          if (company.id === action.payload.companyId && action.payload.totalDevices) {
            company.totalDevices = action.payload.totalDevices
          }

          return company
        })

        state.companies = updatedCompanies
      }
    }
  }
})

export const {
  setIsLoadingPartner: setIsLoadingPartnerAction,
  getPartner: getPartnerAction,
  setPartner: setPartnerAction,
  createAdmin: createAdminAction,
  deleteAdmin: deleteAdminAction,
  editAdmin: editAdminAction,
  createCompany: createCompanyAction,
  deleteCompany: deleteCompanyAction,
  setIsLoadingDeleteCompany: setIsLoadingDeleteCompanyAction,
  setLogoUrl: setLogoUrlAction,
  setIsLoadingUploadLogo: setIsLoadingUploadLogoAction,
  setPartnerName: setPartnerNameAction,
  setPartnerAutomaticBillingEnabled: setPartnerAutomaticBillingEnabledAction,
  setIsDownloadingPartnerFile: setIsDownloadingPartnerFileAction,
  setIsLoadingSaaSAgreement: setIsLoadingSaaSAgreementAction,
  partnerUpdated: partnerUpdatedAction,
  updateCompanyInPartner: updateCompanyInPartnerAction
} = partnerSlice.actions

export default partnerSlice.reducer

export const createAdminThunk = ({
  adminEmail, adminFirst, adminLast,
  id, partnerName, adminPermission
}) => {
  return async (dispatch, getState, api) => {
    const billingPermissionObj = {
      admin: adminPermission === 'full-access',
      readOnly: false,
      noAccess: adminPermission === 'standard'
    }

    const access = {
      adminLevels: { [id]: adminPermission },
      billing: { [id]: billingPermissionObj }
    }

    try {
      const res = await api.post('/api/auth/users', { email: adminEmail, displayName: `${adminFirst} ${adminLast}`, partners: { [id]: 'admin' }, whiteLabelName: partnerName, access })

      if (res.status === 200) {
        const { uid } = await res.json()
        const adminLevel = adminPermission === 'full-access' ? 'admin' : 'no-access'
        await dispatch(createAdminAction({ uid, name: `${adminFirst} ${adminLast}`, email: adminEmail, billing: adminLevel, adminLevels: adminPermission }))

        NotificationUtilities.sendSuccessMessage('Successfully created admin.')
        return true
      } else {
        const { error } = await res.json()

        NotificationUtilities.sendErrorMessage(error)
      }
    } catch (error) {
      NotificationUtilities.sendErrorMessage('Failed to create admin. Please try again or reach out to Phin Support for assistance.')
    }

    return false
  }
}

export const createCompanyThunk = ({
  name,
  partnerId
}) => {
  return async (dispatch, getState, api) => {
    dispatch(notificationOpenedThunk({ open: true, alertSeverity: NOTIFICATIONS.ALERT_SEVERITY.INFO, alertMessage: 'Creating new Company.' }))

    const res = await api.post(`/api/partners/${partnerId}/companies`, { name })
    if (res.status === 200) {
      const company = await res.json()
      dispatch(createCompanyAction(company))

      dispatch(notificationOpenedThunk({ open: true, alertSeverity: NOTIFICATIONS.ALERT_SEVERITY.SUCCESS, alertMessage: 'Successfully created company.' }))
      return company.id
    } else {
      const { error } = await res.json()
      dispatch(notificationOpenedThunk({ open: true, alertSeverity: NOTIFICATIONS.ALERT_SEVERITY.ERROR, alertMessage: error }))
      throw new Error(error)
    }
  }
}

export const deleteAdminThunk = ({ id, uid, distributorId }) => {
  return async (dispatch, getState, api) => {
    const res = await api.delete(`/api/auth/users/${uid}`, { partners: { [id]: 'admin' }, distributorId })

    try {
      if (res.status === 200) {
        const uid = await res.json()
        await dispatch(deleteAdminAction(uid))

        NotificationUtilities.sendSuccessMessage('Successfully deleted admin.')
        return true
      } else {
        const result = await res.json()

        NotificationUtilities.sendErrorMessage(`Failed to deleted admin: ${result?.error}`)
      }
    } catch (error) {
      NotificationUtilities.sendErrorMessage('Failed to deleted admin.')
    }

    return false
  }
}

export const editAdminThunk = ({ partnerId, distributorId, editedFields }) => {
  return async (dispatch, getState, api) => {
    const { id: uid } = editedFields

    try {
      const res = await api.put(`/api/auth/users/${uid}`, { partnerId, distributorId, editedFields })
      if (res.status === 200) {
        const { uid, editedFields } = await res.json()
        await dispatch(editAdminAction({ uid, editedFields }))

        NotificationUtilities.sendSuccessMessage('Successfully saved new admin status.')
        return true
      } else {
        const result = await res.json()
        NotificationUtilities.sendErrorMessage(`Failed to save new admin status: ${result?.error}`)
      }
    } catch (error) {
      NotificationUtilities.sendErrorMessage('Failed to save new admin status.')
    }

    return false
  }
}

export const getPartnerThunk = (id) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingPartnerAction(true))
    try {
      const res = await api.get(`/api/partners/${id}`)
      if (res.status === 200) {
        const partner = await res.json()
        await dispatch(getPartnerAction(partner))
        dispatch(setIsLoadingPartnerAction(false))
        return partner
      } else {
        dispatch(setIsLoadingPartnerAction(false))
        setTimeout(() => {
          NotificationUtilities.sendErrorMessage('Failed to load partner.')
        })
      }
    } catch (err) {
      dispatch(setIsLoadingPartnerAction(false))
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to load partner.')
      })
      console.error(err)
    }
  }
}

export const getPartnerAndSetNavThunk = (id) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingPartnerAction(true))
    try {
      const res = await api.get(`/api/partners/${id}`)
      if (res.status === 200) {
        const partner = await res.json()
        await dispatch(getPartnerAction(partner))

        const { name, isFreeTrial, distributorId, distributorName } = partner

        dispatch(setSideNavOrgDataAction({ name, id, isFreeTrial, orgType: orgTypes.PARTNER }))

        dispatch(setTopNavPartnerDataAction({ name, id, distributorId, distributorName }))
        dispatch(setIsLoadingPartnerAction(false))

        return partner
      } else {
        dispatch(setIsLoadingPartnerAction(false))
        setTimeout(() => {
          NotificationUtilities.sendErrorMessage('Failed to load partner.')
        })
      }
    } catch (err) {
      dispatch(setIsLoadingPartnerAction(false))
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to load partner.')
      })
      console.error(err)
    }
  }
}

export const changePartnerNameThunk = (id, nameToChange) => {
  return async (dispatch, getState, api) => {
    try {
      const res = await api.put(`/api/partners/${id}/update-name`, { nameToChange })
      if (res.status === 200) {
        NotificationUtilities.sendSuccessMessage('Successfully Changed Partner Name.')
        dispatch(setPartnerNameAction(nameToChange))
        dispatch(setTopNavPartnerNameAction({ name: nameToChange }))
        dispatch(setSideNavOrgNameAction({ name: nameToChange }))
        // Update Partner Name in Distributor Name
        dispatch(updatePartnerInDistributorAction({ partnerId: id, partnerName: nameToChange }))
      } else {
        setTimeout(() => {
          NotificationUtilities.sendErrorMessage('Failed to update partner name.')
        })
      }
    } catch (err) {
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to update partner name.')
      })
      console.error(err)
    }
  }
}

export const deleteCompanyThunk = ({ partnerId, companyId }) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingDeleteCompanyAction(true))
    dispatch(notificationOpenedThunk({ open: true, alertSeverity: NOTIFICATIONS.ALERT_SEVERITY.INFO, alertMessage: 'Deleting Company.' }))
    try {
      const response = await api.delete(`/api/partners/${partnerId}/companies/${companyId}`)

      if (response.status === 200) {
        const { companyId } = await response.json()
        dispatch(deleteCompanyAction(companyId))
        // Update Partner to remove company

        dispatch(notificationOpenedThunk({ open: true, alertSeverity: NOTIFICATIONS.ALERT_SEVERITY.SUCCESS, alertMessage: 'Successfully Removed Company.' }))
        dispatch(setIsLoadingDeleteCompanyAction(false))
      } else {
        dispatch(notificationOpenedThunk({ open: true, alertSeverity: NOTIFICATIONS.ALERT_SEVERITY.ERROR, alertMessage: 'Error Deleting Company.' }))
        dispatch(setIsLoadingDeleteCompanyAction(false))
      }
    } catch (error) {
      dispatch(notificationOpenedThunk({ open: true, alertSeverity: NOTIFICATIONS.ALERT_SEVERITY.WARNING, alertMessage: 'Error Deleting Company.' }))
      dispatch(setIsLoadingDeleteCompanyAction(false))
    }
  }
}

export const getPartnerFileThunk = ({ partnerId, fileInfo, fileName }) => {
  return async (dispatch, getState, api) => {
    try {
      dispatch(setIsDownloadingPartnerFileAction({ id: fileInfo.id, value: true }))

      const res = await api.get(`/api/partners/${partnerId}/reports/download?cloudFilePath=${fileInfo.cloudFilePath}&fileType=${fileInfo.fileType}&fileName=${fileName}`)

      if (res.status === 200) {
        const reportBlob = await res.blob()

        if (reportBlob.size === 0) {
          setTimeout(() => {
            NotificationUtilities.sendErrorMessage('Error downloading report, as it is empty, please contact Phin if problem persists.')
          })
        }

        createGenericDownload({ fileBlob: reportBlob, fileName: fileInfo.reportName, fileType: fileInfo.fileType })
      } else {
        setTimeout(() => {
          NotificationUtilities.sendErrorMessage('Error downloading report, please try again or contact Phin support for assistance')
        })
      }
    } catch (error) {
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Error downloading report, , please try again or contact Phin support for assistance')
      })
    }
    dispatch(setIsDownloadingPartnerFileAction({ id: fileInfo.id, value: false }))
  }
}

export const getSaasAgreementThunk = ({
  partnerId
}) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingSaaSAgreementAction(true))

    const res = await api.get(`/api/partners/${partnerId}/agreements`)

    if (res.status === 200) {
      const blob = await res.blob()

      const file = new File([blob], 'Software-as-a-Service-Agreement.pdf', { type: 'application/pdf' })
      const url = URL.createObjectURL(file)

      dispatch(setIsLoadingSaaSAgreementAction(false))

      return url
    } else {
      const { message } = await res.json()
      dispatch(notificationOpenedThunk({ open: true, alertSeverity: NOTIFICATIONS.ALERT_SEVERITY.ERROR, alertMessage: message }))
    }

    dispatch(setIsLoadingSaaSAgreementAction(false))

    return false
  }
}

export const agreeToSaasAgreementThunk = ({
  partnerId
}) => {
  return async (dispatch, getState, api) => {
    let isSuccessful = false
    dispatch(setIsLoadingSaaSAgreementAction(true))

    const res = await api.post(`/api/partners/${partnerId}/agreements`)

    if (res.status === 200) {
      const partner = { ...getState().partner.partner }

      dispatch(setPartnerAction(partner))
      dispatch(notificationOpenedThunk({ open: true, alertSeverity: NOTIFICATIONS.ALERT_SEVERITY.SUCCESS, alertMessage: 'Successfully agreed to Software as A Service (SaaS) Agreement.' }))
      isSuccessful = true
    } else {
      const { message } = await res.json()
      dispatch(notificationOpenedThunk({ open: true, alertSeverity: NOTIFICATIONS.ALERT_SEVERITY.ERROR, alertMessage: message }))
    }

    dispatch(setIsLoadingSaaSAgreementAction(false))

    return isSuccessful
  }
}
