import { Alert, Button, Grid } from '@mui/material'
import { ChargeBeeInvoicesTable } from './components/ChargeBeeInvoicesTable'
import { UsageBreakdownReportsTable } from './components/UsageBreakdownReportsTable'
import { PaymentInfoWidget } from './components/PaymentInfoWidget'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import {
  getPartnerBillingDetailsThunk, getPartnerBillingInvoicesThunk,
  validateAutomatedBillingReadyForExistingPartnersThunk
} from '../../store/PartnerBillingSlice'
import { ChargebeePlanBillingWidget } from './components/ChargebeePlanBillingWidget'
import { PartnerAutomatedBillingForm } from './components/PartnerAutomatedBillingForm'
import { CancelFreeTrialModal } from './components/CancelFreeTrialModal'
import { EnableAutomatedBillingWidget } from './components/EnableAutomatedBillingWidget'
import { IoTrash } from 'react-icons/io5'

export function PartnerBillingPage ({ partnerId }) {
  const dispatch = useDispatch()
  const { partner } = useSelector((state) => state.partner)
  const { authorization } = useSelector((state) => state.auth)
  const [isCancelFreeTrialModalOpen, setIsCancelFreeTrialModalOpen] = useState(false)
  const [isDeleteAcknowledged, setIsDeleteAcknowledged] = useState(false)

  const isBillingAdmin = authorization.billingAdminPartnerIds.includes(partnerId)

  useEffect(() => {
    if (partner && partner.automaticBillingEnabled) {
      dispatch(getPartnerBillingDetailsThunk({ partnerId }))
      dispatch(getPartnerBillingInvoicesThunk({ partnerId }))
    } else if (partner && !partner.freeTrial && !partner.automaticBillingEnabled) {
      dispatch(validateAutomatedBillingReadyForExistingPartnersThunk({ partnerId }))
    }
  }, [partner])

  if (!isBillingAdmin) {
    return (
      <Alert severity='warning' variant='outlined' sx={{ background: 'var(--phin-light-yellow)' }}>
        <div>You do not have permission to view this page. Please reach out to your administrator for permission or contact <a href='https://www.phinsec.io/knowledge/kb-tickets/new'>Phin Support.</a></div>
      </Alert>
    )
  }

  if (partner.freeTrial && !partner.automaticBillingEnabled) {
    return (
      <>
        <CancelFreeTrialModal
          open={isCancelFreeTrialModalOpen}
          isDeleteAcknowledged={isDeleteAcknowledged}
          setIsDeleteAcknowledged={setIsDeleteAcknowledged}
          closeModal={() => {
            setIsCancelFreeTrialModalOpen(false)
            setIsDeleteAcknowledged(false)
          }}
        />

        <PartnerAutomatedBillingForm isSelfServiceAccount />

        {partner.freeTrial && (
          <Grid sx={{ marginTop: 'var(--phin-s2)', paddingBottom: 'var(--phin-s2)' }}>
            <div className='delete-company-container padding:2'>
              <div>
                <span className='phin-h5'>Cancel Trial</span>
                <br />
                <p className='phin-body-text'>
                  Danger: This action is permanent.
                </p>
              </div>

              <div className='margin-left:2'>
                <Button
                  variant='contained'
                  disableElevation
                  id='cancel-freeTrial-button'
                  aria-label='Cancel Free Trial Button'
                  sx={{ backgroundColor: 'var(--phin-red)' }}
                  color='error'
                  endIcon={<IoTrash />} onClick={() => setIsCancelFreeTrialModalOpen(!isCancelFreeTrialModalOpen)}
                >
                  Cancel Trial
                </Button>

              </div>
            </div>
          </Grid>
        )}
      </>
    )
  }

  return (
    <div>
      <div className='phin-h2 margin-bottom:2'>Billing</div>

      <CancelFreeTrialModal
        open={isCancelFreeTrialModalOpen}
        isDeleteAcknowledged={isDeleteAcknowledged}
        setIsDeleteAcknowledged={setIsDeleteAcknowledged}
        closeModal={() => {
          setIsCancelFreeTrialModalOpen(false)
          setIsDeleteAcknowledged(false)
        }}
      />

      <EnableAutomatedBillingWidget />

      <Grid container spacing={4}>
        <Grid item md={12} lg={6}>
          <ChargebeePlanBillingWidget partnerId={partnerId} />
        </Grid>

        <Grid item md={12} lg={6}>
          <PaymentInfoWidget />
        </Grid>
      </Grid>

      <ChargeBeeInvoicesTable
        partnerId={partnerId}
      />

      <UsageBreakdownReportsTable
        partnerId={partnerId}
      />

      {partner.freeTrial && (
        <div className='padding-bottom:2'>
          <div className='delete-company-container padding:2'>
            <div>
              <span className='phin-h5'>Cancel Trial</span>
              <br />
              <p className='phin-body-text'>
                Danger: This action is permanent.
              </p>
            </div>

            <div className='margin-left:2'>
              <Button
                variant='contained'
                disableElevation
                id='cancel-freeTrial-button'
                aria-label='Cancel Free Trial Button'
                sx={{ backgroundColor: 'var(--phin-red)' }}
                color='error'
                endIcon={<IoTrash />} onClick={() => setIsCancelFreeTrialModalOpen(!isCancelFreeTrialModalOpen)}
              >
                Cancel Trial
              </Button>

            </div>
          </div>
        </div>
      )}
    </div>
  )
}
