/* global FileReader */
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  MDBContainer, MDBRow,
  MDBCol, MDBSpinner
} from 'mdb-react-ui-kit'
import NotificationUtilities from './components/notifications/notificationUtils'
import { useDispatch, useSelector } from 'react-redux'
import { createTrainingCourseThunk, getCourseCatalogThunk } from '../store/TrainingSlice'
import Spinner from './components/Spinner'
import { FileUploadInput } from '../shared/components/FileUploadInput'
import { Button, TextField } from '@mui/material'
import { STYLE } from '../frontendConsts'

async function readFile (file, outputType) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.onload = () => {
      resolve(fileReader.result)
    }
    fileReader.onerror = (error) => {
      reject(error)
    }
    if (outputType === 'ArrayBuffer') {
      fileReader.readAsArrayBuffer(file)
    } else if (outputType === 'DataURL') {
      fileReader.readAsDataURL(file)
    }
  })
}

async function makeFormData (course) {
  const {
    courseTitle,
    courseDescription,
    courseVideo,
    courseThumbnail
  } = course
  const formData = new FormData()
  const videoBuffer = await readFile(courseVideo, 'ArrayBuffer')

  formData.append('courseVideo', new Blob([videoBuffer], { type: 'video/mp4' }))
  formData.append('courseThumbnail', courseThumbnail, 'courseThumbnail')
  formData.append('courseDescription', new Blob([courseDescription], { type: 'text/plain' }))
  formData.append('courseTitle', new Blob([courseTitle], { type: 'text/plain' }), `${courseTitle}`)

  return formData
}

function validateCourse (course) {
  const {
    courseTitle,
    courseDescription,
    courseVideo,
    courseThumbnail
  } = course
  let isValidCourse = true
  if (courseTitle === undefined) {
    NotificationUtilities.sendErrorMessage('Course Title cannot be blank')
    isValidCourse = false
  }

  if (courseDescription === undefined) {
    NotificationUtilities.sendErrorMessage('Course Description cannot be blank')
    isValidCourse = false
  }

  if (courseVideo === undefined || courseThumbnail === undefined) {
    NotificationUtilities.sendErrorMessage('Error creating course. Please ensure to include a Course Thumbnail (PNG) and Video (MP4) with a combined file size of 32MB or less.')
    isValidCourse = false
  }

  return isValidCourse
}

function TrainingCreator ({ companyId }) {
  const history = useHistory()
  const dispatch = useDispatch()
  const { courseCatalog, loaders } = useSelector((state) => state.trainings)
  const { isLoadingCourseCatalog, isLoadingCreateTrainingCourse } = loaders

  const [courseTitle, setCourseTitle] = useState()
  const [courseDescription, setCourseDescription] = useState()
  const [courseVideo, setCourseVideo] = useState()
  const [courseThumbnail, setCourseThumbnail] = useState()

  const courseObject = { courseTitle, courseDescription, courseVideo, courseThumbnail }

  useEffect(() => {
    if (!courseCatalog) {
      dispatch(getCourseCatalogThunk({ companyId }))
    }
  }, [])

  async function createCourse (course) {
    if (validateCourse(course) !== false) {
      try {
        const formData = await makeFormData(course)

        dispatch(createTrainingCourseThunk({ formData, companyId, history }))
      } catch (err) {
        console.error(err)
        setTimeout(() => {
          NotificationUtilities.sendErrorMessage('Error creating course. Please ensure to include a Course Thumbnail (PNG) and Video (MP4) with a combined file size of 32MB or less.')
        })
      }
    }
  }

  function renderButtons () {
    if (!isLoadingCreateTrainingCourse) {
      return (
        <>
          <span className='padding:-1'>
            <Button
              id='cancel-course-creation-button'
              aria-label='Cancel Course Creation Button'
              size='large'
              onClick={() => history.goBack()}
              style={STYLE.BUTTONS.TERTIARY}
            >Cancel
            </Button>
          </span>
          <Button
            id='create-course-button'
            aria-label='Create Course Button'
            size='large'
            onClick={() => createCourse(courseObject)}
            color='primary'
            variant='contained'
          >Create
          </Button>
        </>
      )
    } else if (isLoadingCreateTrainingCourse) {
      return (
        <MDBSpinner className='mx-4' multicolor />
      )
    }
  }

  function handleVideoUpload (file) {
    if (file.type !== 'video/mp4') {
      NotificationUtilities.sendErrorMessage('Please upload an MP4 file that is less than 32 MB.')
    } else if (file.size > 32000000) {
      NotificationUtilities.sendErrorMessage('Please upload an MP4 file that is less than 32 MB.')
    } else {
      setCourseVideo(file)
    }
  }

  function handleImageUpload (file) {
    if (file.type !== 'image/png') {
      NotificationUtilities.sendErrorMessage('Please upload a PNG file that is less than 32 MB.')
    } else if (file.size > 32000000) {
      NotificationUtilities.sendErrorMessage('Please upload a PNG file that is less than 32 MB.')
    } else {
      setCourseThumbnail(file)
    }
  }

  return (
    <MDBContainer>
      <MDBRow>
        <h1>Course Creator</h1>
        <p>
          Here we will set up the basic content and information for a training course.
          Hover over the question bubble for specific information about this process.
        </p>
      </MDBRow>
      {(!courseCatalog || isLoadingCourseCatalog) && (
        <Spinner message='Loading Course Creator' />
      )}

      {(courseCatalog && !isLoadingCourseCatalog) && (
        <>
          <div className='phin-card-container w-100 h-100'>
            <div className='phin-h4'>Course Information</div>
            <p>
              Here you can input the course name, provide a description, select a video for the course, and choose an image to be used as the course thumbnail.
            </p>
            <div className='form-group w-100'>
              <TextField
                id='course-title-field'
                aria-label='Course Title Field'
                value={courseTitle}
                label='Course Title'
                fullWidth
                onChange={(e) => { setCourseTitle(e.target.value) }}
              />
            </div>
            <MDBRow className='d-flex justify-content-center'>
              <MDBCol>
                <TextField
                  id='course-description-field'
                  aria-label='Course Description Field'
                  multiline
                  label='Course Description'
                  minRows='5'
                  fullWidth
                  value={courseDescription}
                  onChange={(e) => { setCourseDescription(e.target.value) }}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className='d-flex justify-content-center'>
              <MDBCol size='6'>
                <div className='phin-h5'>Course Video</div>
                <FileUploadInput
                  file={courseVideo}
                  setFile={handleVideoUpload}
                  acceptedFileTypes='video/mp4'
                />
              </MDBCol>
              <MDBCol size='6'>
                <div className='phin-h5'>Course Thumbnail</div>
                <FileUploadInput
                  file={courseThumbnail}
                  setFile={handleImageUpload}
                  acceptedFileTypes='image/png'
                />
              </MDBCol>
            </MDBRow>
          </div>
          <div className='phin-flex-end padding-top:0'>
            {renderButtons()}
          </div>
        </>
      )}
    </MDBContainer>

  )
}

export default TrainingCreator
