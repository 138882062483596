import { useState } from 'react'
import './InlineMultiSelect.css'

const InlineMultiSelect = ({
  collapsed, setCollapsed, selectedOptions,
  shouldBeDisabled, options, setSelectedOptions,
  numberOfRequiredChoices, isValid, setIsValid
}) => {
  const [localSelectedOptions, setLocalSelectedOptions] = useState([...selectedOptions])

  const selectOption = (option) => {
    const oldSelectedOptions = [...localSelectedOptions]
    const oldOptionValues = oldSelectedOptions.map(option => option.value)
    let newlySelectedOptions

    if (oldOptionValues.includes(option.value)) {
      // remove previously selected value
      newlySelectedOptions = oldSelectedOptions.filter(oldOption => option.value !== oldOption.value)

      setLocalSelectedOptions(newlySelectedOptions)

      if (newlySelectedOptions.length === numberOfRequiredChoices) {
        setSelectedOptions(newlySelectedOptions)
        setIsValid(true)
      } else {
        setIsValid(false)
      }
    } else {
      // filter out of the old list so that we get the correct order without sorting ourselves. We leave sorting to the user of the component
      oldSelectedOptions.push(option)
      newlySelectedOptions = [...options].filter(oldOption => (oldSelectedOptions.map(option => option.value).includes(oldOption.value)))

      setLocalSelectedOptions(newlySelectedOptions)

      if (newlySelectedOptions.length === numberOfRequiredChoices) {
        setSelectedOptions(newlySelectedOptions)
        setIsValid(true)
      } else {
        setIsValid(false)
      }
    }
    if (newlySelectedOptions.length === numberOfRequiredChoices) {
      setCollapsed(true)
    }
  }

  const renderOptions = () => {
    return (
      <div className='phin-inline-multi-select-options'>
        <div className='d-flex flex-column'>
          {options.map(option => (<span key={option.text} className={`phin-inline-multi-select-option ${shouldBeDisabled(option, localSelectedOptions)}`} onClick={() => selectOption(option)}>{localSelectedOptions.map(option => option.value).includes(option.value) ? '\u2713 ' : ''}{option.text}</span>))}
        </div>
      </div>
    )
  }

  const renderSelectedValues = () => {
    const length = localSelectedOptions.length
    if (length === 0) {
      return <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
    } else if (length === 1) {
      return localSelectedOptions[0].text
    } else if (length === 2) {
      return `${localSelectedOptions[0].text} and ${localSelectedOptions[1].text}`
    } else if (length > 2) {
      const result = 'and ' + localSelectedOptions[length - 1].text
      let mostOfList = ''
      for (const selectedOption of localSelectedOptions.slice(0, length - 1)) {
        mostOfList = mostOfList + selectedOption.text + ', '
      }
      return mostOfList + result
    }
  }

  return (
    <div className='phin-inline-multi-select'>
      <span
        onClick={() => setCollapsed(!collapsed)}
        className={isValid ? 'phin-inline-multi-select-display' : 'phin-inline-multi-select-display-error'}
      >
        <b>{renderSelectedValues()}</b>
      </span>
      {!collapsed && renderOptions()}
    </div>
  )
}

export default InlineMultiSelect
