import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import NotificationUtilities from './components/notifications/notificationUtils'
import ValidationUtils from '../utils/validationUtils'
import { useDispatch, useSelector } from 'react-redux'
import { addPartnerThunk } from '../store/DistributorSlice'
import Spinner from './components/Spinner'
import { useAuth0 } from '@auth0/auth0-react'
import { SharedStepper } from '../shared/components/SharedStepper'
import { IoPersonAdd, IoCashOutline, IoPencil, IoArrowBackOutline } from 'react-icons/io5'
import HubSpotPartnerSelector from './components/partnersPage/HubSpotPartnerSelector'
import { updateHubSpotPartnerErrorMessageAction, updateHubSpotPartnerNameAction, updateIsValidHubSpotPartnerAction, updateMinimumBillableUsersAction, updatePricePerUserOptionAction } from '../store/HubSpotPartnersSlice'
import { Alert, Button, FormControl, FormControlLabel, Radio, RadioGroup, Grid, TextField } from '@mui/material'
import { StaticAlert } from '../shared/components/StaticAlert'
import { StepperButtonTray } from './components/StepperButtonTray'
import { PhinCard } from './components/PhinCard'
import { STYLE } from '../frontendConsts'

const totalSteps = 3

function cancelCreation (history) {
  history.goBack()
}

const CreatePartner = ({ id }) => {
  const [adminFirst, setAdminFirstName] = useState('')
  const [adminLast, setAdminLastName] = useState('')
  const [adminEmail, setAdminEmail] = useState('')
  const [partnerName, setPartnerName] = useState('')
  const [activeTab, setActiveTab] = useState(0)
  const [partnerFreeTrial, setPartnerFreeTrial] = useState(false)
  const [showDuplicateAdminAlert, setDuplicateAdminAlert] = useState(false)
  const [hubSpotCompanyId, setHubSpotCompanyId] = useState()

  const { loaders, distributor } = useSelector((state) => state.distributor)
  const { isLoadingAddPartner } = loaders
  const { isValidHubSpotPartner } = useSelector((state) => state.hubSpotPartners)

  const history = useHistory()
  const { user } = useAuth0()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(updateHubSpotPartnerNameAction())
    dispatch(updateMinimumBillableUsersAction())
    dispatch(updatePricePerUserOptionAction())
    dispatch(updateHubSpotPartnerErrorMessageAction())
    dispatch(updateIsValidHubSpotPartnerAction(false))
  }, [])

  function validate () {
    if (!ValidationUtils.isNotWhiteSpace(partnerName)) {
      throw Error('Please input a name for your partner')
    }
    if (!ValidationUtils.isNotWhiteSpace(adminFirst)) {
      throw Error('Please input a first name')
    }
    if (!ValidationUtils.isNotWhiteSpace(adminLast)) {
      throw Error('Please input a last name')
    }
    if (!ValidationUtils.isValidEmail(adminEmail)) {
      throw Error('Please use a valid email')
    }
    if (distributor && distributor.supportsAutomatedBilling && !isValidHubSpotPartner) {
      throw Error('Please enter a valid HubSpot Company ID for this Partner.')
    }
  }

  function validateName () {
    if (!ValidationUtils.isNotWhiteSpace(partnerName)) {
      throw Error('Please input a name for your partner')
    }
  }

  function nextStep (finishCreation) {
    try {
      switch (activeTab) {
        case (totalSteps - 1):
          finishCreation()
          break
        case 1:
          validateName()
          setActiveTab(activeTab + 1)
          break
        default:
          setActiveTab(activeTab + 1)
          break
      }
    } catch (error) {
      console.error(error)
      NotificationUtilities.sendWarningMessage(error.message)
    }
  }

  async function finishCreation () {
    try {
      validate()

      const { success, status } = await dispatch(addPartnerThunk(
        {
          id,
          adminFirst,
          adminLast,
          adminEmail,
          partnerName,
          partnerFreeTrial,
          authId: user.sub,
          history,
          hubSpotCompanyId
        }
      ))

      if (!success && status === 409) {
        setDuplicateAdminAlert(true)
      }
    } catch (error) {
      console.error(error)
      NotificationUtilities.sendWarningMessage(error.message)
    }
  }

  const renderStepper = () => {
    const stepsArray = [
      {
        tab: 0,
        label: 'Account Status',
        icon: IoCashOutline
      },
      {
        tab: 1,
        label: 'Partner Info',
        icon: IoPencil
      },
      {
        tab: 2,
        label: 'Admin Information',
        icon: IoPersonAdd
      }]
    return (<div className='pb-4'><SharedStepper steps={stepsArray} activeTab={activeTab} setActiveTab={setActiveTab} /></div>)
  }

  function handleUpdateAdminEmail (email) {
    setAdminEmail(email)

    if (showDuplicateAdminAlert) {
      setDuplicateAdminAlert(false)
    }
  }

  return (
    <div>
      <Button
        variant='text'
        style={STYLE.BUTTONS.TERTIARY}
        color='primary' size='small' onClick={() => cancelCreation(history)} startIcon={<IoArrowBackOutline />}
      >Return to Distributor Home
      </Button>
      <div className='padding-bottom:2' />
      <h1 className='padding-bottom:0' id='partner-page-heading'>Create A New Partner</h1>

      {isLoadingAddPartner && (
        <Spinner />
      )}

      {!isLoadingAddPartner && (
        <>

          {renderStepper()}
          {activeTab === 0 &&
            <PhinCard
              title='Free Trial Account'
              description='Is this new partner starting a free trial account?'
            >
              <FormControl>
                <RadioGroup
                  aria-labelledby='trial-radio-buttons-group-label'
                  defaultValue={false}
                  name='trial-radio-buttons-group'
                  value={partnerFreeTrial}
                  onChange={(e) => setPartnerFreeTrial(e.target.value)}
                >
                  <FormControlLabel id='trialTrue' value control={<Radio />} label='Yes' />
                  <FormControlLabel id='trialfalse' value={false} control={<Radio />} label='No' />
                </RadioGroup>
              </FormControl>
            </PhinCard>}

          {activeTab === 1 &&
            <PhinCard
              title='Partner Name'
              description='Give this new partner a name to identify it.'
            >
              <TextField
                value={partnerName}
                id='new-partner-name'
                onChange={(e) => {
                  setPartnerName(e.target.value)
                }}
                name='partner name'
                label='Partner Name'
              />
              {(distributor && distributor.supportsAutomatedBilling) && (
                <HubSpotPartnerSelector
                  hubSpotCompanyId={hubSpotCompanyId}
                  setHubSpotCompanyId={setHubSpotCompanyId}
                  distributorId={id}
                />
              )}
            </PhinCard>}
          {activeTab === 2 &&
            <PhinCard
              title='Add an Administrator'
            >

              {showDuplicateAdminAlert &&
                <div className='margin-bottom:0'>
                  <StaticAlert
                    severity='warning' color='warning' text={`An admin with the email ${adminEmail} already exists in another partner. Please use a different email or contact Phin Support (support@phinsecurity.com) for assistance.`}
                  />
                </div>}
              <div className='padding-bottom:2'>
                <Alert severity='info'>This Administrator will have Full Access by default. Full Access grants Partner admins additional permission to view & edit billing information in our Billing page and to configure Partner admin permissions under Administrators.</Alert>
              </div>
              <Grid container direction='column' rowGap={2}>
                <TextField
                  value={adminFirst}
                  id='new-admin-first-input'
                  onChange={(e) => {
                    setAdminFirstName(e.target.value)
                  }}
                  name='admin first name'
                  label='Admin First Name'
                />
                <TextField
                  value={adminLast}
                  id='new-admin-last-input'
                  onChange={(e) => {
                    setAdminLastName(e.target.value)
                  }}
                  name='admin last name'
                  label='Admin Last Name'
                />

                <TextField
                  value={adminEmail}
                  id='new-admin-email-input'
                  onChange={(e) => {
                    handleUpdateAdminEmail(e.target.value)
                  }}
                  name='admin email'
                  label='Admin Email'
                />
              </Grid>
            </PhinCard>}
          <div>
            <StepperButtonTray
              cancel={() => setActiveTab(activeTab - 1)}
              cancelText='Previous'
              cancelVariant='outlined'
              noCancel={activeTab === 0}
              action={() => nextStep(finishCreation)}
              actionText={activeTab < totalSteps - 1 ? 'Next' : 'Finish'}
            />
          </div>
        </>)}
    </div>
  )
}

export default CreatePartner
