import { TextField } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { DateTime } from 'luxon'
import '../../css/timeScheduler.css'
import { useState } from 'react'

function TimeScheduler ({ sendTime, setSendTime, label, note, timezone, title }) {
  const [isDateInvalid, setIsDateInvalid] = useState()

  const tryToSetSendTime = (selectedTime) => {
    if (selectedTime && selectedTime instanceof Date && !isNaN(selectedTime)) {
      const luxonDate = DateTime.fromJSDate(selectedTime)

      setSendTime(luxonDate.toLocaleString(DateTime.TIME_24_SIMPLE))
      setIsDateInvalid()
    } else {
      setIsDateInvalid('Date is invalid')
    }
  }

  return (
    <div id='timeSelectorComponent'>
      {title && (
        <p className='phinSubHeading subheadingTextLeftAlign'>{title}</p>
      )}

      <p>{label}</p>

      <section className='muiTimeInputGroup'>
        <div className='muiTimeInput'>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              renderInput={(params) => <TextField {...params} />}
              value={new Date(`2020-01-01 ${sendTime}`)}
              onChange={(newValue) => {
                tryToSetSendTime(newValue)
              }}
              minTime={new Date(0, 0, 0, 0)}
              maxTime={new Date(0, 0, 0, 23, 59)}
            />

          </LocalizationProvider>
        </div>
        {isDateInvalid && (<p>{isDateInvalid}</p>)}
      </section>
      {timezone &&
        <p className='phinNoteText alignCenter'>{timezone}</p>}
    </div>
  )
}
export default TimeScheduler
