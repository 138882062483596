import { Button, Card, Divider, Grid, Skeleton } from '@mui/material'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

const basePrice = 2
export function calculateNextBillingDate ({ billingStartDate, currentDateTime }) {
  if (billingStartDate) {
    const endOfCurrentMonth = currentDateTime.endOf('month')
    let nextBillingDate

    if (billingStartDate > endOfCurrentMonth) {
      nextBillingDate = billingStartDate.endOf('month')
    } else {
      if (endOfCurrentMonth.day === billingStartDate.day) {
        nextBillingDate = billingStartDate.plus({ months: 1 }).endOf('month')
      } else {
        nextBillingDate = endOfCurrentMonth
      }
    }
    return nextBillingDate.toLocaleString()
  } else {
    return ''
  }
}

export function ChargebeePlanBillingWidget ({ partnerId }) {
  const history = useHistory()
  const { partner, loaders } = useSelector((state) => state.partner)
  const {
    isLoadingPartner
  } = loaders

  const { partnerBillingDetails, loaders: partnerBillingLoaders } = useSelector((state) => state.partnerBilling)
  const { isLoadingPartnerBillingDetails } = partnerBillingLoaders

  const [unitPrice, setUnitPrice] = useState(basePrice)
  const [totalUnits, setTotalUnits] = useState(0)
  const [totalCost, setTotalCost] = useState(0)

  useEffect(() => {
    let newUnitPrice = unitPrice
    if (partnerBillingDetails && partnerBillingDetails.couponId) {
      newUnitPrice = (basePrice - (basePrice * (partnerBillingDetails.discountPercentage / 100))).toFixed(2)
      setUnitPrice(newUnitPrice)
    }

    if (partner && partnerBillingDetails) {
      const newTotalUnits = partner.minimumBillableUsers > (partner.currentBillableUsers || 0) ? partner.minimumBillableUsers : partner.currentBillableUsers
      setTotalCost(newTotalUnits * newUnitPrice)
      setTotalUnits(newTotalUnits)
    }
  }, [partnerBillingDetails, partner])

  return (
    <div className='margin-bottom:2'>
      {((isLoadingPartner && !partner) || (isLoadingPartnerBillingDetails && !partnerBillingDetails)) && (
        <Skeleton sx={{ bgcolor: '#c4c4c4', minWidth: 200 }} variant='rectangle' height={242} />
      )}

      {(!isLoadingPartner && partner && !isLoadingPartnerBillingDetails && partnerBillingDetails) && (
        <Card
          variant='outlined' sx={{
            minWidth: 200,
            minHeight: 242,
            padding: 'var(--phin-s2)',
            borderRadius: 'var(--phin-s-2)',
            border: '2px solid var(--phin-light-gray)'
          }}
        >
          <div className='phin-h4 margin-bottom:-2'>Plan</div>
          <div className='phin-flex-space-between-row '>
            <div className='phin-h6  margin-bottom:0'>
              {(partner.chargeBeeSubscriptionItemPriceId === 'Phin-Base-Subscription-USD-Monthly') ? 'Month to Month ' : 'Annual '}
              — {partner.minimumBillableUsers} Monthly Minimum Users {' '}
              {(partner.freeTrial) ? <b>(free trial)</b> : ''}
            </div>
          </div>

          <div style={{
            maxWidth: '600px'
          }}
          >
            <Grid container spacing={2} sx={{ marginBottom: 'var(--phin-s2)' }} justifyContent='space-between'>
              <Grid item xs={4}>
                <Grid container spacing={1} direction='column'>
                  <Grid item xs={6}>
                    <div className='phin-h6'>Total Users</div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className='phin-h5'>{totalUnits}</div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%'
                }}
                > x
                </div>
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={1} direction='column'>
                  <Grid item xs={6}>
                    <div className='phin-h6'>Price per User</div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className='phin-h5'>${unitPrice}</div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Divider sx={{ marginBottom: 'var(--phin-s0)', marginTop: 'var(--phin-s-1)', borderBottomWidth: '2px', borderColor: 'var(--phin-gray)' }} />

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Grid container spacing={1} direction='column'>
                  <Grid item xs={6}>
                    <div className='phin-h6'>Total Cost</div>
                  </Grid>
                  <Grid item xs={6}>
                    {(partner.freeTrial === true)
                      ? (<div className='phin-h5'><s>${totalCost.toFixed(2)}</s><div className='phin-h4'>$0</div></div>)
                      : (<div className='phin-h4'>${totalCost.toFixed(2)}</div>)}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
          <Grid container spacing={2} justifyContent='space-between' sx={{ marginTop: 'var(--phin-s-1)' }}>
            <Grid item className='phin-h6'>
              {(partner.freeTrial === true) ? 'First Bill Date: ' : 'Next Billing Date: '}
              {calculateNextBillingDate({ billingStartDate: DateTime.fromSeconds(partner.hubSpotBillingStartDate._seconds), currentDateTime: DateTime.now() })}
            </Grid>
            <Grid item>
              {(partner.freeTrial === true)
                ? <Button sx={{ backgroundColor: 'var(--light-blue)' }} variant='contained' disableElevation onClick={() => { history.push(`/partners/${partnerId}/billing/manage`) }}> Upgrade Plan </Button>
                : ''}
            </Grid>
          </Grid>
        </Card>
      )}
    </div>
  )
}
