import React from 'react'
import { DateTime } from 'luxon'
import { campaignTypes, navigationRoutes } from '../../../frontendConsts.js'
import { Alert, Button, Chip, Grid, IconButton } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'

import Card from '@mui/material/Card'
import { IoEye, IoPencil, IoTrash } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import { formatDate } from '../../../utils/dateUtils.js'

export default function ActiveTrainingCampaignCard ({ campaign, openCancelCampaignModal, company }) {
  const id = company.id
  function getCampaignTypeLabel (campaignType, isContinuous) {
    if (campaignType === campaignTypes.ONBOARDING) {
      return 'Onboarding Campaign'
    }

    if (isContinuous) {
      return 'Continuous Campaign'
    } else {
      return 'Fixed Campaign'
    }
  }

  function formatInterval (iterations, frequency) {
    if (iterations === 'All At Once') {
      return 'All At Once'
    }

    if (iterations === 'Once') {
      return frequency + 'ly'
    }

    if (iterations === 'Twice') {
      return 'Twice a Month'
    }

    return iterations + ' a ' + frequency
  }

  function editTrainingCampaignLink (campaign) {
    return `/companies/${id}${navigationRoutes.TRAINING_CURRICULUM_FORM}/${campaign.id}`
  }

  function viewTrainingCampaignLink (campaign) {
    return `/companies/${id}${navigationRoutes.TRAINING_VIEWER}/${campaign.id}`
  }

  function OnboardingCampaignCard () {
    return <Alert severity='info' icon={<></>}>Onboarding Training will be delivered automatically to matching users</Alert>
  }

  function ContinuousTrainingCampaignCard ({ campaign }) {
    return (
      <div>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          sx={{ marginBottom: 'var(--phin-s-1)' }}
        >
          Next Fire:
          <Chip
            color='primary'
            variant='outlined'
            label={campaign.nextFireTimestamp ? formatDate(DateTime.fromSeconds(campaign.nextFireTimestamp._seconds)) : 'N/A'}
          />
        </Grid>

        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          Schedule
          <Chip
            color='primary'
            variant='outlined'
            label={formatInterval(campaign.campaignFrequency.iterations, campaign.campaignFrequency.frequency)}
          />
        </Grid>
      </div>
    )
  }

  return (
    <Card
      variant='outlined'
      sx={{
        width: '300px',
        minHeight: '350px',
        marginBottom: 'var(--phin-s2)',
        padding: '16px',
        borderWidth: '3px',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
    >

      <div style={{ marginBottom: 'var(--phin-s1)', minHeight: '100px' }}>
        <Chip
          label={<span className='phin-body-text'>{getCampaignTypeLabel(campaign.campaignType, campaign.isContinuous)}</span>}
          color='phinSecondary'
          sx={{ marginBottom: 'var(--phin-s-1)' }}
        />

        <p className='phin-h5'>{campaign.name}</p>
      </div>

      <div>
        {campaign.campaignType === campaignTypes.TRAINING && <ContinuousTrainingCampaignCard campaign={campaign} />}
        {campaign.campaignType === campaignTypes.ONBOARDING && <OnboardingCampaignCard />}
      </div>

      <Grid
        container
        direction='row'
        justifyContent='space-evenly'
        alignItems='center'
      >
        <Button
          id={`view-${campaign.name}-button`}
          aria-label={`View ${campaign.name} Button`}
          size='large'
          variant='outlined'
          startIcon={<IoEye />}
          component={Link}
          to={viewTrainingCampaignLink(campaign)}
        >View
        </Button>

        <Button
          id={`edit-${campaign.name}-button`}
          aria-label={`Edit ${campaign.name} Button`}
          size='large'
          variant='outlined'
          startIcon={<IoPencil />}
          component={Link}
          to={editTrainingCampaignLink(campaign)}
        >Edit
        </Button>

        <Tooltip title='Cancel Campaign'>
          <IconButton
            id={`cancel-${campaign.name}-button`}
            aria-label={`Cancel ${campaign.name} Button`}
            color='error'
            size='small'
            onClick={() => openCancelCampaignModal(campaign)}
          >
            <IoTrash />
          </IconButton>
        </Tooltip>
      </Grid>
    </Card>
  )
}
