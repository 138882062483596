import React from 'react'

const SelectOptions = ({
  options, name, value, label, onChange, disabled,
  innerClassName, multiple, id, ariaLabel
}) => {
  /*
    options: [
      {
        text: string,
        value: string || int,
        disabled: true || blank
        selected: true || blank
      },
      ...
    ]
    */

  return (
    <div style={{ display: 'inline' }}>
      <label> {label}
        <select
          disabled={disabled}
          id={id}
          aria-label={ariaLabel}
          name={name}
          value={value}
          className={`browser-default selector custom-select${' ' + innerClassName}`}
          onChange={e => onChange(e)}
          multiple={multiple}
        >
          {
            options.map((option) => (
              <option
                disabled={option.disabled}
                key={option.value}
                id={option.value}
                arial-label={option.value}
                value={option.value}
              >
                {option.text}
              </option>
            ))
        }
        </select>
      </label>
    </div>
  )
}
export default SelectOptions
