import React, { useState } from 'react'
import { TextField } from '@mui/material'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import LuxonUtils from '@date-io/luxon'
import { DateTime } from 'luxon'
import { useDispatch } from 'react-redux'
import { markTrainingCompleteForUserThunk } from '../../../store/AnalyticsSlice'
import PhinModal from '../PhinModal'

const CompleteTrainingModal = ({
  companyId, user,
  course, selectedCourse,
  onClose, isOpen, confirmAction,
  isSingleUserView
}) => {
  const dispatch = useDispatch()

  const [score, setScore] = useState(100)
  const [dateCompleted, setDateCompleted] = useState(DateTime.now())
  const [overrideReason, setOverrideReason] = useState()
  const [invalidOverrideReason, setInvalidOverrideReason] = useState(false)

  function validateOverrideReason (reason) {
    setInvalidOverrideReason(!reason || reason.length === 0)
    return reason && reason.length > 0
  }

  function updateScore (newScore) {
    const newScoreInt = parseInt(newScore, 10)
    if (newScore === '') {
      setScore(newScore)
    } else if (newScore && !Number.isNaN(newScore) &&
    newScoreInt >= 0 && newScoreInt <= 100 &&
    (newScore.length <= 2 || newScoreInt === 100)) {
      setScore(newScore)
    }
  }

  async function markTrainingComplete () {
    if (!validateOverrideReason(overrideReason)) {
      return
    }
    const status = await dispatch(markTrainingCompleteForUserThunk(
      { user, companyId, courseId: course?.id || selectedCourse?.id, isSingleUserView, score, dateCompleted, overrideReason }))

    if (status) {
      setScore(100)
      setDateCompleted(DateTime.now())
      setOverrideReason()
    }

    confirmAction()
  }

  return (
    <PhinModal
      isOpen={isOpen}
      title='Mark User Complete'
      close={onClose}
      action={markTrainingComplete}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >

      <div className='main-container-left-aligned'>
        <div>
          <p className='margin:none'><b>User:</b> {user?.first} {user?.last} </p>
          <p className='margin:none'><b>Course:</b> {course ? course.name : selectedCourse?.name}</p>
        </div>

        <TextField
          id='score'
          label='Score'
          type='number'
          value={score}
          onChange={(event) => updateScore(event.target.value)}
          InputLabelProps={{
            shrink: true
          }}
          disableMaskedInput
          required
          sx={{
            marginTop: '1.5em',
            marginBottom: '1.5em'
          }}
        />

        <LocalizationProvider dateAdapter={LuxonUtils}>
          <DateTimePicker
            required
            disableToolbar
            id='date-time-picker-inline'
            label='Date Completed'
            value={dateCompleted}
            onChange={setDateCompleted}
            renderInput={(params) => <TextField {...params} />}
            minDate={course
              ? (course.dateAssigned
                  ? DateTime.fromSeconds(course.dateAssigned._seconds)
                  : DateTime.fromSeconds(course.dateStarted._seconds))
              : selectedCourse?.dateAssigned
                ? DateTime.fromJSDate(new Date(selectedCourse?.dateAssigned))
                : DateTime.fromJSDate(new Date(selectedCourse?.dateStarted))}
            disableMaskedInput
            sx={{
              marginTop: '1.5em',
              marginBottom: '1.5em'
            }}
          />

          <TextField
            required
            fullWidth
            id='override-reason'
            label='Override Reason'
            value={overrideReason}
            error={invalidOverrideReason}
            onChange={(event) => setOverrideReason(event.target.value)}
            inputProps={{ maxLength: 256 }}
            sx={{
              minWidth: '50ch',
              marginTop: '1.5em',
              marginBottom: '1.5em'
            }}
          />
        </LocalizationProvider>
      </div>
    </PhinModal>
  )
}

export default CompleteTrainingModal
