import React from 'react'
import '../../css/integrationsDetail.css'
import '../../css/integrationCard.css'
import { Button } from '@mui/material'

export function ReportAPhishSummary ({ disconnectFunction }) {
  return (
    <div className='consentCard' style={{ height: 'auto' }}>
      <div className='consentTitle'>Microsoft Permissions</div>
      <div className='permissions'>
        <p className='consentSubtitle'>What are the permissions?</p>
        <ul>
          <li>
            User.Read.All
            <ul>
              <li>
                Allows the app to read user profiles without a signed in user.
                Used to fetch all of the microsoft azure users to be imported into Phin
              </li>
            </ul>
          </li>
        </ul>
        <ul>
          <li>
            Mail.Send
            <ul>
              <li>
                Allows the app to send mail as any user without a signed-in user.
                Used to forward reported phishing emails to the configured email or Microsoft's reporting service
              </li>
            </ul>
          </li>
        </ul>
        <p className='consentSubtitle'>How will Phin use the permissions?</p>
        <ul>
          <li>
            Phin will use the above listed permissions to access your client's users' inboxes for mail forwarding and reporting purposes.
          </li>
        </ul>
      </div>
      <div className='d-flex justify-content-center'>
        <Button
          id='continue-to-microsoft-button'
          aria-label='Continue to Microsoft Button'
          color='error'
          sx={{ mr: 3 }}
          variant='contained'
          onClick={() => disconnectFunction()}
        >
          Disconnect
        </Button>
      </div>
    </div>
  )
}
