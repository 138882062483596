import { useState, useEffect } from 'react'
import { Pie } from 'react-chartjs-2'
import {
  MDBRow, MDBCol, MDBCard, MDBIcon, MDBCardBody
} from 'mdb-react-ui-kit'
import { useSelector, useDispatch } from 'react-redux'
import { getPolicyStatsThunk } from '../store/AnalyticsSlice'
import { capitalizeFirstLetter } from '../utils/FormattingUtils'

import Spinner from './components/Spinner'
import { FormControl, Grid, InputLabel, MenuItem, Paper, Select, Slide, Tooltip } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'

function HUDElement ({ title, icon, value, tooltip }) {
  return (
    <Tooltip
      placement='top'
      domElement
      className='mr-2 ml-2'
      material
      title={tooltip}
    >
      <a>
        <MDBCol>
          <p className='dark-grey-text'><MDBIcon icon={icon} /> {title}</p>
          <h4 className='font-weight-bold dark-grey-text'>{value}</h4>
        </MDBCol>
      </a>
    </Tooltip>
  )
}

function PieChart ({ policy }) {
  const chartBaseStyles = {
    title: {
      fontSize: 20,
      fontFamily: 'Roboto',
      fontColor: '#212529'
    },
    legend: {
      fontColor: '#212529',
      fontFamily: 'Roboto',
      fontSize: 13
    },
    axisColor: 'rgba(0, 0, 0, 0.3)',
    colors: ['#00C851', '#ff4444']
  }

  const chartData = {
    labels: [
      'Completed',
      'Not Completed'
    ],
    datasets: [{
      data: [
        policy.totalCompleted,
        policy.totalAssigned - policy.totalCompleted
      ],
      backgroundColor: chartBaseStyles.colors
    }]
  }

  const chartOptions = {
    response: true,
    tooltips: {
      enabled: true
    },
    title: {
      display: true,
      fontSize: chartBaseStyles.title.fontSize,
      text: 'Completion Rate',
      fontColor: chartBaseStyles.title.fontColor
    },
    legend: {
      labels: {
        fontColor: chartBaseStyles.legend.fontColor,
        fontFamily: chartBaseStyles.legend.fontFamily,
        fontSize: chartBaseStyles.legend.fontSize
      }
    }
  }

  return (
    <Pie data={chartData} options={chartOptions} height={250} />
  )
}

function PolicySelector ({ policyData, selectedPolicy, switchPolicy }) {
  const options = []
  const policyMap = {}

  for (const policyObj of policyData) {
    const policy = policyObj.policy
    policyMap[policy.id] = policyObj
    options.push(
      <MenuItem key={policy.id} value={policy.id}>
        {policy.name}
      </MenuItem>
    )
  }

  return (
    <FormControl style={{ width: '200px' }} variant='standard'>
      <InputLabel id='analytics-switch-campaign-dropdown-label'>Select Policy</InputLabel>
      <Select
        labelId='analytics-switch-campaign-dropdown-label'
        id='analytics-switch-campaign-dropdown'
        label='Select Policy'
        value={selectedPolicy.id}
        onChange={(event) => switchPolicy(policyMap[event.target.value])}
      >
        {options}
      </Select>
    </FormControl>
  )
}

const policyAnalyticsColumns = [
  { headerName: 'First', field: 'first', flex: 1 },
  { headerName: 'Last', field: 'last', flex: 1 },
  { headerName: 'Email', field: 'email', flex: 2 },
  {
    headerName: 'User Status',
    field: 'status',
    renderCell: (params) => {
      return capitalizeFirstLetter(params.row.status)
    },
    flex: 1
  },
  {
    headerName: 'Policy Status',
    field: 'acknowledged',
    renderCell: (params) => {
      return (params.row.acknowledged ? 'Acknowledged' : 'Not Acknowledged')
    },
    flex: 1
  }
]

function PoliciesAnalyticsPage ({ companyId }) {
  const [selectedPolicy, setSelectedPolicy] = useState(null)
  const [selectedPolicyUsers, setSelectedPolicyUsers] = useState(null)
  const [tablePageSize, setTablePageSize] = useState(10)

  const { policyStats, loaders } = useSelector((state) => state.analytics)
  const { isLoadingPolicyStats } = loaders
  const dispatch = useDispatch()

  useEffect(() => {
    // Make sure we reset the scroll upon load
    dispatch(getPolicyStatsThunk(companyId))
  }, [])

  useEffect(() => {
    if (policyStats && policyStats.length > 0) {
      if (!selectedPolicy) {
        const { policy, users } = policyStats[0]
        setSelectedPolicy(policy)
        setSelectedPolicyUsers(users)
      } else {
        const selectedCourseStat = policyStats.find((policyStat) => policyStat.policy.id === selectedPolicy.id)
        setSelectedPolicy(selectedCourseStat.policy)
        setSelectedPolicyUsers(selectedCourseStat.users)
      }
    }
  }, [policyStats])

  function switchPolicy (policyObj) {
    const { policy, users } = policyObj
    setSelectedPolicy(policy)
    setSelectedPolicyUsers(users)
  }

  return (
    <Grid>
      <MDBRow>
        <MDBCol className='d-flex flex-column flex-center mb-4'>
          <h1>Policy Analytics</h1>
        </MDBCol>
      </MDBRow>

      {(isLoadingPolicyStats || !policyStats) && (
        <Spinner message='Making everything look nice' />
      )}

      <Slide direction='up' in={policyStats && (policyStats.length === 0) && !isLoadingPolicyStats} mountOnEnter timeout={750}>
        <Paper elevation={4} sx={{ padding: 'var(--phin-s2)' }}>
          <Grid
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
          >
            <div className='phin-h4'>
              No policy data found. Check back here once you have assigned a policy!
            </div>
          </Grid>
        </Paper>
      </Slide>

      <Slide direction='up' in={policyStats && (policyStats.length > 0) && !isLoadingPolicyStats && selectedPolicy && selectedPolicyUsers} mountOnEnter timeout={750}>
        <Grid>
          <MDBRow>
            <MDBCol className='d-flex justify-content-end p-0'>
              <PolicySelector policyData={policyStats} switchPolicy={switchPolicy} selectedPolicy={selectedPolicy} />
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCard className='hud-stats w-100 mb-4'>
              <MDBRow className='text-center d-flex justify-content-center my-4'>
                <HUDElement title='Total Users Assigned' icon='users' tooltip='The total number of users assigned this policy, regardless of status: active, slept, deleted.' value={selectedPolicy !== null ? selectedPolicy.totalAssigned : 0} />
                <HUDElement title='Users Finished' icon='medal' tooltip='The number of users who have acknowledged their policy, regardless of status: active, slept, deleted.' value={selectedPolicy !== null ? selectedPolicy.totalCompleted : 0} />
              </MDBRow>
              <MDBRow className='text-center d-flex justify-content-center my-4'>
                <HUDElement title='Active Users' icon='sun' tooltip='The number of active users assigned this policy' value={selectedPolicy !== null ? selectedPolicy.totalActiveUsers : 0} />
                <HUDElement title='Slept Users' icon='bed' tooltip='The number of slept users assigned this policy' value={selectedPolicy !== null ? selectedPolicy.totalSleptUsers : 0} />
                <HUDElement title='Deleted Users' icon='trash' tooltip='The number of deleted users assigned this policy' value={selectedPolicy !== null ? selectedPolicy.totalDeletedUsers : 0} />
              </MDBRow>
            </MDBCard>
          </MDBRow>

          <MDBRow className='d-flex mb-4 justify-content-center'>
            <MDBCol size='6'>
              <MDBCard className='d-flex justify-content-center'>
                <MDBCardBody className='p-1'>
                  <PieChart policy={selectedPolicy} />
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>

          <div style={{ height: '65vh', width: '100%' }}>
            <DataGrid
              style={{ borderRadius: '8px', border: '2px solid var(--phin-light-gray)' }}
              className='DataGrid'
              rows={selectedPolicyUsers || []}
              columns={policyAnalyticsColumns}
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 }
                }
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'last', sort: 'desc' }]
                }
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              disableSelectionOnClick
              pageSize={tablePageSize}
              rowsPerPageOptions={[10, 20, 50]}
              onPageSizeChange={(newSize) => setTablePageSize(newSize)}
              pagination
            />
          </div>

        </Grid>
      </Slide>
    </Grid>
  )
}

export default PoliciesAnalyticsPage
