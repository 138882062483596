import { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import ValidationUtils from '../utils/validationUtils'
import CampaignTemplateEditor from './components/Campaigns/CampaignTemplateEditor'
import CampaignTemplateCategoryEditor from './components/Campaigns/CampaignTemplateCategoryEditor'
import AudienceFilters from './components/audience/AudienceFilters'
import NotificationUtilities from './components/notifications/notificationUtils'
import { apiRequestUtils } from '../utils/apiRequestUtils'
import moment from 'moment'

import { campaignTypes, shareLevels, navigationRoutes, AUDIENCE_FILTER_TYPES, PHISHING_DEFAULT_SEND_WINDOW } from '../frontendConsts.js'
import PublishSelector from './components/Campaigns/PublishSelector'

import { useSelector, useDispatch } from 'react-redux'
import { getNextCampaignScheduleThunk, setPhishingCampaignPresetsAction, updatePhishingCampaignAction, updatePhishingCampaignPresetsAction } from '../store/CampaignsSlice'
import { campaignSelectedAction, resetAllCampaignFrequencyChoicesAction } from '../store/CampaignFrequencySlice'
import CampaignFrequencySelector from './components/Campaigns/modules/CampaignFrequencySelector'
import { calculateStartTime, sanitizeCampaignFrequency } from '../utils/dateUtils'
import { validatePhishingCampaign } from '../validation/PhishingCampaigns'
import PhinModal from './components/PhinModal.js'
import { StepperButtonTray } from './components/StepperButtonTray.js'
import { DateTime } from 'luxon'

function CampaignEditor ({ companyId }) {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  const { phishingCampaignPresets, activeCampaigns } = useSelector((state) => state.campaigns)
  const { userTemplates, defaultTemplates, sharedTemplates, campaignCategories } = useSelector((state) => state.templates)
  const campaignFrequency = useSelector((state) => state.campaignFrequency)
  const company = useSelector((state) => state.company.company)
  const { groups } = useSelector((state) => state.users)

  const [locationData, setLocationData] = useState({})
  const [selectedCampaign, selectCampaign] = useState({})
  const [audienceStats, setAudienceStats] = useState({})
  const [audienceFilterType, setAudienceFilterType] = useState('first')
  const [departmentNames, setDepartmentNames] = useState([])
  const [supervisorEmails, setSupervisorEmails] = useState([])
  const [nextFireTimes, setNextFireTimes] = useState([])
  const [firstFireDate, setFirstFireDate] = useState()
  const [nextFireTimesErrorMessage, setNextFireTimesErrorMessage] = useState()

  const [parameters, setParameters] = useState([])
  const [sendingChannels, setSendingChannels] = useState({ sms: false, email: false })
  const [learningTypes, setLearningTypes] = useState({ video: false, lm: false, custom: false })
  const [templates, setTemplates] = useState([])
  const [shareLevel, setShareLevel] = useState(shareLevels.CLIENT)

  const [startTime, setStartTime] = useState(calculateStartTime())
  const [phishingAttemptWindowDays, setPhishingAttemptWindowDays] = useState(PHISHING_DEFAULT_SEND_WINDOW)

  const [publishOption, setPublishOption] = useState('preset')

  const [testingEmails, setTestingEmails] = useState(['', '', ''])

  const [testingPhoneNumbers, setTestingNumbers] = useState(['', '', ''])

  const [validationModal, setValidationModal] = useState(false)
  const [previewSuccess, setPreviewSuccess] = useState(false)

  const [isCampaignFrequencyWeeksValid, setIsCampaignFrequencyWeeksValid] = useState(true)

  useEffect(() => {
    document.title = 'Phin Security | Campaign Editor'
    window.scrollTo(0, 0)

    setLocationData(location.state)

    const { edit, campaignName, campaignType, campaignDescription, campaignId, presetId } = location.state

    let campaign
    let templates

    if ((presetId && phishingCampaignPresets) || activeCampaigns) {
      if (edit) {
        campaign = findEditCampaign(campaignId, presetId)
      } else if (campaignName) {
        // A new campaign is being created
        campaign = {
          name: campaignName,
          campaignType,
          description: campaignDescription,
          audienceFilterType: 'first',
          campaignFrequency: {
            iterations: campaignFrequency.iterations.value,
            frequency: campaignFrequency.frequency.value,
            week: campaignFrequency.week.map(week => +week.value),
            weekday: +campaignFrequency.weekday.value,
            time: campaignFrequency.time
          },
          parameters: [{
            field: 'first',
            type: '!=',
            value: '~~~'
          }]
        }

        if (campaign.campaignType === campaignTypes.CONTINUOUS) {
          campaign.sendingChannels = { email: true, sms: false }
          campaign.learningTypes = { custom: false, lm: true, video: false }
        }

        dispatch(campaignSelectedAction(campaign))
      } else {
        // The editor is in an undefined state, likely from refresh. abort.
        history.push(navigationRoutes.CAMPAIGNS)
      }

      if (campaign.campaignType === campaignTypes.FIXEDLENGTH) {
        templates = campaign.templateNames || []
      } else {
        templates = campaign.categoryNames || []
      }

      selectCampaign(campaign)
      setParameters(campaign.parameters)
      setAudienceFilterType(campaign.audienceFilterType)
      setTemplates(templates)
      setSendingChannels(campaign.sendingChannels)
      setStartTime(moment(campaign.startTime).tz(company.timezone.value))
      setPhishingAttemptWindowDays(campaign.phishingAttemptWindowDays || PHISHING_DEFAULT_SEND_WINDOW) // Default to 3 if not defined
      setLearningTypes(campaign.learningTypes)
    }
  }, [phishingCampaignPresets, activeCampaigns])

  useEffect(() => {
    if (selectedCampaign) {
      refreshAudienceStats(parameters)
    }
  }, [audienceFilterType, parameters])

  useEffect(() => {
    // update scheduling dates on component load
    setLocationData(location.state)

    try {
      let startDate = DateTime.fromISO(startTime.toISOString())

      const running = startDate < DateTime.now()

      if (running) {
        // This campaign is currently running, cap the startDate
        startDate = DateTime.now().setZone(company.timezone.value)
      }

      const frequency = sanitizeCampaignFrequency(campaignFrequency)

      let nextFireTimeCount = 5
      if (selectedCampaign.campaignType === campaignTypes.FIXEDLENGTH && !running) {
        nextFireTimeCount = templates.length
      }

      // We need the return value from this thunk so we callback the result
      dispatch(getNextCampaignScheduleThunk({ companyId, campaignFrequency: frequency, startDate, count: nextFireTimeCount }))
        .then(({ nextFireDateTimes, nextFireDateTime }) => {
          setNextFireTimesErrorMessage()
          setNextFireTimes(nextFireDateTimes)
          setFirstFireDate(nextFireDateTime)
        })
    } catch (error) {
      setNextFireTimesErrorMessage(error.message)
    }

    initializeAudienceStats()
  }, [startTime, campaignFrequency])

  function findEditCampaign (campaignId, presetId) {
    let found
    if (presetId && phishingCampaignPresets) {
      found = phishingCampaignPresets.find(item => item.id === presetId)
    } else if (activeCampaigns) {
      found = activeCampaigns.find((item) => item.id === campaignId)
      dispatch(campaignSelectedAction(found))
      if (!found) {
        found = { parameters: [] }
      }
    }

    return found
  }

  async function saveCampaign () {
    const { edit, presetId } = locationData

    if (!isValidCampaign()) {
      return
    }

    if (edit && presetId) {
      await updateCampaignPreset()
    } else if (edit && !presetId) {
      await updateCampaign()
    } else if (publishOption === 'preset') {
      await publishPresetCampaign()
    } else if (publishOption === 'preview') {
      await publishTestingCampaign()
    } else {
      toggleValidationModal()
    }
  }

  async function updateCampaignPreset () {
    const presetId = locationData.presetId
    let presetToUpdate
    phishingCampaignPresets.forEach((preset, i, arr) => {
      if (preset.id === presetId) {
        presetToUpdate = preset
      }
    }
    )

    const editedPreset = {
      parameters,
      campaignType: selectedCampaign.campaignType,
      name: selectedCampaign.name,
      description: selectedCampaign.description,
      audienceFilterType
    }

    if (presetToUpdate.campaignType === campaignTypes.CONTINUOUS) {
      editedPreset.sendingChannels = sendingChannels || {}
      editedPreset.learningTypes = learningTypes || {}
      editedPreset.categoryNames = templates
    } else {
      editedPreset.templateNames = templates
    }
    const res = await apiRequestUtils.put(`/api/companies/${companyId}/campaign-presets/${presetId}`, {
      editedCampaign: editedPreset,
      shareLevel: presetToUpdate.shareLevel
    })

    if (res.status === 403) { // TODO: rip most of this out of the frontend and into the slice
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to update campaign preset because you do not have edit access')
      }, 0)
      history.push(`/companies/${companyId}${navigationRoutes.CAMPAIGNS}`)
    } else if (res.status !== 200) {
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to update campaign preset')
      }, 0)
      history.push(`/companies/${companyId}${navigationRoutes.CAMPAIGNS}`)
    } else {
      dispatch(updatePhishingCampaignPresetsAction({ presetId, editedPreset }))
      setTimeout(() => {
        NotificationUtilities.sendSuccessMessage('Preset updated successfully')
      }, 0)

      history.push(`/companies/${companyId}${navigationRoutes.CAMPAIGNS}`)
    }
  }

  function publishLaunchCampaign () {
    const bundledCampaign = {
      parameters,
      campaignType: selectedCampaign.campaignType,
      name: selectedCampaign.name,
      description: selectedCampaign.description,
      audienceFilterType,
      shareLevel
    }

    if (selectedCampaign.campaignType === campaignTypes.CONTINUOUS) {
      bundledCampaign.sendingChannels = sendingChannels
      bundledCampaign.learningTypes = learningTypes
      bundledCampaign.categoryNames = templates
    } else {
      bundledCampaign.templateNames = templates
    }

    history.push(`/companies/${companyId}${navigationRoutes.CAMPAIGNLAUNCHER}`, { campaign: bundledCampaign })
  }

  async function publishTestingCampaign () {
    const emails = testingEmails.filter((email) => email !== '')
    const phones = testingPhoneNumbers.filter((phone) => phone !== '')
    const templateObjs = [...userTemplates, ...sharedTemplates, ...defaultTemplates]
    function goFindTemplateChannel (templateId) {
      let channel
      templateObjs.forEach(templateObj => {
        if (templateObj.id === templateId) {
          channel = templateObj.channel
        }
      })
      return channel
    }

    const selectedChannels = templates.map(templateId => ({ id: templateId, channel: goFindTemplateChannel(templateId) }))
    const phoneTemplateIds = selectedChannels.filter(channelObj => channelObj.channel === 'sms').map(channelObj => channelObj.id)
    const hasSMSTemplates = phoneTemplateIds.length > 0
    const emailTemplateIds = selectedChannels.filter(channelObj => channelObj.channel === 'email').map(channelObj => channelObj.id)
    const hasEmailTemplates = emailTemplateIds.length > 0

    if (!await validateTestingCampaignAudience(emails, phones, hasEmailTemplates, hasSMSTemplates)) {
      return
    }

    NotificationUtilities.sendInfoMessage('Publishing campaign...')

    try {
      const res = await apiRequestUtils.post(`/api/companies/${companyId}/campaigns/test`,
        { emails, emailTemplateIds, phones, phoneTemplateIds })
      const resjson = await res.json()

      if (res.status !== 200) {
        NotificationUtilities.sendErrorMessage(resjson.message)
        return
      }

      NotificationUtilities.sendSuccessMessage('Preview campaign successfully sent!')
      setPreviewSuccess(true)
    } catch (err) {
      console.error(err)
      NotificationUtilities.sendErrorMessage('Failed to create testing campaign!')
    }
  }

  async function publishPresetCampaign () {
    try {
      NotificationUtilities.sendInfoMessage('Publishing campaign...')

      const res = await apiRequestUtils.post(`/api/companies/${companyId}/campaign-presets`, {
        templateNames: templates,
        categoryNames: templates,
        parameters,
        campaignType: selectedCampaign.campaignType,
        audienceFilterType,
        name: selectedCampaign.name,
        description: selectedCampaign.description,
        shareLevel,
        sendingChannels: sendingChannels || {},
        learningTypes: learningTypes || {}
      })

      const preset = await res.json()
      const presets = [...phishingCampaignPresets]
      presets.unshift(
        {
          id: preset.id,
          parameters,
          templateNames: templates,
          categoryNames: templates,
          campaignType: selectedCampaign.campaignType,
          name: selectedCampaign.name,
          description: selectedCampaign.description,
          shareLevel,
          sendingChannels,
          learningTypes
        }
      )

      // Set the campaign presets in the store with the newly added preset
      dispatch(setPhishingCampaignPresetsAction(presets))

      // With the preset saved, run publishing for a launch
      publishLaunchCampaign()
    } catch (err) {
      console.error(err)
      NotificationUtilities.sendErrorMessage('Failed to publish campaign!')
    }
  }

  const bundleCampaignFrequency = (campaignFrequency) => {
    const newCampaignFrequency = {
      iterations: campaignFrequency.iterations.value,
      frequency: campaignFrequency.frequency.value,
      weekday: +campaignFrequency.weekday.value,
      time: campaignFrequency.time
    }
    if (campaignFrequency.frequency.value === 'Quarter') {
      newCampaignFrequency.month = +campaignFrequency.month.value
    }
    if (campaignFrequency.frequency.value !== 'Week') {
      newCampaignFrequency.week = campaignFrequency.week.map(week => +week.value)
    }
    return newCampaignFrequency
  }

  async function updateCampaign () {
    const campaignId = locationData.campaignId

    const newCampaign = {}
    try {
      const newCampaignFrequency = bundleCampaignFrequency(campaignFrequency)

      newCampaign.parameters = parameters
      newCampaign.startTime = startTime.toISOString()
      newCampaign.audienceFilterType = audienceFilterType

      if (selectedCampaign.phishingAttemptWindowDays !== phishingAttemptWindowDays) {
        newCampaign.phishingAttemptWindowDays = phishingAttemptWindowDays
      }

      if (selectedCampaign.campaignType === campaignTypes.CONTINUOUS) {
        newCampaign.sendingChannels = sendingChannels
        newCampaign.learningTypes = learningTypes
        newCampaign.categoryNames = templates
      } else {
        newCampaign.templateNames = templates
      }

      if (nextFireTimes.length) {
        newCampaign.nextFireTimestamp = nextFireTimes[0].toISO()
      }

      const res = await apiRequestUtils.put(`/api/companies/${companyId}/campaigns/${campaignId}`, {
        editedCampaign: {
          ...newCampaign,
          campaignFrequency: newCampaignFrequency,
          campaignType: selectedCampaign.campaignType
        }
      })

      if (res.status !== 200) {
        if (res.status === 400) {
          const statusObj = await res.json()
          setTimeout(() => {
            NotificationUtilities.sendErrorMessage(statusObj.error)
          }, 0)
        } else {
          setTimeout(() => {
            NotificationUtilities.sendErrorMessage('Failed to save your campaign!')
          }, 0)
        }
      } else {
        // Get the processed nextFireTimestamp as a Timestamp { _seconds } obj so it's consistent with the non local changed campaigns
        const { nextFireTimestamp } = await res.json()
        dispatch(updatePhishingCampaignAction({
          campaignId,
          ...selectedCampaign,
          ...newCampaign,
          nextFireTimestamp,
          campaignFrequency: newCampaignFrequency
        }))

        setTimeout(() => {
          NotificationUtilities.sendSuccessMessage('Successfully saved your campaign!')
        }, 0)
        history.push(`/companies/${companyId}${navigationRoutes.CAMPAIGNS}`)
      }
    } catch (err) {
      console.error(err)
      NotificationUtilities.sendErrorMessage('Failed to save your campaign!')
    }
  }

  const initializeAudienceStats = async () => {
    try {
      const res = await apiRequestUtils.post(`/api/companies/${companyId}/audience/stats`, {
        isGroup: audienceFilterType === 'group',
        userParameters: parameters[0]
          ? parameters
          : [{
              field: 'first',
              type: '!=',
              value: '~~~'
            }]
      })

      if (res.status === 200) {
        const audience = await res.json()
        const departments = audience.users.filter(user => user.deptname).map((user) => user.deptname)
        const supervisors = audience.users.filter(user => user.supvemail).map((user) => user.supvemail)

        if (audienceFilterType === AUDIENCE_FILTER_TYPES.DEPARTMENT_NAME) {
          departments.push(parameters[0].value)
        } else if (audienceFilterType === AUDIENCE_FILTER_TYPES.DEPARTMENT_NAME) {
          supervisors.push(parameters[0].value)
        }

        setDepartmentNames([...new Set(departments)])
        setSupervisorEmails([...new Set(supervisors)])
      } else {
        NotificationUtilities.sendErrorMessage('Failed to load audience. Please try again or reach out to Phin Support for assistance.')
      }
    } catch (error) {
      NotificationUtilities.sendErrorMessage('Failed to load audience. Please try again or reach out to Phin Support for assistance.')
    }
  }

  async function refreshAudienceStats (parameters) {
    try {
      if (parameters.length > 0) {
        const resp = await apiRequestUtils.post(`/api/companies/${companyId}/audience/stats`,
          { isGroup: audienceFilterType === 'group', userParameters: parameters })
        const audience = await resp.json()
        setAudienceStats(audience?.users)
      } else {
        setAudienceStats([])
      }
    } catch (err) {
      console.error(err)
      NotificationUtilities.sendErrorMessage('Failed to fetch audience!')
    }
  }

  async function validateTestingCampaignAudience (emails, phones, hasEmailTemplates, hasSMSTemplates) {
    try {
      if (hasEmailTemplates && emails.length === 0) {
        throw Error('Please add at least one recipient\'s email to run a preview.')
      }

      if (hasSMSTemplates && phones.length === 0) {
        throw Error('You have selected sms templates but did not provide a phone number')
      }

      if (!ValidationUtils.areValidEmailAddresses(emails)) {
        throw Error('You must enter at least one valid email.')
      }

      if (!ValidationUtils.areValidPhoneNumbers(phones)) {
        throw Error('You must enter valid 10 digit phone numbers')
      }

      return true
    } catch (err) {
      NotificationUtilities.sendWarningMessage(err.message)
      return false
    }
  }

  function isValidCampaign () {
    if (selectedCampaign.campaignType === campaignTypes.CONTINUOUS) {
      if (ValidationUtils.allFalse(sendingChannels)) {
        NotificationUtilities.sendWarningMessage('You must select at least one sending channel')
        return false
      }

      if (ValidationUtils.allFalse(learningTypes)) {
        NotificationUtilities.sendWarningMessage('You must select at least one learning type')
        return false
      }
    }

    if (!parameters || parameters.length === 0) {
      NotificationUtilities.sendWarningMessage('You must select an audience filter or a group')
      return false
    }

    if (audienceFilterType !== 'group') {
      if (parameters[0].field !== 'first') {
        if (!ValidationUtils.isNotWhiteSpace(parameters[0].value)) {
          NotificationUtilities.sendWarningMessage('You must pass in a valid value for the audience filter. It can not be blank!')
          return false
        }
      }
    }

    if (!ValidationUtils.isNotUndefinedOrNull(templates) || templates.length === 0) {
      if (selectedCampaign.campaignType === campaignTypes.FIXEDLENGTH) {
        NotificationUtilities.sendWarningMessage('You must select at least one template for the campaign')
      } else {
        NotificationUtilities.sendWarningMessage('You must select at least one template category for the campaign')
      }
      return false
    }

    const { status, reason } = validatePhishingCampaign({ type: 'update', isCampaignFrequencyWeeksValid })

    if (!status) {
      NotificationUtilities.sendWarningMessage(reason)
      return false
    }

    return true
  }

  function updateSendingChannels (channelName) {
    const newSendingChannels = Object.assign({}, sendingChannels)
    newSendingChannels[channelName] = !newSendingChannels[channelName]
    setSendingChannels(newSendingChannels)
  }

  function updateLearningTypes (learningType) {
    const newLearningTypes = Object.assign({}, learningTypes)
    newLearningTypes[learningType] = !newLearningTypes[learningType]
    setLearningTypes(newLearningTypes)
  }

  function renderButtonText () {
    const edit = locationData.edit

    if (edit) {
      return 'Save'
    } else {
      if (publishOption === 'preview') {
        return 'Run Preview'
      } else {
        return 'Next'
      }
    }
  }

  function toggleValidationModal () {
    setValidationModal(!validationModal)
  }

  function renderTemplateSelector (edit, presetId) {
    if (selectedCampaign.campaignType === campaignTypes.FIXEDLENGTH) {
      return (
        <CampaignTemplateEditor
          editCallback={setTemplates}
          selectedTemplates={templates}
          parameters={parameters}
          allTemplates={[...userTemplates, ...defaultTemplates, ...sharedTemplates]}
        />
      )
    } else {
      return (
        <CampaignTemplateCategoryEditor
          editCallback={setTemplates}
          selectedCampaignCategories={templates}
          campaignCategories={campaignCategories}
          allTemplates={[...userTemplates, ...defaultTemplates, ...sharedTemplates]}
          updateSendingChannels={updateSendingChannels}
          toggleLearningTypes={updateLearningTypes}
          sendingChannels={sendingChannels || []}
          learningTypes={learningTypes || []}
          disableUpdating={edit && !presetId}
        />
      )
    }
  }

  return (
    <>
      <PhinModal
        isOpen={validationModal}
        title='Launch Campaign'
        close={() => setValidationModal(false)}
        closeText='Cancel'
        action={publishLaunchCampaign}
        actionText='Launch'
      >
        <p>Are you sure you want to continue without saving? You'll have to remake the entire campaign if you want to rerun it!</p>
      </PhinModal>

      <div className='phin-h2 padding-bottom:-2'>{locationData.edit ? 'Edit' : 'Create'} Phishing {locationData.presetId ? 'Preset' : 'Campaign'}</div>
      <div id='campaign-name' className='phin-h5'>{selectedCampaign.name}</div>
      <AudienceFilters
        departmentNames={departmentNames}
        supervisorEmails={supervisorEmails}
        groups={groups}
        audienceFilterType={audienceFilterType}
        setAudienceFilterType={setAudienceFilterType}
        users={audienceStats}
        parameters={parameters}
        setParameters={setParameters}
      />

      {locationData.edit && !locationData.presetId &&
        <>
          <CampaignFrequencySelector
            numberOfItems={templates.length > 0 ? templates.length : 5}
            startTime={moment(startTime)}
            setStartTime={setStartTime}
            phishingAttemptWindowDays={phishingAttemptWindowDays}
            setPhishingAttemptWindowDays={setPhishingAttemptWindowDays}
            campaignFrequency={campaignFrequency}
            tz={company ? company.timezone.value : 'America/New_York'}
            campaignType={campaignTypes.PHISHING}
            isRunning={selectedCampaign ? selectedCampaign.running : false}
            nextFireTimes={nextFireTimes}
            firstFireDate={firstFireDate}
            nextFireTimesErrorMessage={nextFireTimesErrorMessage}
            isCampaignFrequencyWeeksValid={isCampaignFrequencyWeeksValid}
            setIsCampaignFrequencyWeeksValid={setIsCampaignFrequencyWeeksValid}
          />
        </>}

      {renderTemplateSelector(locationData.edit, locationData.presetId)}

      {!locationData.edit &&
        <>
          <PublishSelector
            option={publishOption}
            handleOptions={setPublishOption}
            previewSuccess={previewSuccess}
            handleShareLevel={setShareLevel}
            handleEmail={setTestingEmails}
            emails={testingEmails}
            handlePhones={setTestingNumbers}
            phones={testingPhoneNumbers}
            allowPreview={selectedCampaign.campaignType !== campaignTypes.CONTINUOUS}
          />
        </>}

      <StepperButtonTray
        cancel={() => { history.push(`/companies/${companyId}${navigationRoutes.CAMPAIGNS}`); dispatch(resetAllCampaignFrequencyChoicesAction()) }}
        action={saveCampaign}
        actionText={renderButtonText()}
      />
    </>
  )
}

export default CampaignEditor
