import apiSlice from './apiSlice'

export const reportedEmailsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPartnerReportedEmails: builder.query({
      query: (partnerId) => `/partners/${partnerId}/triaging/reported-emails`,
      providesTags: ['ReportedEmails']
    }),
    getPartnerReportedEmailById: builder.query({
      query: ({ partnerId, messageId }) => `/partners/${partnerId}/triaging/reported-emails/${messageId}`,
      providesTags: ['ReportedEmails']
    }),
    resolveReportedEmail: builder.mutation({
      query: ({ partnerId, reportedEmailId, disposition, tags }) => ({
        url: `/partners/${partnerId}/triaging/reported-emails/${reportedEmailId}/resolve`,
        method: 'POST',
        body: { disposition, tags }
      }),
      invalidatesTags: ['ReportedEmails']
    })
  })
})

export const {
  useGetPartnerReportedEmailsQuery,
  useGetPartnerReportedEmailByIdQuery,
  useResolveReportedEmailMutation
} = reportedEmailsApi
