import NotificationUtilities from '../pages/components/notifications/notificationUtils'
import apiSlice from './apiSlice.js'

export const cwApi = apiSlice.injectEndpoints({
  tagTypes: ['CwCompanies', 'CwCompanyMappings'],
  endpoints: (builder) => ({
    connectIntegration: builder.mutation({
      query: ({ partnerId, cwPartnerId, publicKey, privateKey, cwApiUrl }) => ({
        url: `/partners/${partnerId}/integrations/cw-billing-api/connect`,
        body: { cwPartnerId, publicKey, privateKey, cwApiUrl },
        method: 'POST'
      })
    }),
    disconnectIntegration: builder.mutation({
      query: ({ partnerId }) => ({
        url: `/partners/${partnerId}/integrations/cw-billing-api/disconnect`,
        method: 'DELETE'
      })
    }),
    getCwMappings: builder.query({
      query: (partnerId) => `/partners/${partnerId}/integrations/cw-billing-api/company-mapping`,
      providesTags: ['CwCompanyMappings']
    }),
    getCwCompanies: builder.query({
      query: ({ partnerId, nameFilter }) => `/partners/${partnerId}/integrations/cw-billing-api/companies?name=${nameFilter}`,
      providesTags: ['CwCompanies']
    }),
    getCwAgreements: builder.query({
      query: ({ partnerId, cwCompanyId, nameFilter }) => `/partners/${partnerId}/integrations/cw-billing-api/${cwCompanyId}/agreements?name=${nameFilter}`
    }),
    getCwAdditions: builder.query({
      query: ({ partnerId, cwAgreementId, nameFilter }) => `/partners/${partnerId}/integrations/cw-billing-api/agreements/${cwAgreementId}/additions?name=${nameFilter}`
    }),
    setCwMapping: builder.mutation({
      query: ({ partnerId, companyId, mappingUpdate }) => ({
        url: `/partners/${partnerId}/integrations/cw-billing-api/company-mapping`,
        method: 'POST',
        body: { companyId, mappingUpdate }
      }),
      invalidatesTags: ['CwCompanies'],
      async onQueryStarted ({ partnerId, companyId, mappingUpdate }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          cwApi.util.updateQueryData('getCwMappings', partnerId, (draft) => {
            if (draft.rows) {
              // Update the specific mapping with the new changes

              draft.rows = draft.rows.map((mapping) => {
                if (mapping.companyId === companyId) {
                  return { ...mapping, ...mappingUpdate }
                }

                return mapping
              })
            }
          })
        )

        try {
          await queryFulfilled
        } catch {
          NotificationUtilities.sendErrorMessage('Failed to update ConnectWise Company Mapping')
          patchResult.undo()
        }
      }
    }),
    deleteCwMapping: builder.mutation({
      query: ({ partnerId, companyId }) => ({
        url: `/partners/${partnerId}/integrations/cw-billing-api/company-mapping/${companyId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['CwCompanies'],
      async onQueryStarted ({ partnerId, companyId, mappingUpdate }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          cwApi.util.updateQueryData('getCwMappings', partnerId, (draft) => {
            if (draft.rows) {
              // Remove the 'deleted' mappings fields
              draft.rows = draft.rows.map((mapping) => {
                if (mapping.companyId === companyId) {
                  delete mapping.cwCompanyId
                  delete mapping.cwCompanyName
                  delete mapping.cwAgreementId
                  delete mapping.cwAgreementName
                  delete mapping.cwAdditionId
                  delete mapping.cwAdditionName

                  return mapping
                } else {
                  return mapping
                }
              })
            }
          })
        )

        try {
          await queryFulfilled
        } catch {
          NotificationUtilities.sendErrorMessage('Failed to update ConnectWise Company Mapping')
          patchResult.undo()
        }
      }
    }),
    syncCwCompanies: builder.mutation({
      query: ({ partnerId }) => ({
        url: `/partners/${partnerId}/integrations/cw-billing-api/sync`,
        method: 'POST'
      }),
      invalidatesTags: ['CwCompanyMappings']
    })
  })
})

export const {
  useConnectIntegrationMutation,
  useDisconnectIntegrationMutation,
  useGetCwMappingsQuery,
  useGetCwCompaniesQuery,
  useLazyGetCwAgreementsQuery,
  useLazyGetCwAdditionsQuery,
  useSetCwMappingMutation,
  useDeleteCwMappingMutation,
  useSyncCwCompaniesMutation
} = cwApi
