import { Button, Tooltip } from '@mui/material'
import { MDBIcon } from 'mdb-react-ui-kit'

export const TrainingTableRow = ({ courseInfo, togglePreviewModal, toggleDetailModal, handleArrayChange, modules, lock, stripe }) => {
  const checkedCourseIds = modules.map(course => course.id)
  return (
    <tr className={(stripe) ? '' : 'stripe'}>
      <th scope='row' style={(lock) ? { pointerEvents: 'none', opacity: '0.6' } : {}}>
        <div className='form-check'>
          <input onChange={() => handleArrayChange(courseInfo)} className='form-check-input' type='checkbox' checked={checkedCourseIds.includes(courseInfo.id)} id={`checkbox-${courseInfo.id}`} />
          <label className='form-check-label' for={`checkbox-${courseInfo.id}`} />
        </div>
      </th>
      <td>{courseInfo.provider}</td>
      <td>{courseInfo.name}</td>
      <td>
        <Tooltip
          style={{ zIndex: '2' }} className='action-button' placement='top'
          title={`Show additional details for "${courseInfo.name}"`}
        >
          <Button
            rounded
            style={{ color: 'black' }}
            id={`course-details-${courseInfo.id}-button`}
            aria-label={`Course Details ${courseInfo.name} Button`}
            size='sm'
            onClick={() => { toggleDetailModal(courseInfo, true) }}
          >
            <MDBIcon
              size='lg'
              icon='info'
            />
          </Button>
        </Tooltip>
      </td>
      <td>
        <Tooltip
          style={{ zIndex: '2' }} className='action-button' placement='top'
          title='Preview Training Module'
        >
          <Button
            rounded
            type='submit'
            size='sm'
            style={{ color: 'black' }}
            onClick={() => { togglePreviewModal(courseInfo, true) }}
          >
            <MDBIcon
              size='lg'
              color='blue'
              far
              icon='eye'
            />
          </Button>
        </Tooltip>
      </td>
    </tr>
  )
}
