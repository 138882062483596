import React from 'react'
import {
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBBadge
} from 'mdb-react-ui-kit'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { dateIntervals } from '../../../frontendConsts.js'
import './CampaignSelector.css'
import { FormControl, InputLabel, MenuItem, Select, TextField, Tooltip } from '@mui/material'
import MomentUtils from '@date-io/moment'

const buildCampaignOptions = (campaigns, pastCampaigns, selectedCampaignId, handleCampaignSelection) => {
  const campaignOptions = []

  campaignOptions.push(
    <MenuItem key='' value={{ id: '', name: '' }}>All Campaigns</MenuItem>
  )

  // Select all the active campaigns, and the top 5 past campaigns
  for (const campaign of campaigns) {
    campaignOptions.push(
      <MenuItem key={campaign.id} value={campaign}>{campaign.name}<MDBBadge className='mr-2' color='success'>Active</MDBBadge> </MenuItem>
    )
  }

  // Select the 5 most recent past campaigns too
  for (let i = 0; i < pastCampaigns.length && i < 5; i++) {
    const campaign = pastCampaigns[i]

    campaignOptions.push(
      <MenuItem key={campaign.id} value={campaign}>{campaign.name}<MDBBadge className='mr-2' color='primary'>Completed</MDBBadge> </MenuItem>
    )
  }

  return campaignOptions
}

const createDateRangeSelectors = (dateInterval, updateDateRange) => {
  const labels = Object.entries(dateIntervals)
  const mappedLabels = labels.map(entry => {
    const labelName = entry[0]
    const daysAgo = entry[1]
    const active = dateInterval === daysAgo
    const link = <a className={`p-1 ${active ? 'active-date-interval' : ''}`} key={labelName} onClick={() => { updateDateRange({ daysAgo }) }}>{labelName}</a>
    return link
  })
  return (
    <p className='mb-0'>{mappedLabels}</p>
  )
}

const CampaignSelector = ({
  campaigns, pastCampaigns, selectedCampaignId, handleCampaignSelection, startDate, endDate, dateInterval, updateDateRange, earliestDate,
  clickedTimeElapsedFilter, clickedTimeElapsedFilterLabel, setClickedTimeElapsedFilterLabel, setClickedTimeElapsedFilter
}) => {
  let selectedCampaign
  const campaignOptions = buildCampaignOptions(campaigns, pastCampaigns, selectedCampaignId, handleCampaignSelection)
  if (selectedCampaignId === '') {
    selectedCampaign = { name: 'All Campaigns' }
  } else {
    selectedCampaign = [...campaigns, ...pastCampaigns].find(campaign => campaign.id === selectedCampaignId)
  }

  return (
    <MDBRow className=''>
      <MDBCol size='12'>
        <MDBCol>
          <MDBRow className='d-flex align-items-center justify-content-center mt-2'>
            <LocalizationProvider dateAdapter={MomentUtils}>
              <DatePicker
                label=''
                inputFormat='MM/DD/YYYY'
                value={startDate}
                id='campaign-frequency-selector-start-date-picker'
                minDate={earliestDate}
                maxDate={endDate}
                onChange={(value) => updateDateRange({ startDate: value })}
                renderInput={(params) => <TextField onKeyDown={(e) => e.preventDefault()} {...params} />}
                slotProps={{
                  textField: {
                    inputProps: {
                      'data-testid': 'campaign-frequency-selector-start-date-picker'
                    }
                  }
                }}
              />
            </LocalizationProvider>
            <h4 className='mb-0'><MDBIcon className='pl-2 pr-2' icon='long-arrow-alt-right' /></h4>
            <LocalizationProvider dateAdapter={MomentUtils}>
              <DatePicker
                label=''
                inputFormat='MM/DD/YYYY'
                value={endDate}
                id='campaign-frequency-selector-end-date-picker'
                minDate={startDate}
                maxDate={new Date()}
                onChange={(value) => updateDateRange({ endDate: value })}
                renderInput={(params) => <TextField onKeyDown={(e) => e.preventDefault()} {...params} />}
                slotProps={{
                  textField: {
                    inputProps: {
                      'data-testid': 'campaign-frequency-selector-end-date-picker'
                    }
                  }
                }}
              />
            </LocalizationProvider>
          </MDBRow>
          <MDBRow>
            <MDBCol className='d-flex justify-content-end flex-column'>
              {createDateRangeSelectors(dateInterval, updateDateRange)}
            </MDBCol>
            <MDBCol className='d-flex justify-content-end w-auto'>
              <FormControl variant='standard' sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id='campaign-analytics-select-label'>Campaign</InputLabel>
                <Select
                  labelId='campaign-analytics-select-label'
                  id='campaign-analytics-select'
                  value={selectedCampaign || { id: '', name: '' }}
                  onChange={(e) => handleCampaignSelection(e.target.value.id, e.target.value.name)}
                  label='Campaign'
                >
                  {campaignOptions}
                </Select>
              </FormControl>

              <FormControl variant='standard' sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id='time-elapsed-select-label'>
                  Elapsed Time
                  <Tooltip
                    title="Filter records by the time between send and click. Filter records that show they were clicked '30 seconds', '5 minutes', etc. after being sent."
                    placement='top'
                  >
                    (?)
                  </Tooltip>
                </InputLabel>
                <Select
                  labelId='time-elapsed-select-label'
                  id='time-elapsed-select'
                  value={JSON.stringify({ filter: clickedTimeElapsedFilter, label: clickedTimeElapsedFilterLabel })}
                  onChange={(e) => {
                    if (e.target.value !== '') {
                      setClickedTimeElapsedFilter(JSON.parse(e.target.value).filter)
                      setClickedTimeElapsedFilterLabel(JSON.parse(e.target.value).label)
                    } else {
                      setClickedTimeElapsedFilter(0)
                      setClickedTimeElapsedFilterLabel('30 Seconds')
                    }
                  }}
                  label='Time Elapsed Filter'
                >
                  <MenuItem key={0} value=''>Reset</MenuItem>
                  <MenuItem key={30} value={JSON.stringify({ filter: 30, label: '30 Seconds' })}>30 Seconds</MenuItem>
                  <MenuItem key={60} value={JSON.stringify({ filter: 60, label: '1 Minute' })}>1 Minute</MenuItem>
                  <MenuItem key={120} value={JSON.stringify({ filter: 120, label: '2 Minutes' })}>2 Minutes</MenuItem>
                  <MenuItem key={300} value={JSON.stringify({ filter: 300, label: '5 Minutes' })}>5 Minutes</MenuItem>
                  <MenuItem key={600} value={JSON.stringify({ filter: 600, label: '10 Minutes' })}>10 Minutes</MenuItem>
                </Select>
              </FormControl>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBCol>
    </MDBRow>
  )
}

export default CampaignSelector
