import React, { useState, useEffect } from 'react'
import { Autocomplete, Button, TextField } from '@mui/material'
import { getGoogleOauthUrlThunk, getGoogleWorkspaceGroups, getGoogleWorkspaceUsers, getGoogleWorkspaceUsersByGroup, syncGoogleWorkspaceUsers } from '../../../store/GoogleUserSyncIntegrationSlice'
import { useDispatch, useSelector } from 'react-redux'
import { DataGrid } from '@mui/x-data-grid'
import { smartRoute } from '../../../utils/RouterUtils'
import { useHistory } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

export function GoogleUserSync ({ id }) {
  const dispatch = useDispatch()
  const { company } = useSelector((state) => state.company)
  const integrationIsActive = company?.refreshToken

  const { authorization } = useSelector((state) => state.auth)
  const { logout } = useAuth0()
  const history = useHistory()

  if (company?.featureFlags?.googleUserSync === false) {
    smartRoute({ authorization, history, logout })
  }

  const [users, setUsers] = useState([])
  const [groups, setGroups] = useState([])
  const [selectedGroup, setSelectedGroup] = useState(null)

  async function getUsersFromGoogleByGroup () {
    const res = await dispatch(getGoogleWorkspaceUsersByGroup(id, selectedGroup.id))
    res.json().then(data => {
      setUsers(data.users || [])
    })
  }
  async function getUsersFromGoogle () {
    const res = await dispatch(getGoogleWorkspaceUsers(id))
    res.json().then(data => {
      setUsers(data.users || [])
    })
  }

  async function getGroupsFromGoogle () {
    const res = await dispatch(getGoogleWorkspaceGroups(id))
    res.json().then(data => {
      let fetchedGroups = data.groups || []
      fetchedGroups = fetchedGroups.map(group => ({ id: group.id, label: group.name }))
      setGroups(fetchedGroups)
    })
  }
  useEffect(() => {
    if (company?.refreshToken) {
      getUsersFromGoogle()
      getGroupsFromGoogle()
    }
  }, [company])

  useEffect(() => {
    if (selectedGroup) {
      getUsersFromGoogleByGroup()
    } else {
      getUsersFromGoogle()
    }
  }, [selectedGroup])

  const usersTableColumns = [
    { field: 'name', headerName: 'Name', valueGetter: (params) => params.row.name.fullName, minWidth: 100, flex: 1.5 },
    { field: 'email', headerName: 'Email', valueGetter: (params) => params.row.primaryEmail, minWidth: 100, flex: 1 }
  ]

  async function oauthWithGoogle () {
    const res = await dispatch(getGoogleOauthUrlThunk(id))
    if (res.status !== 200) {
      console.log('Error getting Google Oauth URL')
    }

    res.json().then(data => {
      window.open(data.url)
    })
  }

  return (
    <div>
      <Button
        variant='contained'
        onClick={oauthWithGoogle}
      >Auth with Google
      </Button>
      <hr />
      {integrationIsActive &&
        <div>
          <Autocomplete
            sx={{ width: 500 }}
            options={groups}
            renderInput={(params) => <TextField {...params} label='Filter by Group' />}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={selectedGroup}
            onChange={(event, newValue) => {
              if (newValue) {
                setSelectedGroup(newValue)
              } else {
                setSelectedGroup(null)
              }
            }}
          />

          <DataGrid
            style={{ borderRadius: '8px', border: '2px solid var(--phin-light-gray)' }}
            className='DataGrid'
            initialState={{
              sorting: {
                sortModel: [{ field: 'name', sort: 'asc' }]
              }
            }}
            autoHeight
            rows={users}
            columns={usersTableColumns}
            rowsPerPageOptions={[10, 25, 50]}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500, id: 'partner-admin-table-search', 'aria-label': 'Search' },
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true }
              }
            }}
            disableSelectionOnClick
            disableColumnSelector
            disableDensitySelector
            disableColumnFilter
            disableColumnMenu
          />
          <Button variant='outlined' onClick={() => dispatch(syncGoogleWorkspaceUsers(id, users))}>Sync Users</Button>
        </div>}

    </div>
  )
}
