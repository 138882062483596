import { createSlice } from '@reduxjs/toolkit'

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    authorization: null,
    isLoadingAuthorization: false,
    featureToggles: {},
    isLoadingFeatureToggles: false
  },
  reducers: {
    setAuthorization: (state, action) => {
      state.authorization = action.payload
    },
    setIsLoadingAuthorization: (state, action) => {
      state.isLoadingAuthorization = action.payload
    },
    setFeatureToggles: (state, action) => {
      state.featureToggles = action.payload
    },
    setIsLoadingFeatureToggles: (state, action) => {
      state.isLoadingFeatureToggles = action.payload
    }
  }
})

export const {
  setAuthorization: setAuthorizationAction,
  setIsLoadingAuthorization: setIsLoadingAuthorizationAction,
  setFeatureToggles: setFeatureTogglesAction,
  setIsLoadingFeatureToggles: setIsLoadingFeatureTogglesAction

} = authSlice.actions

export default authSlice.reducer

export const getAuthorizationThunk = (id) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingAuthorizationAction(true))

    const res = await api.post(`/api/auth/users/${id}/login`)
    if (res.status !== 200) {
      throw new Error('We could not authorize you')
    } else {
      const authorization = await res.json()
      await dispatch(setAuthorizationAction(authorization))
      dispatch(setIsLoadingAuthorizationAction(false))
      return authorization
    }
  }
}

export const getAuthorizationSilentlyThunk = (id) => {
  return async (dispatch, getState, api) => {
    const res = await api.post(`/api/auth/users/${id}/login`)
    if (res.status !== 200) {
      throw new Error('We could not authorize you')
    } else {
      const authorization = await res.json()
      await dispatch(setAuthorizationAction(authorization))

      return authorization
    }
  }
}

export const updateHasSeenReportAPhishThunk = ({ adminId, hasSeenReportAPhish }) => {
  return async (dispatch, getState, api) => {
    try {
      const response = await api.post(`/api/auth/users/${adminId}/seen-report-a-phish`, { hasSeenReportAPhish })

      if (response.status === 200) {
        const authorization = getState().auth.authorization
        await dispatch(setAuthorizationAction({ ...authorization, hasSeenReportAPhish }))
        return true
      } else {
        console.debug(`Failed to update hasSeenReportAPhish for Admin: ${adminId}.`)
      }
    } catch (error) {
      console.debug(`Failed to update hasSeenReportAPhish for Admin: ${adminId}.`)
    }

    return false
  }
}

export const updateHasSeenCWBillingThunk = ({ adminId, hasSeenCWBilling }) => {
  return async (dispatch, getState, api) => {
    try {
      const response = await api.post(`/api/auth/users/${adminId}/seen-cw-billing`, { hasSeenCWBilling })

      if (response.status === 200) {
        const authorization = getState().auth.authorization
        await dispatch(setAuthorizationAction({ ...authorization, hasSeenCWBilling }))
        return true
      } else {
        console.debug(`Failed to update hasSeenCWBilling for Admin: ${adminId}.`)
      }
    } catch (error) {
      console.debug(`Failed to update hasSeenCWBilling for Admin: ${adminId}.`)
    }

    return false
  }
}

export const getFeatureToggleEnabledThunk = (featureToggleName, id) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingFeatureTogglesAction(true))
    const state = getState()
    const res = await api.get(`/api/auth/feature-toggle-enabled/${featureToggleName}/${id}`)
    if (res.status !== 200) {
      throw new Error('We could not get the feature toggle')
    } else {
      const { featureToggleEnabled } = await res.json()
      const updatedFeatureToggles = { ...state.auth.featureToggles, [id]: { ...state.auth.featureToggles[id], [featureToggleName]: featureToggleEnabled } }
      await dispatch(setFeatureTogglesAction(updatedFeatureToggles))
      dispatch(setIsLoadingFeatureTogglesAction(false))
      return featureToggleEnabled
    }
  }
}
