import { Button } from '@mui/material'
import { useRef, useState } from 'react'

export function FileUploadInput ({ file, setFile, acceptedFileTypes }) {
  const [dragActive, setDragActive] = useState(false)

  // React Ref for the input element
  const inputRef = useRef(null)

  function handleFile (file) {
    setFile(file)
  }

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files[0])
    }
  }

  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault()
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files[0])
    }
  }

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click()
  }

  return (
    <form id='form-file-upload' onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
      <input ref={inputRef} type='file' id='input-file-upload' onChange={handleChange} accept={acceptedFileTypes} />
      <label id='label-file-upload' htmlFor='input-file-upload' className={dragActive ? 'drag-active' : ''}>
        {
          !file
            ? (
              <div>
                <p>Drag and drop your file here or</p>
                <Button className='upload-button' onClick={onButtonClick}>Upload a File</Button>
              </div>
              )
            : (
              <div>
                <p>{file.name}</p>
                <Button className='upload-button' onClick={onButtonClick}>Replace File</Button>
              </div>
              )
            }
      </label>
      {dragActive && <div id='drag-file-element' onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop} />}
    </form>
  )
}
