import moment from 'moment'
import { DateTime, Interval } from 'luxon'
import { PHISHING_DEFAULT_SEND_WINDOW } from '../frontendConsts'

export const getYearAndNextMonth = (startDate = new Date()) => {
  const nextMonth = (startDate.getMonth() === 11 ? 0 : startDate.getMonth() + 1)
  const year = (nextMonth === 0) ? startDate.getFullYear() + 1 : startDate.getFullYear()
  return { year, nextMonth }
}

export const calculateStartTime = (startDate = new Date()) => {
  const { year, nextMonth } = getYearAndNextMonth(startDate)

  // this calculates the first monday of a month in all cases
  let firstMonday = moment().set('year', year).set('month', nextMonth).set('date', 1).isoWeekday(8)
  firstMonday = (firstMonday.date() === 8 ? firstMonday.isoWeekday(-6) : firstMonday)

  return firstMonday
}

export const determinePhishingScheduleString = (campaign, companyTimeZone) => {
// iterations - the number of iterations per frequency
//      'All At Once', 'Once', or 'Twice' (Only if frequency is 'Month')
// frequency - the frequency we want to launch
//      'Week', 'Month', or 'Quarter'
// week - the weeks that we want to launch
//      always 1 for weekly, 1-4 and have 1 or 2 values for monthly based on iterations
// weekday - the day of the week we want to launch
//      0-6, 0 is Sunday, 6 is Saturday
// month - the month of the quarter that we want to launch
//      null if weekly or monthly, 1-3 for quarterly
  const campaignSchedule = campaign?.campaignFrequency

  const calcFromTime =
      (DateTime.local().setZone(companyTimeZone) > DateTime.fromISO(campaign.startTime))
        ? DateTime.local()
        : DateTime.fromISO(campaign.startTime)

  // Convert our weekday number to Luxon's weekday number
  // Phin: 0 - Sunday, 6 - Saturday
  // Luxon: 1 - Monday, 7 - Sunday
  const weekday = campaignSchedule.weekday === 0 ? 7 : campaignSchedule.weekday

  // Init our DateTimes
  let nextLaunchDate, currentFrequencyLaunchTime, nextFrequencyLaunchTime

  // Campaigns firing once per iteration (Weekly, Monthly, Quarterly)
  if (campaignSchedule?.iterations === 'Once') {
    if (campaignSchedule.frequency === 'Quarter') {
      currentFrequencyLaunchTime = calcFromTime.startOf('quarter')
        .plus({ months: campaignSchedule.month - 1 }).startOf('month')
        .plus({ weeks: campaignSchedule.week[0] - 1 })
      nextFrequencyLaunchTime = calcFromTime.startOf('quarter').plus({ quarters: 1 })
        .plus({ months: campaignSchedule.month - 1 }).startOf('month')
        .plus({ weeks: campaignSchedule.week[0] - 1 })
    } else if (campaignSchedule.frequency === 'Month') {
      currentFrequencyLaunchTime = calcFromTime.startOf('month')
        .plus({ weeks: campaignSchedule.week[0] - 1 })
      nextFrequencyLaunchTime = calcFromTime.startOf('month').plus({ months: 1 })
        .plus({ weeks: campaignSchedule.week[0] - 1 })
    } else if (campaignSchedule.frequency === 'Week') {
      currentFrequencyLaunchTime = calcFromTime.startOf('week')
      nextFrequencyLaunchTime = calcFromTime.startOf('week').plus({ weeks: 1 })
    }
    // Adjust the week if the calculated weekday is greater than the weekday we set
    // This is important for the first week of a given month
    (currentFrequencyLaunchTime.weekday > weekday)
      ? currentFrequencyLaunchTime = currentFrequencyLaunchTime.plus({ weeks: 1 }).set({ weekday })
      : currentFrequencyLaunchTime = currentFrequencyLaunchTime.set({ weekday });

    (nextFrequencyLaunchTime.weekday > weekday)
      ? nextFrequencyLaunchTime = nextFrequencyLaunchTime.plus({ weeks: 1 }).set({ weekday })
      : nextFrequencyLaunchTime = nextFrequencyLaunchTime.set({ weekday });

    // Decide which launch date is next
    (calcFromTime < currentFrequencyLaunchTime)
      ? nextLaunchDate = currentFrequencyLaunchTime
      : nextLaunchDate = nextFrequencyLaunchTime

    let weekdaysFound = 0
    let weekdayRange = campaign.phishingAttemptWindowDays || PHISHING_DEFAULT_SEND_WINDOW
    let endLaunchDate = DateTime.fromISO(nextLaunchDate.toISO()).setZone(companyTimeZone)

    // Check that we are not on a weekend to start
    if (endLaunchDate.weekday !== 6 && endLaunchDate.weekday !== 7) {
      // We can count today as a valid send day to start (not Saturday or Sunday)
      weekdayRange -= 1
    }

    while (weekdaysFound < weekdayRange) {
      endLaunchDate = endLaunchDate.plus({ days: 1 })

      if (endLaunchDate.weekday !== 6 && endLaunchDate.weekday !== 7) {
        weekdaysFound++
      }
    }

    return {
      start: nextLaunchDate.toFormat('MM/dd/yyyy'),
      end: endLaunchDate.toFormat('MM/dd/yyyy')
    }
  }
  // Campaigns firing twice per iteration (Monthly Only)
  if (campaignSchedule?.iterations === 'Twice') {
    if (campaignSchedule.frequency === 'Month') {
      let firstIterationLaunchTime = calcFromTime.startOf('month')
        .plus({ weeks: campaignSchedule.week[0] - 1 })
      let secondIterationLaunchTime = calcFromTime.startOf('month')
        .plus({ weeks: campaignSchedule.week[1] - 1 })

      // Grab the next cycle's first iteration if the both iterations are in the past
      if (firstIterationLaunchTime < calcFromTime && secondIterationLaunchTime < calcFromTime) {
        firstIterationLaunchTime = firstIterationLaunchTime.plus({ months: 1 })
      }

      (firstIterationLaunchTime.weekday > weekday)
        ? firstIterationLaunchTime = firstIterationLaunchTime.plus({ weeks: 1 }).set({ weekday })
        : firstIterationLaunchTime = firstIterationLaunchTime.set({ weekday });

      (secondIterationLaunchTime.weekday > weekday)
        ? secondIterationLaunchTime = secondIterationLaunchTime.plus({ weeks: 1 }).set({ weekday })
        : secondIterationLaunchTime = secondIterationLaunchTime.set({ weekday });

      // Decide which launch date is next
      (calcFromTime < firstIterationLaunchTime)
        ? nextLaunchDate = firstIterationLaunchTime
        : nextLaunchDate = secondIterationLaunchTime

      // Subtract one from the window as we include the day we start as a sending day
      let endLaunchDate = DateTime.fromJSDate(nextLaunchDate.toJSDate()).plus({ days: (campaign.phishingAttemptWindowDays || PHISHING_DEFAULT_SEND_WINDOW) - 1 })

      // If we crossed a weekend add two days to properly account we only fire on weekdays
      const thisSaturday = DateTime.fromISO(endLaunchDate.toISO()).set({ weekday: 6 })
      const phishingInterval = Interval.fromDateTimes(nextLaunchDate, endLaunchDate)

      const includesSaturday = phishingInterval.contains(thisSaturday)

      // If we have crossed or ended on a weekend, add two days to account for the weekend
      endLaunchDate = endLaunchDate.plus({ days: includesSaturday ? 2 : 0 })

      return {
        start: nextLaunchDate.toFormat('MM/dd/yyyy'),
        end: endLaunchDate.toFormat('MM/dd/yyyy')
      }
    }
  }
}

export function calculateMonthValueFromIndex (monthIndex) {
  return (monthIndex % 12) + 1
}

/**
   * Formats a date into a localized string representation.
   * @param {DateTime} date - The Luxon date to be formatted.
   * @returns {string} The formatted date string.
   */
export function formatDate (date) {
  return date.toLocaleString(DateTime.DATETIME_SHORT)
}

/**
 * Sanitize campaignFrequency from the front end to not include dropdown/input { text, value } objects.
 * Safe for use with scheduling backend routes.
 * @param {Object} campaignFrequency
 */
export function sanitizeCampaignFrequency (campaignFrequency) {
  const safeFrequency = { ...campaignFrequency }
  for (const [key, value] of Object.entries(safeFrequency)) {
    if (value?.text) {
      // Due to bad input seperation month can become a string when editing a campaign. It MUST be a number
      if (key === 'month') {
        safeFrequency[key] = parseInt(value.value)
      } else {
        safeFrequency[key] = value.value
      }
    }

    // This is an array and we need to process it
    if (value instanceof Array) {
      const newArray = [...value]
      for (let i = 0; i < value.length; i++) {
        if (value[i].text) {
          newArray[i] = value[i].value
        }
      }
      safeFrequency[key] = newArray
    }
  }

  return safeFrequency
}
