import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import DOMPurify from 'dompurify'
import {
  Box,
  Grid,
  Typography,
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  Select,
  MenuItem,
  Button,
  Autocomplete,
  TextField
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { DateTime } from 'luxon'
import { useGetPartnerReportedEmailByIdQuery, useResolveReportedEmailMutation } from '../../../../store/PartnerTriagingSlice'
import { getFeatureToggleEnabledThunk } from '../../../../store/AuthSlice'
import { EMAIL_TRIAGE_TAGS, integrationTypes } from '../../../../frontendConsts'
import NotificationUtilities from '../../../components/notifications/notificationUtils'
import Spinner from '../../../components/Spinner'
import { useSelector, useDispatch } from 'react-redux'
import { smartRoute } from '../../../../utils/RouterUtils'
import { useAuth0 } from '@auth0/auth0-react'

export const ReportedEmailTriagePage = () => {
  const history = useHistory()
  const { id: partnerId, messageId } = useParams()
  const { data: reportedEmail, isLoading: isLoadingReportedEmail } = useGetPartnerReportedEmailByIdQuery({ partnerId, messageId })
  const { authorization, featureToggles } = useSelector((state) => state.auth)
  const { logout } = useAuth0()
  const dispatch = useDispatch()

  const cleanHTML = DOMPurify.sanitize(reportedEmail?.messageContent?.body?.content)

  async function getFeatureToggle () {
    await dispatch(getFeatureToggleEnabledThunk(integrationTypes.PROCESS_REPORTED_EMAILS, partnerId))
  }

  useEffect(() => {
    if (!featureToggles[partnerId] || featureToggles[partnerId]?.processReportedEmails === undefined) {
      getFeatureToggle()
    } else if (!featureToggles[partnerId]?.processReportedEmails) {
      smartRoute({ authorization, history, logout })
    }
  }, [])

  const [tabIndex, setTabIndex] = useState(0)
  const [disposition, setDisposition] = useState('')
  const [tags, setTags] = useState([])

  const [resolveReportedEmail] = useResolveReportedEmailMutation()

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue)
  }

  useEffect(() => {
    if (reportedEmail) {
      setTags(reportedEmail.tags?.map((tag) => tag.name) || [])
    }
  }, [reportedEmail])

  const handleResolve = async () => {
    try {
      if (!disposition) {
        NotificationUtilities.sendErrorMessage('No disposition selected for Email')
        return
      } else {
        await resolveReportedEmail({
          partnerId,
          reportedEmailId: reportedEmail.messageId,
          disposition,
          tags
        })
      }
      history.push(`/partners/${partnerId}/triaging`)
    } catch (error) {
      NotificationUtilities.sendErrorMessage('Failed to save resolution. Please try again or contact Phin Support for assistance.')
    }
  }

  if (!reportedEmail || isLoadingReportedEmail) {
    return (
      <Box>
        <Spinner />
      </Box>
    )
  }

  const regex = /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/g
  const urls = [...new Set(cleanHTML.match(regex) || [])]

  const sortedHeaders = [...reportedEmail.emailHeaders].sort((a, b) => a.name.localeCompare(b.name))

  return (
    <Box sx={{ p: 1 }}>
      <Typography variant='h4' gutterBottom>
        {reportedEmail.subject}
      </Typography>
      <Box
        sx={{
          border: '1px solid #ccc',
          borderRadius: '4px',
          overflow: 'hidden'
        }}
      >
        <Box sx={{ borderBottom: '1px solid #ccc', display: 'flex' }}>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            sx={{ flex: { xs: '1', md: '1' }, '& .MuiTab-root': { textTransform: 'none' } }}
          >
            <Tab label='Headers' />
            <Tab label='URLs' />
          </Tabs>
          <Box
            sx={{
              borderLeft: '1px solid #ccc',
              flex: 1,
              textAlign: 'center',
              display: { xs: 'none', md: 'block' }
            }}
          >
            <Tab label='Preview' />
          </Box>
        </Box>

        <Grid sx={{ minHeight: '50vh' }} container>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              borderRight: { md: '1px solid #ccc' },
              borderBottom: { xs: '1px solid #ccc', md: 'none' }
            }}
          >
            <Box sx={{ maxWidth: '100%', overflow: 'hidden', p: 2 }}>
              {tabIndex === 0 && (
                <Box>
                  <Box sx={{ pl: '1rem' }}>
                    <Typography><strong>From</strong></Typography>
                    <Typography>{reportedEmail.senderEmail}</Typography>
                    <Typography><strong>To</strong></Typography>
                    <Typography>{reportedEmail.reporterName}</Typography>
                    <Typography><strong>Received Time</strong></Typography>
                    <Typography>{DateTime.fromSeconds(Number(reportedEmail.reportedTime?._seconds)).toLocaleString(DateTime.DATETIME_MED)}</Typography>
                    <Typography><strong>Sender IP</strong></Typography>
                    <Typography>{reportedEmail.senderIpAddress}</Typography>
                  </Box>

                  <Box sx={{ maxHeight: '60vh', overflowY: 'auto' }}>
                    {sortedHeaders.map((header, index) => (
                      <Accordion key={index} sx={{ maxWidth: '100%' }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography noWrap sx={{ maxWidth: '100%' }}>
                            {header.name}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ overflowX: 'auto', maxWidth: '100%' }}>
                          <Typography sx={{ wordBreak: 'break-all' }}>
                            {header.value}
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </Box>
                </Box>
              )}
              {tabIndex === 1 && (
                <Box>
                  <Typography variant='h6' gutterBottom>
                    URLs found in the email:
                  </Typography>
                  <List sx={{ listStyleType: 'disc', pl: 2 }}>
                    {urls.length > 0
                      ? (
                          urls.map((url, index) => (
                            <ListItem
                              key={index}
                              sx={{ display: 'list-item', paddingLeft: 0 }}
                            >
                              <ListItemText primary={url} />
                            </ListItem>
                          ))
                        )
                      : (
                        <Typography>No URLs found.</Typography>
                        )}
                  </List>
                </Box>

              )}
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: { xs: 'block', md: 'none' },
                borderTop: '1px solid #ccc',
                textAlign: 'center',
                py: 1
              }}
            >
              <Tab label='Preview' />
            </Box>

            <iframe
              srcDoc={cleanHTML}
              title='content-preview'
              style={{
                width: '100%',
                height: '100%',
                border: 'none',
                overflowY: 'auto',
                maxHeight: '100vh'
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ mt: 4, p: 2, borderTop: '1px solid #ccc' }}>
        <Typography variant='h6'>Actions</Typography>
        <Grid container alignItems='center' justifyContent='space-between'>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', gap: 2, flexDirection: { xs: 'column', md: 'row' } }}>
              <Select
                value={disposition}
                onChange={(e) => setDisposition(e.target.value)}
                displayEmpty
                sx={{ minWidth: 120 }}
              >
                <MenuItem value='' disabled>Select Disposition</MenuItem>
                <MenuItem value='spam'>Spam</MenuItem>
                <MenuItem value='malicious'>Malicious</MenuItem>
                <MenuItem value='safe'>Safe</MenuItem>
              </Select>

              <Autocomplete
                multiple
                options={Object.values(EMAIL_TRIAGE_TAGS)}
                value={tags}
                onChange={(event, newValue) => setTags(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Tags'
                    placeholder='Select tags'
                  />
                )}
                sx={{ flex: 1, marginBottom: { xs: 2, md: 0 } }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6} sx={{ textAlign: { xs: 'center', md: 'right' } }}>
            <Box sx={{ mb: '1rem' }}>
              <Button variant='outlined' sx={{ mr: 2 }} onClick={() => window.history.back()}>Cancel</Button>
              <Button variant='contained' color='primary' onClick={handleResolve}>Resolve</Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
