import { Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import { PhinCard } from '../PhinCard.js'
import { useConnectIntegrationMutation } from '../../../store/ConnectwiseApiSlice.js'
import { ConnectWiseIntegrationForm } from './ConnectWiseIntegrationForm.js'

export function ConfigureConnectWiseIntegration () {
  const { partner } = useSelector(state => state.partner)

  const [enableCWBillingIntegration, { isLoading: isLoadingEnableIntegration }] = useConnectIntegrationMutation()

  return (
    <>
      <Grid container direction='row' spacing={4}>
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <PhinCard title='ConnectWise Permissions' height='60vh'>
            <ul style={{ marginTop: 'var(--phin-s1)' }}>
              <li className='phin-h5'>Companies</li>
              <ul>
                <li className='phin-h6'>Company Maintenance: Inquiry Level</li>
                <p className='margin-left:0'>Phin needs Inquiry Level access for reading the list of Companies from ConnectWise. Phin will store the names and IDs of these Companies.</p>
              </ul>
              <li className='phin-h5 margin-top:2'>Finance</li>
              <ul>
                <li className='phin-h6'>Agreements: Inquiry Level</li>
                <p className='margin-left:0'>Phin needs Inquiry Level access for reading the list of Agreements and Additions. Phin will store the names and IDs of Additions and Agreements.</p>
                <li className='phin-h6'>Agreements: Edit Level</li>
                <p className='margin-left:0'>Phin needs Edit Level access for updating the "Total Quantity" field for the Phin Addition.</p>
              </ul>
            </ul>
          </PhinCard>
        </Grid>

        <Grid item lg={6} md={12} sm={12} xs={12}>
          <ConnectWiseIntegrationForm
            isLoadingEnableIntegration={isLoadingEnableIntegration}
            enableIntegration={enableCWBillingIntegration}
            partnerId={partner.id}
          />
        </Grid>
      </Grid>
    </>
  )
}
