import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  Button, FormControl, FormControlLabel, FormLabel, Grid, IconButton, RadioGroup, Radio, TextField
} from '@mui/material'
import { GetApp } from '@mui/icons-material'
import { IoAdd, IoExitOutline, IoTrash } from 'react-icons/io5'
import { getCompanyThunk, updatePhishingReportEmailThunk, updatePhishingReportOutlookFolderThunk } from '../../../store/CompanySlice'
import { EMAIL_FOLDER_NAMES } from '../../../frontendConsts'

const OutlookAddInSettings = ({
  getCompanyManifestThunk,
  phishingReportEmailCurrentValue
}) => {
  const { id: companyId } = useParams()
  const { company } = useSelector((state) => state.company)
  const dispatch = useDispatch()

  const [phishingReportEmails, setCompanyPhishingReportEmails] = useState(phishingReportEmailCurrentValue ?? ['']) // Array to store email inputs
  const [errorIndices, setErrorIndices] = useState([]) // Array to store indices of invalid emails

  const [defenderForwardEmail, setDefenderForwardEmail] = useState(company.defenderForwardEmail ?? '')
  const [defenderForwardEmailError, setDefenderForwardEmailError] = useState()

  const [outlookFolder, setOutlookFolder] = useState(company.phishingReportOutlookFolder)

  async function handlePhishingReportEmailChange (phishingReportEmails) {
    phishingReportEmails = phishingReportEmails.filter((email) => email !== '')
    await dispatch(updatePhishingReportEmailThunk({ companyId: company.id, phishingReportEmails, defenderForwardEmail }))
  }

  const handleEmailInputChange = (index, value) => {
    const updatedInputs = [...phishingReportEmails]
    updatedInputs[index] = value
    setCompanyPhishingReportEmails(updatedInputs)
    setErrorIndices(prevIndices => prevIndices.filter(idx => idx !== index))
  }

  const handleAddEmailInput = () => {
    if (phishingReportEmails.length < 5) {
      setCompanyPhishingReportEmails([...phishingReportEmails, ''])
    }
  }

  const handleRemoveEmailInput = (index) => {
    const updatedInputs = phishingReportEmails.filter((_, i) => i !== index)
    setErrorIndices(errorIndices.filter((idx) => idx !== index))
    setCompanyPhishingReportEmails(updatedInputs)
  }

  const validateEmails = (emails) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const invalidIndices = []
    emails.forEach((email, index) => {
      if (email === '') {
        return
      }
      if (!emailPattern.test(email)) {
        invalidIndices.push(index)
      }
    })
    return invalidIndices
  }

  const settingRowStyles = {
    border: 'solid 2px var(--phin-light-gray)',
    borderRadius: '8px',
    padding: '2em',
    marginBottom: '1em'
  }

  async function handleSubmit (e) {
    e.preventDefault()
    const invalidIndices = validateEmails(phishingReportEmails)
    const invalidDefenderIndices = validateEmails([defenderForwardEmail])

    if (invalidIndices.length === 0 && invalidDefenderIndices.length === 0) {
      await handlePhishingReportEmailChange(phishingReportEmails)
      await dispatch(getCompanyThunk(companyId))
      return
    }

    if (invalidIndices.length > 0) {
      setErrorIndices(invalidIndices)
    }
    if (invalidDefenderIndices.length > 0) {
      setDefenderForwardEmailError(true)
    }
  }

  async function handleOutlookFolderChange (e) {
    setOutlookFolder(e.target.value)
    await dispatch(updatePhishingReportOutlookFolderThunk({ companyId, phishingReportOutlookFolder: e.target.value }))
  }

  return (
    <div>
      <div style={settingRowStyles}>

        <form onSubmit={handleSubmit}>
          <p className='phin-h4'>Forwarding Addresses</p>
          <p>Emails from phishing campaigns will not be forwarded to this inbox.</p>

          <div className='d-flex flex-row align-items-end margin-bottom:2'>

            <div className='d-flex flex-column justify-content-between'>
              <p className='phin-h5'>Support Inboxes</p>
              <p style={{ margin: 0 }}>Reported emails are sent to the following addresses as EML attachments with the subject line: 🚨PHISH REPORTED: {company.name}</p>
            </div>

          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', marginTop: '1rem', marginBottom: '2rem' }}>

            {phishingReportEmails.map((email, index) => (
              <div key={index}>
                <div className='d-flex flex-row' key={index}>
                  <TextField
                    id={`phishingReportContactInput-${index}`}
                    fullWidth
                    label={`Forwarding Address ${index + 1}`}
                    sx={{ flex: 1 }}
                    value={email}
                    error={errorIndices.includes(index)}
                    helperText={errorIndices.includes(index) && 'Invalid email format'}
                    onChange={(e) => handleEmailInputChange(index, e.target.value)}
                  />

                  <Grid spacing={2} item md={1} className='d-flex justify-end align-items-center'>

                    {index !== 0 &&
                      <IconButton
                        variant='contained'
                        onClick={() => handleRemoveEmailInput(index)}
                        sx={{ color: 'var(--phin-red)', mb: errorIndices.includes(index) && '1rem' }}
                      >
                        <IoTrash />
                      </IconButton>}
                  </Grid>
                </div>
                {index + 1 === phishingReportEmails.length && phishingReportEmails.length !== 5 && (
                  <div style={{ display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'start' }}>
                    <Button
                      onClick={handleAddEmailInput}
                      color='primary'
                      size='small'
                      startIcon={<IoAdd />}
                      variant='outlined'
                      style={{ marginTop: '.75rem' }}
                    >
                      Add
                    </Button>
                  </div>
                )}
              </div>
            ))}
          </div>

          <div style={{ marginBottom: '2rem' }}>
            <p className='phin-h5'>Microsoft Defender Address</p>
            <p>Setup Microsoft User Reported Settings {' '}
              <a href='https://security.microsoft.com/securitysettings/userSubmission'>
                here {' '}
                <IoExitOutline />
              </a>
            </p>

            <TextField
              id='defender-email-input'
              label='Microsoft Defender Email Address'
              fullWidth
              value={defenderForwardEmail}
              onChange={(e) => {
                setDefenderForwardEmailError(false)
                setDefenderForwardEmail(e.target.value)
              }}
              error={defenderForwardEmailError}
              helperText={defenderForwardEmailError && 'Invalid email format'}
            />
          </div>

          <Grid container spacing={1}>
            <Grid item md={12}>
              <Button
                type='submit'
                variant='contained'
                id='saveContactButton'
                sx={{ backgroundColor: 'var(--phin-blue)', float: 'right' }}
              >
                {phishingReportEmails.length === 0 ? 'Save Settings' : 'Save Contacts'}
              </Button>
            </Grid>
          </Grid>
        </form>

      </div>
      <div style={settingRowStyles}>
        <div className='consentTitle'>Outlook Customizations</div>
        <p>
          Customize the end user's experience in Outlook when they use Phin's Phishing Button
        </p>
        <form>
          <FormControl>
            <FormLabel id='folder-radio-buttons-group-label'>Move Reported Non-Phin Emails To</FormLabel>
            <RadioGroup
              aria-labelledby='folder-radio-buttons-group-label'
              value={outlookFolder}
              defaultValue={EMAIL_FOLDER_NAMES.JUNK}
              name='email-folder-radio-buttons-group'
              onChange={handleOutlookFolderChange}
            >
              <FormControlLabel value={EMAIL_FOLDER_NAMES.JUNK} control={<Radio />} label='Junk Email' />
              <FormControlLabel value={EMAIL_FOLDER_NAMES.DELETED_ITEMS} control={<Radio />} label='Deleted Items' />
            </RadioGroup>
          </FormControl>
        </form>
      </div>
      <div style={settingRowStyles}>
        <div className='consentTitle'>Outlook Add-in Manifest</div>
        <p>
          Outlook add-in manifest can be downloaded below. The generated xml file should be installed through your Microsoft Admin Portal.
          Instructions can be found in our knowledge base <a target='_blank' href='https://www.phinsec.io/knowledge/phin-outlook-phishing-button' rel='noreferrer'>here</a>.
        </p>
        <Button
          type='button'
          variant='outlined'
          sx={{ color: 'var(--phin-blue)' }}
          onClick={() => {
            dispatch(getCompanyManifestThunk(companyId))
          }}
        >
          <GetApp /> Download Manifest
        </Button>
      </div>
    </div>
  )
}

export default OutlookAddInSettings
