import { configureStore } from '@reduxjs/toolkit'
import distributorSlice from './store/DistributorSlice'
import sideNavSlice from './store/SideNavSlice'
import topNavSlice from './store/TopNavSlice'
import partnerSlice from './store/PartnerSlice'
import partnerBillingSlice from './store/PartnerBillingSlice'
import resourcesSlice from './store/ResourcesSlice'
import authSlice from './store/AuthSlice'
import companySlice from './store/CompanySlice'
import usersSlice from './store/UsersSlice'
import campaignsSlice from './store/CampaignsSlice'
import logoSlice from './store/LogoSlice'
import trainingSlice from './store/TrainingSlice'
import policiesSlice from './store/PoliciesSlice'
import integrationsSlice from './store/IntegrationsSlice'
import analyticsSlice from './store/AnalyticsSlice'
import templateSlice from './store/TemplatesSlice'
import campaignFrequencySlice from './store/CampaignFrequencySlice'
import TrainingCampaignFrequencySlice from './store/TrainingCampaignFrequencySlice'
import reportViewersSlice from './store/ReportViewersSlice'
import userSyncSlice from './store/UserSyncSlice'
import stagedUsersSlice from './store/StagedUsersSlice'
import messagesSlice from './store/MessagesSlice'
import stagedGroupsSlice from './store/StagedGroupsSlice'
import tempStagedGroupsSlice from './store/TempStagedGroupsSlice'
import spamFilterBypassSlice from './store/SpamFilterBypassSlice'
import { apiRequestUtils } from './utils/apiRequestUtils'
import welcomeEmailSlice from './store/WelcomeEmailSlice'
import brandingSlice from './store/BrandingSlice'
import reportingSettingsSlice from './store/ReportingSettingsSlice'
import phinApiSlice from './store/PhinApiSlice'
import phinDistributorApiSlice from './store/phinDistributorApiSlice'
import gradientMSPApiSlice from './store/GradientMSPIntegrationSlice'
import partnerIntegrationsSlice from './store/PartnerIntegrationSlice'
import distributorIntegrationsSlice from './store/DistributorIntegrationSlice'
import sendRecordSlice from './store/SendRecordSlice'
import reportGeneratorSlice from './store/ReportGeneratorSlice'
import hubSpotPartnersSlice from './store/HubSpotPartnersSlice'
import muiNotificationsSlice from './store/MuiNotificationsSlice'
import googleUserSyncIntegrationSlice from './store/GoogleUserSyncIntegrationSlice'
import apiSlice from './store/apiSlice'

const store = configureStore({
  reducer: {
    distributor: distributorSlice,
    sideNav: sideNavSlice,
    topNav: topNavSlice,
    partner: partnerSlice,
    partnerBilling: partnerBillingSlice,
    resources: resourcesSlice,
    auth: authSlice,
    company: companySlice,
    users: usersSlice,
    logo: logoSlice,
    messages: messagesSlice,
    campaigns: campaignsSlice,
    analytics: analyticsSlice,
    trainings: trainingSlice,
    policies: policiesSlice,
    integrations: integrationsSlice,
    templates: templateSlice,
    campaignFrequency: campaignFrequencySlice,
    trainingCampaignFrequency: TrainingCampaignFrequencySlice,
    reportViewers: reportViewersSlice,
    userSync: userSyncSlice,
    stagedUsers: stagedUsersSlice,
    stagedGroups: stagedGroupsSlice,
    tempStagedGroups: tempStagedGroupsSlice,
    spamFilterBypass: spamFilterBypassSlice,
    welcomeEmail: welcomeEmailSlice,
    branding: brandingSlice,
    reportingSettings: reportingSettingsSlice,
    phinAPI: phinApiSlice,
    phinDistributorAPI: phinDistributorApiSlice,
    gradientMSPAPI: gradientMSPApiSlice,
    partnerIntegrations: partnerIntegrationsSlice,
    distributorIntegrations: distributorIntegrationsSlice,
    sendRecords: sendRecordSlice,
    reportGenerator: reportGeneratorSlice,
    hubSpotPartners: hubSpotPartnersSlice,
    muiNotifications: muiNotificationsSlice,
    googleUserSyncIntegration: googleUserSyncIntegrationSlice,
    [apiSlice.reducerPath]: apiSlice.reducer
  },

  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: apiRequestUtils
      }
    }).concat(apiSlice.middleware)
})

export default store
