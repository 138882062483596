import React, { useState } from 'react'
import { IoLockClosed, IoEye } from 'react-icons/io5'
import CurriculumCourseSelect from '../CurriculumCourseSelect'
import './curriculumContainer.scss'
import { TrainingModuleSelectorTable } from './TrainingSelectorTable'
import { TrainingTopicsSelectorTable } from './TrainingTopicsSelectorTable'
import { Alert, IconButton, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { useSelector } from 'react-redux'
import { campaignTypes, CURRICULUM_BUILD_TYPES } from '../../../../frontendConsts.js'
import { CoursePreviewModal } from '../../../../shared/components/CoursePreviewModal'

export const CurriculumContainer = ({
  campaignType, companyId, curriculumCatalog, lock,
  isContinuous, curriculumBuildType, setCurriculumBuildType,
  modules, setModules, trainingTopics, setTrainingTopics,
  baseTrainingCampaignTopics,
  nonBaseTrainingCampaignTopics,
  setCurriculumDescription,
  curriculumId, setCurriculumId
}) => {
  const [previewCourse, setPreviewCourse] = useState()
  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const { courseCatalog } = useSelector((state) => state.trainings)
  const catalogMap = courseCatalog.reduce((obj, item) => { return { ...obj, [item.id]: item } }, {})

  const handleChangeCurriculumBuildMode = (val) => {
    if (val.target.value !== curriculumBuildType) {
      if (val.target.value === CURRICULUM_BUILD_TYPES.PREBUILT) {
        setCurriculumBuildType(CURRICULUM_BUILD_TYPES.PREBUILT)
        setCurriculumDescription(undefined)
      } else {
        setCurriculumBuildType(CURRICULUM_BUILD_TYPES.MANUAL)
        setCurriculumDescription('Curriculum Builder Generated Curriculum')
        setCurriculumId(undefined)
      }
    }
    setModules([])
  }

  const togglePreviewModal = (course, shouldShowPreviewModal) => {
    const courseLookup = catalogMap[course?.path.split('/')[1]]
    setPreviewCourse(courseLookup)
    setShowPreviewModal(shouldShowPreviewModal)
  }

  return (
    <div className='main-container'>
      {!isContinuous && (
        <>
          <div className='heading-section'>
            <h4 className='phin-h4 margin-bottom:-2'> Curriculum Builder </h4>
            {lock && (<IoLockClosed className='lock-style' />)}
          </div>
          {campaignType === campaignTypes.TRAINING && (
            <div className='desc-switch-container'>
              <div className='desc-toggle'>
                <ToggleButtonGroup
                  value={curriculumBuildType}
                  color='primary'
                  exclusive
                  onChange={handleChangeCurriculumBuildMode}
                  style={(lock) ? { pointerEvents: 'none', opacity: '0.6' } : {}}
                >
                  <ToggleButton
                    id='manual-curriculum-toggle'
                    aria-label='Manual Curriculum Toggle'
                    value={CURRICULUM_BUILD_TYPES.MANUAL}
                  >Manual
                  </ToggleButton>
                  <ToggleButton
                    id='prebuilt-curriculum-toggle'
                    aria-label='Prebuilt Curriculum Toggle'
                    value={CURRICULUM_BUILD_TYPES.PREBUILT}
                  >Prebuilt
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
              <p className='section-content'> Prescribe a curriculum that works for you and your customers using the Curriculum Builder! Choose between building a new curriculum with individual courses, or a prebuilt pack. Note you can only choose one strategy currently.</p>
            </div>
          )}

          <div className='curriculum-builder-container'>
            {(curriculumBuildType === CURRICULUM_BUILD_TYPES.MANUAL) && (
              <div className='course-select-container'>
                <TrainingModuleSelectorTable
                  companyId={companyId}
                  modules={modules}
                  setModules={setModules}
                  rowCount={5}
                  lock={lock}
                />
              </div>
            )}
            {(curriculumBuildType === undefined || curriculumBuildType === CURRICULUM_BUILD_TYPES.PREBUILT) && (
              <>
                <div className='title-and-card-column-container-curriculum'>
                  <h5 className='phin-h5 margin-bottom:-2'>Prebuilt Curriculums</h5>
                  <CurriculumCourseSelect
                    setCurriculumDescription={setCurriculumDescription}
                    setModules={setModules}
                    curriculumId={curriculumId}
                    setCurriculumId={setCurriculumId}
                    curriculumCatalog={curriculumCatalog}
                    lock={lock}
                    trainingTopics={trainingTopics}
                    isContinuous={isContinuous}
                  />
                </div>
                <div className='title-and-card-column-container-preview margin-left:2'>
                  <h5 className='phin-h5 margin-bottom:-2'>Courses</h5>
                  <div className='curriculum-builder-container-preview' style={(lock) ? { pointerEvents: 'none', opacity: '0.6' } : {}}>

                    {modules && modules.length > 1 && modules.map((module, index) => (
                      <div key={module.id} className='module-list-row'>
                        <p className='module-title'>{module.name}</p>
                        <IconButton
                          sx={{ color: '#5AA7DC' }}
                          onClick={() => togglePreviewModal(module, true)}
                          color='primary'
                          aria-label='preview-course'
                          component='label'
                        >
                          <IoEye />
                        </IconButton>
                      </div>
                    ))}

                    {(modules && modules.length === 0) && (
                      <div className='preview-container'>
                        <p className='preview-warning'>No Selected Curriculum</p>
                      </div>
                    )}

                  </div>
                </div>
              </>
            )}

          </div>
          <CoursePreviewModal
            companyId={companyId}
            courseDefinition={previewCourse}
            previewModalHeader={previewCourse?.name || ''}
            showPreviewModal={showPreviewModal}
            togglePreviewModal={togglePreviewModal}
          />
        </>
      )}
      {isContinuous && (
        <>
          <div className='heading-section'>
            <h4 className='phin-h4'>Continuous Curriculum Topics</h4>
            {lock && (<IoLockClosed className='lock-style' />)}
          </div>
          <div className='desc-switch-container'>
            <p className='section-content'>Below are the list of topics that that this campaign will randomly select courses from. You can reorder, add, or uncheck topics from this list. You <b>must</b> select at least one topic.</p>
          </div>

          <TrainingTopicsSelectorTable
            baseTrainingCampaignTopics={baseTrainingCampaignTopics}
            nonBaseTrainingCampaignTopics={nonBaseTrainingCampaignTopics}
            trainingTopics={trainingTopics}
            setTrainingTopics={setTrainingTopics}
            rowCount={15}
            lock={lock}
          />
          <div className='margin-top:1'>
            <Alert variant='outlined' severity='info'>
              {'Continuous Training Campaigns will run continuously with a finite amount of training courses. If a campaign uses' +
              ' all of the training courses from a specific topic, the campaign will start re-using courses.'}
            </Alert>
          </div>
        </>
      )}
    </div>
  )
}
