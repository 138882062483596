import { Alert, Grid, Skeleton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { integrationTypes, partnerIntegrationStatuses } from '../../frontendConsts'
import { getPartnerIntegrationsThunk, removePartnerIntegrationAction } from '../../store/PartnerIntegrationSlice'
import { smartRoute } from '../../utils/RouterUtils'
import { useHistory } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import PhinModal from './PhinModal'
import { StatusBreadCrumbs } from './integrations/StatusBreadCrumbs'
import NotificationUtilities from './notifications/notificationUtils'
import { PhinCard } from './PhinCard.js'
import { ConnectWiseIntegrationForm } from './connectWise/ConnectWiseIntegrationForm'
import { useConnectIntegrationMutation, useDisconnectIntegrationMutation } from '../../store/ConnectwiseServiceDeskApiSlice'
import { IoWarning } from 'react-icons/io5'

export function ConnectwiseServiceDeskIntegrationSetup ({ partnerId }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const { logout } = useAuth0()

  const [enableCWServiceDeskIntegration, { isLoading: isLoadingEnableIntegration }] = useConnectIntegrationMutation()

  const { authorization } = useSelector((state) => state.auth)
  const {
    partnerIntegrationsMap,
    partnerIdOfLastFetch,
    loaders: partnerIntegrationLoaders
  } = useSelector((state) => state.partnerIntegrations)
  const { isLoadingPartnerIntegrations } = partnerIntegrationLoaders
  const { partner, loaders: partnerLoaders } = useSelector((state) => state.partner)
  const { isLoadingPartner } = partnerLoaders

  const cwServiceDeskIntegration = useSelector(state => state.partnerIntegrations?.partnerIntegrationsMap?.[integrationTypes.CW_SERVICE_DESK])

  const [disconnectModal, setDisconnectModal] = useState(false)
  const [disconnectCwServiceDeskIntegration] = useDisconnectIntegrationMutation()

  // Fetch the partner integrations if the partnerIntegrationsMap is null or the partnerIdOfLastFetch is different from the current partnerId
  useEffect(() => {
    if (!partnerIntegrationsMap || partnerIdOfLastFetch !== partnerId) {
      dispatch(getPartnerIntegrationsThunk(partnerId))
    }
  }, [dispatch, partnerId, partnerIntegrationsMap, partnerIdOfLastFetch])

  async function disconnectConnectWiseIntegration () {
    const result = await disconnectCwServiceDeskIntegration({ partnerId })
    setDisconnectModal(false)
    if (result.error) {
      NotificationUtilities.sendErrorMessage('Failed to disconnect ConnectWise Service Desk Integration. Please try again or open a ticket with Phin Support.')
    } else {
      NotificationUtilities.sendSuccessMessage('ConnectWise Service Desk Integration Disconnected')
      dispatch(removePartnerIntegrationAction(integrationTypes.CW_SERVICE_DESK))
      history.push(`/partners/${partnerId}/integrations`)
    }
  }

  if (isLoadingPartner || !partner) {
    return <Skeleton variant='rectangle' width={1190} height={600} />
  }

  if (!partner.featureFlags?.isCwServiceDeskIntegrationEnabled) {
    smartRoute({ authorization, history, logout })
  }

  return (
    <>
      <PhinModal
        isOpen={disconnectModal}
        title='Disconnect ConnectWise Integration'
        action={disconnectConnectWiseIntegration}
        close={() => setDisconnectModal(false)}
        closeText='Cancel'
        actionText='Disconnect'
        actionColor='error'
      >
        <p>Are you sure you want to disconnect the ConnectWise Integration?</p>
      </PhinModal>

      <div>
        <h1>ConnectWise Service Desk Integration</h1>

        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          sx={{ marginTop: 'var(--phin-s-1)', marginBottom: 'var(--phin-s-1)' }}
        >
          <Grid>
            <StatusBreadCrumbs
              steps={['Not Connected', 'Connected']}
              step={cwServiceDeskIntegration ? 1 : 0}
            />
          </Grid>
        </Grid>

        {(isLoadingPartnerIntegrations || !partnerIntegrationsMap) && (
          <Skeleton variant='rectangle' width={1500} height={700} />
        )}

        {!isLoadingPartnerIntegrations && partnerIntegrationsMap && (
          <>
            <Grid container direction='row' spacing={4}>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <PhinCard title='ConnectWise Permissions' height='60vh'>

                  <Alert icon={<IoWarning />} severity='warning'>
                    <p className='phin-h6'>Company names in Phin should match company names in ConnectWise Manage for tickets to be created.</p>
                  </Alert>
                  <ul style={{ marginTop: 'var(--phin-s1)' }}>
                    <li className='phin-h5'>Companies</li>
                    <ul>
                      <li className='phin-h6'>Company Maintenance: Inquiry Level</li>
                      <p className='margin-left:0'>Phin needs Inquiry Level access for matching the Phin company to the ConnectWise company.</p>
                    </ul>
                    <ul>
                      <li className='phin-h6'>Create Attachment: Add (All), Inquire (All)</li>
                      <p className='margin-left:0'>Phin needs Add and Inquire level access for uploading attachments to the created ticket.</p>
                    </ul>
                    <li className='phin-h5 margin-top:2'>Service Desk</li>
                    <ul>
                      <li className='phin-h6'>Service Tickets: Inquire (All), Add (All)</li>
                      <p className='margin-left:0'>Phin needs Inquire and Add level access for creating a ticket.</p>
                    </ul>
                  </ul>
                </PhinCard>
              </Grid>

              <Grid item lg={6} md={12} sm={12} xs={12}>
                <ConnectWiseIntegrationForm
                  isLoadingEnableIntegration={isLoadingEnableIntegration}
                  isIntegrationEnabled={cwServiceDeskIntegration?.integrationStatus === partnerIntegrationStatuses.ENABLED}
                  enableIntegration={enableCWServiceDeskIntegration}
                  disableIntegration={() => setDisconnectModal(true)}
                  partnerId={partner.id}
                />
              </Grid>
            </Grid>
          </>
        )}
      </div>
    </>
  )
}
