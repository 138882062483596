import Moment from 'moment'
import { DateTime } from 'luxon'

export function computeCampaignStatus (startTime, endTime, referenceTime, nextFireDateTime) {
  const momentReference = new Moment(referenceTime)
  const timeUntilStart = momentReference.diff(new Moment(startTime))
  const timeUntilEnd = momentReference.diff(new Moment(endTime))

  if (timeUntilStart <= 0) {
    // Campaign has NOT started yet
    return { status: 0, message: `Starts in ${getTimeInAppropriateUnits(startTime, momentReference)}`, color: 'success' }
  } else if (timeUntilEnd >= 0 || isNaN(timeUntilEnd)) {
    // Continuous campaign or campaign completed
    if (isNaN(timeUntilEnd)) {
      const formattedDate = nextFireDateTime.toLocaleString(DateTime.DATETIME_SHORT)
      return { status: 1, message: `Next Fire Time: ${formattedDate}`, color: 'default' }
    } else {
      return { status: 3, message: 'Completed', color: 'primary' }
    }
  } else {
    // Check if today is within one day (86400000 milliseconds) of the end of the campaign
    if (-timeUntilEnd < 86400000) {
      // Campaign is within 1 day of being stopped and archived
      return { status: 2, message: `Ends in ${getTimeInAppropriateUnits(momentReference, +new Moment(endTime))}`, color: 'primary' }
    } else {
      // Campaign has not completed
      const formattedDate = nextFireDateTime.toLocaleString(DateTime.DATETIME_SHORT)
      return { status: 1, message: `Next Fire Time: ${formattedDate}`, color: 'default' }
    }
  }
}

export function getTimeInAppropriateUnits (time, referenceTime) {
  const monthOfMillis = 2629800000
  const dayOfMillis = 86400000
  const absTime = Math.abs(time - referenceTime)

  if (absTime > monthOfMillis) {
    const num = Math.floor(absTime / monthOfMillis)
    return `${num} month${num !== 1 ? 's' : ''}`
  } else if (absTime > dayOfMillis) {
    const num = Math.ceil(absTime / dayOfMillis)
    return `${num} day${num !== 1 ? 's' : ''}`
  } else {
    return 'less than a day'
  }
}
