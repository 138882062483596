import React, { useEffect, useState } from 'react'
import Spinner from '../../Spinner'
import {
  MDBContainer, MDBCol, MDBRow
} from 'mdb-react-ui-kit'
import NotificationUtilities from '../../notifications/notificationUtils'
import { downloadCSV, downloadOrgData, downloadOrgDataForCampaign } from '../../../../utils/fileUtils'
import CompanyAnalytics from '../../analyticsPage/CompanyAnalytics'
import DataExportButtons from '../../analyticsPage/DataExportButtons.js'
import CampaignSelector from '../../analyticsPage/CampaignSelector'
import { useHistory } from 'react-router-dom'
import { campaignTypes, dateIntervals, navigationRoutes, phishingAnalyticsSelectionTypes } from '../../../../frontendConsts.js'
import { apiRequestUtils } from '../../../../utils/apiRequestUtils'
import { useSelector, useDispatch } from 'react-redux'
import { getPhishingClickStatsThunk, getSendRecordsThunk } from '../../../../store/AnalyticsSlice'

// CSS Stylesheets
import '../../../css/analytics.css'
import '../../../css/reports.css'
import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { PhishingClicksTable } from './PhishingClicksTable'
import { DateTime } from 'luxon'

function PhishingAnalyticsDashboard ({ id }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const { sendRecords, loaders } = useSelector((state) => state.analytics)
  const { isLoadingSendRecords } = loaders
  const { activeCampaigns, pastCampaigns, loaders: campaignLoaders } = useSelector((state) => state.campaigns)
  const { isLoadingActiveCampaigns, isLoadingPastCampaigns } = campaignLoaders

  const [exporting, setExporting] = useState(false)

  const t = new Date()
  const earliestDate = new Date(t.setDate(t.getDate() - 365))

  const [startDate, setStartDate] = useState(earliestDate)
  const [endDate, setEndDate] = useState(new Date())
  const [dateInterval, setDateInterval] = useState(365)
  const [exportButtonStyle, setExportButtonStyle] = useState({
    transform: 'scaleY(0.4) scaleX(0.4) translateY(200px) translateX(0)',
    opacity: '0'
  })
  const [selectedCampaignId, setSelectedCampaignId] = useState('')
  const [selectedCampaignName, setSelectedCampaignName] = useState('')
  const [analyticsSelectionType, setAnalyticsSelectionType] = useState(phishingAnalyticsSelectionTypes.USER_VIEW)
  const [clickedTimeElapsedFilter, setClickedTimeElapsedFilter] = useState(0)
  const [clickedTimeElapsedFilterLabel, setClickedTimeElapsedFilterLabel] = useState('All Time')

  useEffect(() => {
    async function setup () {
      await dispatch(getSendRecordsThunk(id))
      await dispatch(getPhishingClickStatsThunk(id))
    }
    setup()
  }, [])

  const toggleSelectionChange = (event) => {
    const selectionType = parseInt(event.target.value)
    setAnalyticsSelectionType(selectionType)
  }

  const onExportHover = () => {
    setExportButtonStyle({
      exportButtonStyle: {
        transform: 'scaleY(1) scaleX(1) translateY(0px) translateX(0)',
        opacity: '1'
      }
    })
  }

  const onExportMouseLeave = () => {
    setExportButtonStyle({
      exportButtonStyle: {
        transform: 'scaleY(0.4) scaleX(0.4) translateY(100px) translateX(0)',
        opacity: '0'
      }
    })
  }

  const handleCampaignSelection = (selectedCampaignId, selectedCampaignName) => {
    setSelectedCampaignId(selectedCampaignId)
    setSelectedCampaignName(selectedCampaignName)
  }

  const handleIndividualSelection = (duid, user) => {
    if (duid !== '') {
      history.push(`/companies/${id}${navigationRoutes.ANALYTICS_USERS}/${duid}`, { previousPageTitle: 'Phishing Analytics', user })
    }
  }

  const handleDownload = async () => {
    setExporting(true)

    let selectedCampaign = activeCampaigns.filter(campaign => campaign.id === selectedCampaignId)
    let selectedCampaignStatus = 'activeCampaigns'

    if (selectedCampaign === undefined) {
      selectedCampaign = pastCampaigns.filter(campaign => campaign.id === selectedCampaignId)
      selectedCampaignStatus = 'pastCampaigns'
    }

    if (selectedCampaign === undefined) {
      NotificationUtilities.sendErrorMessage('No Campaign To download.')
      return
    }

    try {
      await downloadCSV(id, selectedCampaignId, selectedCampaignName, selectedCampaignStatus, apiRequestUtils)
    } catch (err) {
      console.error(err)
      NotificationUtilities.sendErrorMessage('Failed to generate CSV. Please try again.')
    }

    setExporting(false)
  }
  const downloadOrgPerformance = async () => {
    setExporting(true)

    try {
      const oneYearAgo = new Date()
      oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1)
      if (selectedCampaignId === '') {
        await downloadOrgData(id, [startDate.toISOString(), endDate.toISOString()])
      } else {
        await downloadOrgDataForCampaign(id, selectedCampaignName, selectedCampaignId)
      }
    } catch (err) {
      NotificationUtilities.sendErrorMessage('Failed to Generate Organizational Data. Please try again')
    }
    setExporting(false)
  }

  const filterSendRecords = (sendRecords) => {
    let filteredSendRecords = sendRecords.filter(sendRecord => new Date(sendRecord.created) >= startDate && new Date(sendRecord.created) <= endDate)

    if (selectedCampaignId !== '') {
      filteredSendRecords = filteredSendRecords.filter(sendRecord => sendRecord.campaignId === selectedCampaignId)
    }

    if (clickedTimeElapsedFilter !== '') {
      filteredSendRecords = filteredSendRecords.filter(sendRecord => {
        if (!sendRecord.clicked) {
          return sendRecord
        }

        const clickedLuxon = DateTime.fromISO(sendRecord.clicked)
        const createdLuxon = DateTime.fromISO(sendRecord.created)
        const timeElapse = clickedLuxon.diff(createdLuxon, 'seconds').toObject().seconds
        return timeElapse >= clickedTimeElapsedFilter
      })
    }

    return filteredSendRecords
  }

  const updateDateRange = (updateObj) => {
    const midnightToday = new Date()
    midnightToday.setHours(0, 0, 0, 0)

    if (updateObj.daysAgo) {
      const t = new Date()
      setStartDate(new Date(t.setDate(t.getDate() - updateObj.daysAgo)))
      // newState.startDate = new Date(t.setDate(t.getDate() - updateObj.daysAgo)) not sure if this needs to happen on a new line
      setEndDate(new Date())
      setDateInterval(updateObj.daysAgo)
    } else {
      if (updateObj.startDate) {
        setStartDate(updateObj.startDate)
      }
      if (updateObj.endDate) {
        setEndDate(updateObj.endDate)
      }
      if (updateObj.endDate || endDate > midnightToday) {
        const oneDayInMillis = 24 * 60 * 60 * 1000
        const diffDays = Math.round(Math.abs(((updateObj.startDate || startDate) - (updateObj.endDate || endDate)) / oneDayInMillis))
        if (Object.values(dateIntervals).includes(diffDays)) {
          setDateInterval(diffDays)
        } else {
          setDateInterval(dateIntervals.custom)
        }
      } else {
        setDateInterval(dateIntervals.custom)
      }
    }
  }

  const renderAnalyticsView = () => {
    if (isLoadingSendRecords || isLoadingActiveCampaigns || isLoadingPastCampaigns ||
      !sendRecords || !activeCampaigns || !pastCampaigns) {
      return <Spinner />
    } else {
      const filteredSendRecords = filterSendRecords(sendRecords)
      return (
        <>
          <CampaignSelector
            campaigns={activeCampaigns.filter(campaign => campaign.campaignType === campaignTypes.CONTINUOUS || campaign.campaignType === campaignTypes.FIXEDLENGTH)}
            pastCampaigns={pastCampaigns.filter(campaign => campaign.campaignType === campaignTypes.CONTINUOUS || campaign.campaignType === campaignTypes.FIXEDLENGTH)}
            selectedCampaignId={selectedCampaignId}
            earliestDate={earliestDate}
            startDate={startDate}
            endDate={endDate}
            updateDateRange={updateDateRange}
            dateInterval={dateInterval}
            handleCampaignSelection={handleCampaignSelection}
            setClickedTimeElapsedFilter={setClickedTimeElapsedFilter}
            clickedTimeElapsedFilterLabel={clickedTimeElapsedFilterLabel}
            clickedTimeElapsedFilter={clickedTimeElapsedFilter}
            setClickedTimeElapsedFilterLabel={setClickedTimeElapsedFilterLabel}
          />
          <CompanyAnalytics sendRecords={filteredSendRecords} handleIndividualSelection={handleIndividualSelection} companyId={id} />
        </>
      )
    }
  }

  return (
    <Box>
      <MDBContainer>
        <MDBRow>
          <MDBCol className='d-flex flex-column flex-center mb-4'>
            <h1 id='analytics-page-heading'>Phishing Analytics</h1>
          </MDBCol>
        </MDBRow>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', pb: 1 }}>
          <ToggleButtonGroup value={analyticsSelectionType} color='primary' exclusive onChange={toggleSelectionChange}>
            <ToggleButton id='courseView' value={phishingAnalyticsSelectionTypes.USER_VIEW}>User View</ToggleButton>
            <ToggleButton id='userView' value={phishingAnalyticsSelectionTypes.CLICK_VIEW}>Clicks View</ToggleButton>
          </ToggleButtonGroup>
        </Box>
        {!sendRecords && (
          <Spinner message='' />
        )}
        {sendRecords && analyticsSelectionType === phishingAnalyticsSelectionTypes.USER_VIEW && (
          <>
            <MDBRow className='my-4 d-flex justify-content-center'>
              <MDBCol xl='12' md='12' className='mb-5'>
                {renderAnalyticsView()}
              </MDBCol>
            </MDBRow>

            <DataExportButtons
              exporting={exporting}
              onMouseHover={onExportHover}
              onMouseLeave={onExportMouseLeave}
              buttonStyles={exportButtonStyle}
              downloadOrgData={() => downloadOrgPerformance()}
              downloadCampaignData={handleDownload}
            />
          </>)}
        {sendRecords && analyticsSelectionType === phishingAnalyticsSelectionTypes.CLICK_VIEW && (
          <PhishingClicksTable companyId={id} />
        )}
      </MDBContainer>

    </Box>
  )
}

export default PhishingAnalyticsDashboard
