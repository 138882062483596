import { useState } from 'react'
import { useDispatch } from 'react-redux'
import './CampaignFrequencySelector.css'
import SelectOptions from '../../SelectOptions'
import InlineMultiSelect from '../../InlineMultiSelect'
import { setIterationsAction, setFrequencyAction, setMonthAction, setWeekAction, setWeekdayAction, setCampaignFireTimeAction } from '../../../../store/CampaignFrequencySlice'
import { setTrainingIterationsAction, setTrainingFrequencyAction, setTrainingMonthAction, setTrainingWeekAction, setTrainingWeekdayAction, setTrainingCampaignFireTimeAction } from '../../../../store/TrainingCampaignFrequencySlice'
import TextField from '@mui/material/TextField'
import MomentUtils from '@date-io/moment'
import { LocalizationProvider, DatePicker, TimePicker } from '@mui/x-date-pickers'
import { Alert, MenuItem } from '@mui/material'
import CampaignScheduleDisplay from './CampaignScheduleDisplay'
import moment from 'moment'
import { campaignTypes } from '../../../../frontendConsts'
import { PhinCard } from '../../PhinCard'

const CampaignFrequencySelector = ({
  startTime, setStartTime, phishingAttemptWindowDays, setPhishingAttemptWindowDays,
  campaignFrequency, tz, campaignType,
  isRunning, nextFireTimes, firstFireDate,
  isCampaignFrequencyWeeksValid, setIsCampaignFrequencyWeeksValid,
  nextFireTimesErrorMessage,
  modules, // modules is optional
  useTrainingSelector = false // keep separate state for training since we can use 2 of these components on the same page
}) => {
  const {
    iterations, iterationsOptions, frequency,
    frequencyOptions, month, monthOptions,
    week, weekOptions, weekday, weekdayOptions
  } = campaignFrequency

  const dispatch = useDispatch()

  const minDate = new Date()
  minDate.setDate(minDate.getDate() + 1)

  const renderOptions = (activeValue, setActiveValue, options, id, ariaLabel) => {
    return (activeValue
      ? (
        <SelectOptions
          value={activeValue.length ? activeValue[0].value : activeValue.value}
          label=''
          innerClassName='mad-lib-picker'
          onChange={
            (e) => setActiveValue(activeValue.length
              ? [{ text: e.target.value, value: e.target.options[e.target.selectedIndex].value }]
              : { text: e.target.value, value: e.target.options[e.target.selectedIndex].value })
          }
          options={options}
          id={id}
          aria-label={ariaLabel}
        />
        )
      : (<></>)
    )
  }

  const shouldBeDisabled = (option, selectedOptions) => {
    if (frequency.text === 'Once' ? selectedOptions.length === 1 : selectedOptions.length === 2) {
      const disabledStatus = selectedOptions.map(option => option.value).includes(option.value) ? '' : 'disabled'
      return disabledStatus
    }
  }

  const phishingWindowOptions = [
    {
      value: 3,
      label: 'Across 3 Days'
    },
    {
      value: 4,
      label: 'Across 4 Days'
    },
    {
      value: 5,
      label: 'Across 5 Days'
    },
    {
      value: 6,
      label: 'Across 6 Days'
    },
    {
      value: 7,
      label: 'Across 7 Days'
    }
  ]

  const [collapsed, setCollapsed] = useState(true)

  const [hours, minutes] = campaignFrequency.time.split(':')

  const iterationsSelectionId = useTrainingSelector ? 'iterations-select' : 'phishing-iterations-select'
  const frequencySelectionId = useTrainingSelector ? 'frequency-select' : 'phishing-frequency-select'
  const weeksSelectionId = useTrainingSelector ? 'weeks-select' : 'phishing-weeks-select'
  const monthsSelectionId = useTrainingSelector ? 'months-select' : 'phishing-months-select'
  const weekdaySelectionId = useTrainingSelector ? 'weekdayOptions-select' : 'phishing-weekdayOptions-select'

  return (
    <PhinCard
      title={`${campaignType === 'phishing' ? 'Phishing' : 'Training'} Time and Sending Settings`}
    >
      <div className='d-flex justify-content-left align-items-center margin-top:3 margin-bottom:1'>
        <div className='mr-3'><p style={{ margin: 0, fontSize: '1.15rem' }}> {campaignFrequency.iterations.value === 'All At Once' ? 'On' : 'Start scheduling no earlier than'}: </p></div>
        <LocalizationProvider dateAdapter={MomentUtils}>
          <DatePicker
            label='Start Date'
            inputFormat='MM/DD/YYYY'
            value={startTime}
            id='campaign-frequency-selector-start-date-picker'
            minDate={isRunning ? startTime : minDate}
            disablePast={!isRunning}
            disabled={isRunning}
            onChange={(value) => setStartTime(value)}
            renderInput={(params) => <TextField onKeyDown={(e) => e.preventDefault()} {...params} />}
            slotProps={{
              textField: {
                inputProps: {
                  'data-testid': 'campaign-frequency-selector-start-date-picker'
                }
              }
            }}
          />
        </LocalizationProvider>
      </div>
      <div className='margin-top:1 margin-bottom:3'>
        <p style={{ fontSize: '1.15rem', width: '100%' }}>
          <span> Send {renderOptions(iterations, (d) => {
            if (useTrainingSelector) {
              dispatch(setTrainingIterationsAction(d))
            } else {
              dispatch(setIterationsAction(d))
            }

            setIsCampaignFrequencyWeeksValid(true)
          }, iterationsOptions, iterationsSelectionId, 'Iterations Selector')}
          </span>
          {campaignFrequency.iterations.value !== 'All At Once' &&
                    (
                      <>
                        <span> a {renderOptions(frequency, (d) => {
                          if (useTrainingSelector) {
                            dispatch(setTrainingFrequencyAction(d))
                          } else {
                            dispatch(setFrequencyAction(d))
                          }
                        }, frequencyOptions, frequencySelectionId, 'Frequency Selector')}
                        </span>
                        <span>
                          {frequency.text === 'Quarter' &&
                            (
                              <span> in the {renderOptions(month, (d) => {
                                if (useTrainingSelector) {
                                  dispatch(setTrainingMonthAction(d))
                                } else {
                                  dispatch(setMonthAction(d))
                                }
                              }, monthOptions, monthsSelectionId, 'Month Selector')} month
                              </span>
                            )}
                        </span>
                        <span>
                          {frequency.text !== 'Week' &&
                            (iterations.text === 'Once'
                              ? (
                                <span> on the {renderOptions(week, (d) => {
                                  if (useTrainingSelector) {
                                    dispatch(setTrainingWeekAction(d))
                                  } else {
                                    dispatch(setWeekAction(d))
                                  }
                                }, weekOptions, weeksSelectionId, 'Week Selector')} week
                                </span>)
                              : (
                                <span> on the <InlineMultiSelect
                                  shouldBeDisabled={shouldBeDisabled}
                                  collapsed={collapsed}
                                  setCollapsed={setCollapsed}
                                  selectedOptions={week}
                                  options={weekOptions}
                                  setSelectedOptions={(d) => {
                                    if (useTrainingSelector) {
                                      dispatch(setTrainingWeekAction(d))
                                    } else {
                                      dispatch(setWeekAction(d))
                                    }
                                  }}
                                  numberOfRequiredChoices={frequency === 'Once' ? 1 : 2}
                                  isValid={isCampaignFrequencyWeeksValid}
                                  setIsValid={setIsCampaignFrequencyWeeksValid}
                                              /> week
                                </span>
                                ))}
                        </span>
                        <span> on {renderOptions(weekday, (d) => {
                          if (useTrainingSelector) {
                            dispatch(setTrainingWeekdayAction(d))
                          } else {
                            dispatch(setWeekdayAction(d))
                          }
                        }, weekdayOptions, weekdaySelectionId, 'Weekday Selector')}
                        </span>
                      </>
                    )}
          <span> at
            <span className='pl-3'>
              {/* TODO: Prevent users from choosing a Date time pair that's within 2 hours of now */}
              <LocalizationProvider dateAdapter={MomentUtils}>
                <TimePicker
                  label='Weekly Send Time'
                  value={moment().hours(+hours).minutes(+minutes)}
                  onChange={(newValue) => {
                    if (newValue && newValue._isValid) {
                      if (useTrainingSelector) {
                        dispatch(setTrainingCampaignFireTimeAction({ minutes: newValue.minutes(), hours: newValue.hours() }))
                      } else {
                        dispatch(setCampaignFireTimeAction({ minutes: newValue.minutes(), hours: newValue.hours() }))
                      }
                    }
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </span>
          </span>
        </p>
      </div>

      {campaignFrequency.iterations.value !== 'All At Once' &&
          campaignType === campaignTypes.PHISHING &&
            <div className='margin-bottom:3'>
              <h5 className='phin-h5 mb-3'>Phishing Sending Window</h5>

              <Alert style={{ width: '100%' }} severity='info'>
                Learn more about how we schedule phishing messages <a target='_blank' href='https://www.phinsec.io/knowledge/when-will-continuous-phishing-campaigns-deliver' rel='noreferrer'>here</a>.
              </Alert>

              <TextField
                id='phishing-attempt-window-select'
                select
                className='mt-3'
                style={{ minWidth: '200px' }}
                type='outlined'
                value={phishingAttemptWindowDays}
                onChange={(event) => setPhishingAttemptWindowDays(event.target.value)}
              >
                {phishingWindowOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>}

      <hr className='margin-bottom:3' />
      <div>
        {campaignFrequency.iterations.value !== 'All At Once' &&
          <CampaignScheduleDisplay
            tz={tz}
            modules={modules}
            nextFireTimes={nextFireTimes}
            firstFireDate={firstFireDate}
            nextFireTimesErrorMessage={nextFireTimesErrorMessage}
            isCampaignFrequencyWeeksValid={isCampaignFrequencyWeeksValid}
            useTrainingSelector={useTrainingSelector}
          />}
      </div>

    </PhinCard>
  )
}

export default CampaignFrequencySelector
